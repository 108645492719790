<template>
  <nav>
    <router-link class="logo" to="/">
      <img class="logo-large" src="/logos/logo-nuteam-white.svg" alt="NuTeam Logo" />
      <img class="logo-small" src="/logos/logo-nuteam-white-small.svg" alt="NuTeam Logo" />
    </router-link>
    <router-link to="/">
      <IconLibrary name="dashboard" size="lg" />
      <span class="label">{{ $t('ui.dashboard') }}</span>
    </router-link>
    <router-link to="/orders">
      <IconLibrary name="order" size="lg" />
      <span class="label">{{ $t('ui.orders') }}</span>
      <span class="tag count">{{ orderStore.orderCount }}</span>
    </router-link>
    <router-link to="/products" :class="{ active: isProductsChild }">
      <IconLibrary name="product" size="lg" />
      <span class="label">{{ $t('ui.products') }}</span>
    </router-link>
    <router-link
      to="/collections"
      :class="{ child: isProductsChild }"
      v-if="userStore.user?.role !== 'seller' && isProductsChild"
    >
      <span class="label">{{ $t('ui.collections') }}</span>
    </router-link>
    <router-link
      :class="{ child: isProductsChild }"
      v-if="isProductsChild && userStore.user?.role !== 'seller'"
      to="/catalog"
    >
      <span class="label">{{ $t('ui.catalog') }}</span>
    </router-link>
    <router-link
      :class="{ child: isProductsChild }"
      v-if="isProductsChild && userStore.user?.role === 'seller'"
      to="/catalogs"
    >
      <span class="label">{{ $t('ui.catalogs') }}</span>
    </router-link>
    <router-link v-if="userStore.user?.role !== 'seller'" to="/shop">
      <IconLibrary name="online-shop" size="lg" />
      <span class="label">{{ $t('ui.shop') }}</span>
    </router-link>
    <router-link v-if="userStore.user?.role === 'seller'" to="/clients">
      <IconLibrary name="director" size="lg" />
      <span class="label">{{ $t('ui.clients') }}</span>
    </router-link>
    <router-link to="/sellers" v-if="userStore.user?.role !== 'seller'">
      <IconLibrary name="seller" size="lg" />
      <span class="label">{{ $t('ui.sellers') }}</span>
    </router-link>
    <router-link v-if="userStore.user?.role !== 'seller'" to="/pos">
      <IconLibrary name="shop" size="lg" />
      <span class="label">{{ $t('ui.pos') }}</span>
    </router-link>
    <router-link v-if="userStore.user?.role !== 'seller'" to="/teams">
      <IconLibrary name="team" size="lg" />
      <span class="label">{{ $t('ui.teams') }}</span>
    </router-link>
    <router-link to="/settings">
      <IconLibrary name="setting" size="lg" />
      <span class="label">{{ $t('ui.settings') }}</span>
    </router-link>
    <router-link to="/logout">
      <IconLibrary name="logout" size="lg" />
      <span class="label">{{ $t('ui.logout') }}</span>
    </router-link>
  </nav>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import IconLibrary from './IconLibrary.vue';
import { useOrderStore } from '../../stores/orders.js';
import { useUserStore } from '../../stores/user.js';
const route = useRoute();
const orderStore = useOrderStore();
const userStore = useUserStore();
const isProductsChild = ref(false);
watch(
  () => route.path,
  () => {
    isProductsChild.value = ['/products', '/collections', '/catalog', '/catalogs'].includes(
      route.path
    );
  },
  { immediate: true }
);
defineProps({
  count: { type: Number, default: 0 },
  role: { type: String }
});
</script>

<style scoped lang="scss">
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
nav {
  display: flex;
  flex-direction: column;
  padding: 15px 16px;
  gap: 10px;
  a.logo {
    margin-left: 5px;
    margin-bottom: 40px;
    width: 125px;
  }
  a:not(.logo) {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $brand-light-purple;
    font-size: 1.15em;
    font-weight: $font-weight-light;
    transition: color $base-speed-fast ease;
    .icon-wrapper {
      margin-right: 8px;
      padding: 6px;
      background-color: transparent;
      border-radius: $base-radius;
      transition: background-color $base-speed-fast ease;
    }
    :deep(.svg-icon) {
      stroke: $brand-light-purple;
      transition: stroke $base-speed-fast ease;
    }
  }
  a:not(.logo):not(.router-link-exact-active):hover {
    color: $brand-corail;
    .icon-wrapper {
      background-color: rgba($white, 0.1);
    }
    :deep(.svg-icon) {
      stroke: $brand-corail;
    }
  }
  a:not(.logo).router-link-exact-active,
  a:not(.logo).active {
    color: $white;
    .icon-wrapper {
      background-color: $white;
    }
    :deep(.svg-icon) {
      stroke: $brand-dark-purple;
    }
  }
  a:not(.logo).active {
    color: $brand-light-purple;
  }
  a:not(.logo).active.router-link-exact-active {
    color: $white;
  }
}
nav.order {
  a .icon-wrapper {
    margin-right: 0px;
  }
}
.tag {
  background-color: $brand-corail;
  color: $white;
}
.child {
  margin-left: 50px;
}
.logo-small {
  display: none;
}

/* Responsive styles for tablet screens */
@media (max-width: 1023px) {
  .logo-large {
    display: none;
  }
  .logo-small {
    display: block;
    height: 25px;
  }

  .label {
    display: none;
  }
  .tag {
    position: relative;
  }
  .tag.count {
    position: absolute;
    bottom: -4px;
    left: 4.5px;
  }
  nav {
    a:not(.logo) {
      display: block;
      position: relative;
    }
    a.logo {
      width: auto;
    }
  }
}
</style>
