<template>
  <div v-if="isMounted" class="order-page">
    <OrderHeader />
    <main>
      <OrderProductGroup
        v-for="GroupedProducts in orderStore.getGroupedProducts"
        :key="GroupedProducts[0].seller_id"
        :products="GroupedProducts"
      />
    </main>
    <aside>
      <OrderPointOfSale />
    </aside>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useOrderStore } from '../stores/orders.js';
import OrderHeader from '../components/Order/OrderHeader.vue';
import OrderProductGroup from '../components/Order/OrderProductGroup.vue';
import OrderPointOfSale from '../components/Order/OrderPointOfSale.vue';

const orderStore = useOrderStore();
const isMounted = ref(false);

onMounted(async () => {
  try {
    const route = useRoute();
    await orderStore.fetchOrder(route.params.order_id);
    // when reloading the order page we want to save changes made to the order in db
    window.onbeforeunload = () => {
      // updateOrder();
    };
    isMounted.value = true;
  } catch (e) {
    console.error(e);
  }
});

// onMounted(async () => {
//   const websocket = inject('websocket');
//   await fetchOrder();
//   listenOrder(websocket.value);

//   groupedProducts.value = order.value.products.reduce(function (results, product) {
//     (results[product.seller_id] = results[product.seller_id] || []).push(product);
//     return results;
//   }, {});

//   // when reloading the order page we want to save changes made to the order in db
//   window.onbeforeunload = () => {
//     updateOrder();
//   };
//   isMounted.value = true;
// });

// onBeforeRouteLeave(async (to, from, next) => {
//   try {
//     // if some errors happened on updateOrder we still want to go the next page
//     // a common one is error.api.ressource_dont_exist, that happened when a shipment is made for example
//     // as the order is deleted when a shipment is made, it can't be updated
//     await updateOrder();
//   } finally {
//     next();
//   }
// });
</script>

<style scoped lang="scss">
.order-page {
  display: grid;
  grid-template-columns: 1.25fr 0.75fr;
  grid-template-areas:
    'OrderHeader OrderHeader'
    'OrderMain OrderAside ';
  gap: 25px;
  @include responsive-padding(sm, md, lg);
  > header {
    grid-area: OrderHeader;
  }
  > main {
    grid-area: OrderMain;
    // background-color: $brand-light-grey;
  }
  > aside {
    grid-area: OrderAside;
    position: relative;
  }
  > main > *,
  > aside > * {
    border-radius: $base-radius;
    background-color: $white;
    box-shadow: 0px 8px 18px rgba($brand-indigo, 0.08);
  }
}

@media (max-width: 767px) {
  .order-page {
    grid-template-columns: 1fr;
    grid-template-areas:
      'OrderHeader'
      'OrderMain'
      'OrderAside';
    gap: 15px;
  }
}
</style>
