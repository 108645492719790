<template>
  <transition name="fade">
    <div v-if="isOpen" class="modal-overlay" @click="close">
      <div class="modal" :class="{ 'modal-sm': size === 'small' }" @click.stop>
        <header class="modal-header">
          <slot name="title"></slot>
          <!-- <div class="modal-close" @click="close">
          <IconLibrary name="close" size="sm" color="red" :hasMargin="false" />
        </div> -->
        </header>

        <section class="modal-content">
          <slot></slot>
        </section>

        <footer class="modal-footer">
          <slot name="footer"></slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref } from 'vue';

const { size } = defineProps({ size: { type: String, default: 'regular' } });
// import IconLibrary from './IconLibrary.vue';

const isOpen = ref(false);
const open = () => (isOpen.value = true);
const close = () => (isOpen.value = false);

defineExpose({ open, close });
</script>

<style scoped lang="scss">
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($black, 0.35);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal {
  background: $white;
  border-radius: $base-radius;
  box-shadow: 0 0 15px rgba($black, 0.25);
  min-width: 550px;
  width: 75%;
  position: relative;
  overflow: hidden;
  &-sm {
    min-width: 450px;
    width: 45%;
  }
}

// .modal-close {
//   background: none;
//   border: none;
//   cursor: pointer;
// }

.modal-header,
.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header {
  padding: 20px;
  background-color: $brand-light-indigo;
}

.modal-footer {
  padding: 15px 0 25px;
  margin: 0 20px;
  border-top: 1px solid darken($brand-light-indigo, 15%);
}

.modal-content {
  padding: 10px 20px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 200ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
