<template>
  <section class="gallery">
    <img :src="selectedImage" />
    <nav>
      <img v-for="image in product.images" :src="image" @click="switchImage(image)" />
    </nav>
  </section>
</template>
<script setup>
import { ref } from 'vue';
const props = defineProps({ product: { type: Object } });
const selectedImage = ref(props?.product?.images?.[0]);
const switchImage = (image) => (selectedImage.value = image);
</script>
<style lang="scss" scoped>
.gallery {
  display: flex;
  aspect-ratio: 1/1;
  > img {
    width: 100%;
    object-fit: contain;
    background-color: white;
    border-radius: $base-radius;
    box-shadow: 2px 2px 8px rgba($black, 0.15);
  }
}
nav {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  gap: 12px;
}
nav img {
  width: 65px;
  height: 65px;
  object-fit: cover;
  border-radius: $base-radius;
  box-shadow: 2px 2px 8px rgba($black, 0.15);
  cursor: pointer;
  transition: transform 150ms ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}

@media (max-width: 767px) {
  .gallery {
    flex-direction: column;
    aspect-ratio: inherit;
    > img {
      max-height: 280px;
    }
  }
  nav {
    flex-direction: row;
    gap: 12px;
    margin: 0;
    @include margin(sm, top);
  }
}
</style>
