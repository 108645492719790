<template>
  <div id="page">
    <header>
      <div class="actions">
        <RouterLink to="/shop" class="btn btn-lg btn-ghost">
          <IconLibrary name="online-shop" size="sm" />
          Retour à la boutique
        </RouterLink>
      </div>
    </header>
    <section class="boxed">
      <IconLibrary name="check" color="green" size="xxl" />
      <h4>
        Commande <span class="blue">{{ orderStore.order.identifier }}</span> <br />effectuée avec
        succès!
      </h4>
      <p>
        Un email de confirmation vous sera envoyé dès que la commande sera validé par votre
        directeur d'achat.
      </p>
    </section>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { formatMoney } from '../libs/utils.js';
import { useUserStore } from '../stores/user.js';
import { useOrderStore } from '../stores/orders.js';
import { useRoute } from 'vue-router';

import MoneyFormat from '../components/Shared/MoneyFormat.vue';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import FlagLibrary from '../components/Shared/FlagLibrary.vue';
import EuropeanVAT from '../libs/european_vat.json';
import { kyWithAuth } from '../libs/ky';

const route = useRoute();
const userStore = useUserStore();
const orderStore = useOrderStore();

const salePoint = ref(null);

onMounted(async () => {
  try {
    const role = userStore.user.role;
    orderStore.fetchOrder(route.params.order_id);
    if (role === 'director') {
      const { users: res } = await kyWithAuth.get('users/list/buyers').json();
      salePoint.value = res[0];
    } else if (role === 'buyer') {
      salePoint.value = await userStore.user;
    }
  } catch (e) {
    console.error(e);
  }
});
</script>

<style scoped lang="scss">
section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h4 {
    margin-bottom: 0;
    text-align: center;
  }
}
#page {
  padding: 0px !important;
}
#page > header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 25px;
  h2 {
    margin: 0;
  }
}
:deep(input[type='number']) {
  background-color: $white;
  border: 2px solid rgba($brand-blue, 0.3);
  color: $tuatara;
  transition: border 200ms ease, color 200ms ease, transform 200ms ease-in-out;
  font-size: 16px;
}
.grid {
  display: grid;
  grid-template-columns: 1.25fr 0.75fr;
  gap: 50px;
}
.info {
  font-size: 85%;
  color: $brand-grey;
}
.product + .product {
  margin-top: 20px;
}
.address strong,
.address span {
  color: $brand-light-purple;
}

.address {
  strong,
  span {
    color: $brand-light-purple;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.product {
  display: grid;
  grid-template-columns: 24px 65px 1fr 90px 90px;
  gap: 25px;
  align-items: center;
  .img-wrapper {
    width: 65px;
    height: 65px;
    overflow: hidden;
    box-shadow: 0px 0px 8px rgba($black, 0.1);
    border-radius: $base-radius;
    border: 2px solid $white;
    background-color: $white;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
h4 {
  margin: 25px 0;
}
aside h4 {
  margin-top: 0;
}
.tag {
  padding: 3px 12px;
  background-color: $brand-light-purple;
  color: rgba($white, 0.8);
  border-radius: $base-radius;
  font-size: 90%;
  &.active {
    color: $white;
    background-color: $brand-blue;
  }
}
</style>
