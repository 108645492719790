<template>
  <nav class="mobile">
    <!-- <div class="toggle"><IconLibrary name="shop" /></div> -->
    <section>
      <router-link to="/">
        <IconLibrary name="dashboard" size="lg" />
        <span class="label">{{ $t('ui.dashboard') }}</span>
      </router-link>
      <router-link to="/orders">
        <IconLibrary name="order" size="lg" />
        <span class="label">{{ $t('ui.orders') }}</span>
        <span class="tag count">{{ orderStore.orderCount }}</span>
      </router-link>
      <router-link to="/products">
        <IconLibrary name="product" size="lg" />
        <span class="label">{{ $t('ui.products') }}</span>
      </router-link>
      <router-link v-if="userStore.user?.role !== 'seller'" to="/shop">
        <IconLibrary name="online-shop" size="lg" />
        <span class="label">{{ $t('ui.shop') }}</span>
      </router-link>
      <router-link v-if="userStore.user?.role === 'seller'" to="/clients">
        <IconLibrary name="director" size="lg" />
        <span class="label">{{ $t('ui.clients') }}</span>
      </router-link>
      <router-link to="/settings">
        <IconLibrary name="setting" size="lg" />
        <span class="label">{{ $t('ui.settings') }}</span>
      </router-link>
    </section>
    <!-- <section class="hidden">
      <router-link to="/sellers" v-if="userStore.user?.role !== 'seller'">
        <IconLibrary name="seller" size="lg" />
        <span class="label">{{ $t('ui.sellers') }}</span>
      </router-link>
      <router-link v-if="userStore.user?.role !== 'seller'" to="/pos">
        <IconLibrary name="shop" size="lg" />
        <span class="label">{{ $t('ui.pos') }}</span>
      </router-link>
      <router-link v-if="userStore.user?.role !== 'seller'" to="/teams">
        <IconLibrary name="team" size="lg" />
        <span class="label">{{ $t('ui.teams') }}</span>
      </router-link>
      <router-link to="/logout">
        <IconLibrary name="logout" size="lg" />
        <span class="label">{{ $t('ui.logout') }}</span>
      </router-link>
    </section> -->
  </nav>
</template>

<script setup>
import IconLibrary from './IconLibrary.vue';
import { useOrderStore } from '../../stores/orders.js';
import { useUserStore } from '../../stores/user.js';

const orderStore = useOrderStore();
const userStore = useUserStore();

defineProps({
  count: { type: Number, default: 0 },
  role: { type: String }
});
</script>

<style scoped lang="scss">
nav.mobile {
  position: fixed;
  left: 0.75rem;
  right: 0.75rem;
  bottom: 0.5rem;
  z-index: 10000;
  background-color: $brand-dark-grey-purple;
  border-radius: $base-radius;
  box-shadow: 0 0 8px rgba($black, 0.5);
  @include padding(xs);
  @include padding(lg, left);
  @include padding(lg, right);
  section {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
  .hidden {
    display: none;
  }
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: $brand-light-purple;
    font-size: 1.15em;
    font-weight: $font-weight-light;
    transition: color $base-speed-fast ease;
    .icon-wrapper {
      margin-right: 8px;
      padding: 6px;
      background-color: transparent;
      border-radius: $base-radius;
      transition: background-color $base-speed-fast ease;
    }
    :deep(.svg-icon) {
      stroke: $brand-light-purple;
      transition: stroke $base-speed-fast ease;
    }
  }
  a:not(.router-link-exact-active):hover {
    color: $brand-corail;
    .icon-wrapper {
      background-color: rgba($white, 0.1);
    }
    :deep(.svg-icon) {
      stroke: $brand-corail;
    }
  }
  a.router-link-exact-active,
  a.active {
    color: $white;
    .icon-wrapper {
      background-color: $white;
    }
    :deep(.svg-icon) {
      stroke: $brand-dark-purple;
    }
  }
  a.active {
    color: $brand-light-purple;
  }
  a.active.router-link-exact-active {
    color: $white;
  }
}
nav.order {
  a .icon-wrapper {
    margin-right: 0px;
  }
}
.tag {
  background-color: $brand-corail;
  color: $white;
}
.child {
  margin-left: 50px;
}
.logo-small {
  display: none;
}

/* Responsive styles for tablet screens */
@media (max-width: 1023px) {
  .logo-large {
    display: none;
  }
  .logo-small {
    display: block;
    height: 25px;
  }

  .label {
    display: none;
  }
  .tag {
    position: relative;
  }
  .tag.count {
    position: absolute;
    bottom: -4px;
    left: 4.5px;
  }
  nav {
    a {
      display: block;
      position: relative;
    }
  }
}
</style>
