<template>
  <div id="app" :class="[routeName, `role-${userStore.user?.role}`, 'authenticated']">
    <MainHeader v-if="userStore.user?.role !== 'buyer'" />
    <MainNavigation v-if="userStore.user?.role !== 'buyer' && !isMobile()" />
    <main>
      <router-view :key="$route.fullPath" />
    </main>
  </div>
  <MobileMainNavigation v-if="userStore.user?.role !== 'buyer' && isMobile()" />
</template>

<script setup>
import { onMounted, onBeforeUnmount, onUnmounted, computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useUserStore } from '../stores/user';
import { useOrderStore } from '../stores/orders.js';
import { useCategoryStore } from '../stores/category';
import { useNotificationStore } from '../stores/notifications';
import { useWebSocket } from '@/composables/useWebSocket';

import MainHeader from '../components/Shared/MainHeader.vue';
import MainNavigation from '../components/Shared/MainNavigation.vue';
import MobileMainNavigation from '../components/Shared/MobileMainNavigation.vue';

const route = useRoute();
const routeName = computed(() => route?.name?.toLocaleLowerCase());

const userStore = useUserStore();
const orderStore = useOrderStore();
const categoryStore = useCategoryStore();
const notificationStore = useNotificationStore();
const { initWebSocket, closeWebSocket } = useWebSocket();

const windowWidth = ref(window.innerWidth);

const checkWidth = () => {
  windowWidth.value = window.innerWidth;
};

const isMobile = () => windowWidth.value < 768;

onMounted(async () => {
  window.addEventListener('resize', checkWidth);
  try {
    const jwt = window.localStorage.getItem('jwt');
    if (jwt && userStore.isLogged) {
      initWebSocket(jwt);
      await orderStore.fetchOrders();
      await orderStore.fetchOrderCount();
      if (userStore.user.role === 'director') {
        await categoryStore.fetchCategories();
        await notificationStore.fetchNotifications();
      }
    }
  } catch (e) {
    console.error(e);
  }
});

onBeforeUnmount(() => {
  console.log('Component unmounted. Closing WebSocket.');
  closeWebSocket();
});

onUnmounted(() => {
  window.removeEventListener('resize', checkWidth);
});
</script>

<style lang="scss">
#app.authenticated {
  display: grid;
  grid-template-columns: 255px 1fr;
  grid-template-rows: 70px 1fr;
  gap: 0px 0px;
  grid-template-areas:
    'MainNav Header'
    'MainNav Main';
  & > nav {
    grid-area: MainNav;
  }
  & > main {
    grid-area: Main;
  }
  & > header {
    grid-area: Header;
  }
}

#app.authenticated {
  & > main {
    background-color: #f5f6fa;
  }
  & > nav {
    background-color: $brand-dark-grey-purple;
  }
  & > header {
    background-color: $white;
  }
  & > aside {
    background-color: $brand-deep-purple;
  }
}

#app.authenticated.role-buyer {
  display: block;
  > main {
    max-width: 1440px;
    margin-inline: auto;
    box-shadow: 0 0 0.5rem rgba($black, 0.15);
    min-height: 100vh;
  }
}

@media (max-width: 1023px) {
  #app.authenticated {
    grid-template-columns: auto 1fr;
  }
}

@media (max-width: 767px) {
  #app.authenticated {
    grid-template-columns: 1fr;
    grid-template-rows: 70px 1fr;
    grid-template-areas:
      'Header'
      'Main';
    // overflow-x: hidden;
  }
}
</style>
