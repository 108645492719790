<template>
  <div class="products">
    <header>
      <h6>{{ $t('collections.products.title') }}</h6>
      <section class="search">
        <FormSearch title="forms.label.search" placeholder="forms.placeholder.filter_product" />
      </section>
    </header>

    <article
      class="product"
      :class="{ included: product.included }"
      v-for="product in allProducts"
      :key="product._id"
    >
      <img class="image" :src="product?.images?.[0]" :alt="product.name" />
      <div class="name">
        {{ product.name }}<br />
        <span v-if="product.variants.length > 1">
          <a href="#">
            <small>{{ product.variants.length }} variants</small>
          </a>
        </span>
      </div>
      <div class="status" :class="statusColor(product.status)">{{ product.status }}</div>
      <div class="actions">
        <div
          v-show="!product.included"
          class="btn"
          @click="updateProductSelection({ productId: product._id, included: false })"
        >
          <IconLibrary name="add-product" size="sm" />Add to collection
        </div>
      </div>
    </article>
    <Pagination
      :currentPage="productStore.currentPage"
      :hasNextPage="productStore.hasNextPage"
      :pageSize="productStore.pageSize"
      @update:page="changePage"
    />
  </div>
</template>

<script setup>
import FormSearch from '../Shared/Forms/FormSearch.vue';
import IconLibrary from '../Shared/IconLibrary.vue';
import Pagination from '../Shared/Pagination.vue';
import { useProductStore } from '../../stores/products.js';
import { storeToRefs } from 'pinia';
import { ref, onMounted, watch } from 'vue';

const productStore = useProductStore();
const { products } = storeToRefs(productStore);

const allProducts = ref([]);

function changePage(page) {
  productStore.fetchProducts({ page, pageSize: productStore.pageSize });
}

const { modelValue } = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['update:modelValue']);

const emitUpdatedModelValue = () => {
  const selectedProductIds = allProducts.value.filter((p) => p.included).map((p) => p._id);
  emit('update:modelValue', selectedProductIds);
};

onMounted(async () => {
  await productStore.fetchProducts({
    page: productStore.currentPage,
    pageSize: productStore.pageSize
  });
  updateAllProducts();
});

watch(products, () => {
  updateAllProducts();
});

const updateAllProducts = () => {
  allProducts.value = products.value.map((product) => {
    const existingProduct = modelValue.find((productId) => productId === product._id);
    return {
      ...product,
      included: !!existingProduct,
      custom_price: existingProduct ? existingProduct.custom_price : null,
      quantity_rules: existingProduct
        ? existingProduct.quantity_rules
        : { increment: null, min: null, max: null }
    };
  });
};

const updateProductSelection = ({ productId, included = false }) => {
  const product = allProducts.value.find((d) => d._id === productId);
  if (product) {
    product.included = !included;
    emitUpdatedModelValue();
  }
};

const statusColor = (status) => (status === 'active' ? 'green' : 'yellow');
</script>

<style lang="scss" scoped>
nav.filters {
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid $gainsboro;
  a {
    text-decoration: none;
    font-size: 90%;
    color: #444444;
    padding: 12px 8px;
    margin-bottom: -1px;
    border-bottom: 3px solid transparent;
  }
  a.active {
    border-bottom: 3px solid $brand-blue;
  }
  a:hover {
    border-bottom: 3px solid $gainsboro;
  }
}
section.search {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

article.product {
  display: grid;
  grid-template-columns: 50px 1fr auto 385px;
  grid-template-rows: 1fr;
  grid-template-areas: 'image name status actions';
  align-items: center;
  gap: 0 1rem;
  color: $brand-deep-purple;
  padding: 0.75rem 1rem;
  background-color: $white;
  transition: background-color $base-speed-fast ease;
}

article.product:nth-of-type(even) {
  border-top: 1px dashed rgba($brand-light-purple, 0.75);
  border-bottom: 1px dashed rgba($brand-light-purple, 0.75);
}
article.product:last-child {
  border-bottom: none;
}
article.product:hover {
  cursor: default;
  background-color: rgba($brand-mint, 0.05);
}

article .status {
  padding: 3px 14px;
  border-radius: 100px;
  font-size: 85%;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  background-color: $brand-light-gold;
  &.green {
    background-color: $brand-light-mint;
  }
}

// Grid Area
.product {
  grid-area: product;
}
.image {
  grid-area: image;
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: $base-radius;
  overflow: hidden;
  border: 1px solid rgba($brand-blue, 0.1);
  padding: 2px;
}
.name {
  grid-area: name;
  small {
    font-size: 90%;
    color: $brand-blue;
  }
}
.status {
  grid-area: status;
}
.price {
  grid-area: price;
  position: relative;
}
.actions {
  grid-area: actions;
  display: flex;
  justify-content: flex-end;
}
.warning {
  position: absolute;
  font-size: 85%;
  bottom: -20px;
  opacity: 0.75;
}

.form-input {
  padding: 0 0px;
  position: relative;
  label {
    color: rgba($black, 0.65);
  }
}
</style>
