import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import i18n from './plugins/i18n';
import VueApexCharts from 'vue3-apexcharts';
import { useUserStore } from './stores/user.js';

async function initApplication() {
  const app = createApp(App);
  const pinia = createPinia();

  app.use(pinia);
  app.use(i18n);
  app.use(router);
  app.use(VueApexCharts);

  const userStore = useUserStore();

  try {
    await userStore.auth();
  } catch (e) {
    console.error('User not authenticated!');
  }

  app.mount('#root');
}

initApplication();
