<template>
  <div class="receiver">
    <section>
      <h5>{{ $t('order.receiver.title') }}</h5>
      <h6>{{ order.to_address.full_name }}</h6>
      <p>
        <a :href="`mailto:${order.to_address?.email}`"> {{ order.to_address?.email }}</a
        ><br />
        <a href="#"> {{ order.to_address?.phone }} </a>
        <!-- <a href="#">5 commandes</a> -->
      </p>
      <p v-if="order.director_vat_number">{{ $t('ui.vat') }}: {{ order.director_vat_number }}</p>
    </section>
    <section>
      <header>
        <h5>{{ $t('order.address.title') }}</h5>
      </header>
      <h6>{{ order.to_address.identifier }}</h6>
      <div class="address">
        <div>
          <p>{{ order.to_address.address1 }}</p>
          <p v-if="order.to_address.address2">{{ order.to_address.address2 }}</p>
          <p>{{ order.to_address.zip }} {{ order.to_address.city }}</p>
          <p>
            <img class="flag" :src="`/flags/${order.to_address.country}.svg`" />
            {{ countriesByCode[order.to_address.country] }}
          </p>
        </div>
      </div>
    </section>
    <section v-if="order.note">
      <header>
        <h5>{{ $t('order.note.title') }}</h5>
      </header>
      <p>{{ order.note }}</p>
    </section>
  </div>
</template>

<script setup>
import { useOrderStore } from '../../stores/orders.js';
import countriesByCode from '../../libs/countries_by_code.json';

const orderStore = useOrderStore();
const order = orderStore.order;
</script>

<style scoped lang="scss">
.receiver {
  padding: 25px;
  section + section {
    border-top: 1px solid darken($brand-light-indigo, 15%);
    margin: 25px 0 0;
    padding: 20px 0 0;
  }
  section > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h5,
  h6 {
    margin: 0;
  }
  h6 {
    color: $monsoon;
    font-weight: $font-weight-light;
  }
  .address {
    margin-top: 15px;
    p {
      margin: 4px 0;
    }
  }
}
</style>
