import { onBeforeUnmount } from 'vue';
import { useCartStore } from '../stores/cart.js';

export const CART_STORAGE = 'CART_STORAGE';

export const usePersistCart = () => {
  const cartStore = useCartStore();
  const unsub = cartStore.$subscribe(() => {
    localStorage.setItem(CART_STORAGE, JSON.stringify(cartStore.purchases));
  });

  onBeforeUnmount(() => unsub());
};
