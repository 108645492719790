export { useUserStore };

import { defineStore } from 'pinia';
import { kyWithAuth } from '../libs/ky';

const useUserStore = defineStore('user', {
  state: () => {
    return {
      user: null,
      isLogged: false
    };
  },
  actions: {
    async auth() {
      const jwt = window.localStorage.getItem('jwt');
      if (!jwt) {
        this.user = null;
        this.isLogged = false;
        return;
      }
      try {
        const user = await kyWithAuth.get('users/is-authenticated').json();
        this.user = user;
        this.isLogged = true;
      } catch (err) {
        this.user = null;
        this.isLogged = false;
        window.localStorage.removeItem('jwt');
        console.error({ ERROR: err });
        throw new Error('User not authenticated!');
      }
    },
    async login({ email, password }) {
      const json = { login: email, password: password };
      const { jwt } = await kyWithAuth.post('users/login', { json }).json();
      window.localStorage.setItem('jwt', jwt);
      const user = await kyWithAuth.get('users/is-authenticated').json();
      this.user = user;
      this.isLogged = true;
    },
    async logout() {
      this.user = null;
      this.isLogged = false;
      window.localStorage.removeItem('jwt');
    },
    async register({ fullname, company, email, password, role = 'director' }) {
      const json = { fullname, company, email, password, role };
      await kyWithAuth.post('users/register', { json }).json();
    },
    async reset({ email }) {
      const json = { email };
      await kyWithAuth.post('users/reset', { json }).json();
    },
    async editPassword({ password, jwt }) {
      try {
        const json = { password };
        window.localStorage.setItem('jwt', jwt);
        await kyWithAuth.post('users/password', { json }).json();
      } catch (e) {
        console.error(e);
      } finally {
        window.localStorage.removeItem('jwt');
      }
    },
    async verifyMail() {
      try {
        const { jwt } = await kyWithAuth.patch('users/verify-mail').json();
        if (jwt) {
          this.isMailVerified = true;
          this.isLogged = true;
          return jwt;
        }
      } catch (e) {
        this.isMailVerified = false;
        this.isLogged = false;
        throw e.error;
      }
    },
    async updateUser(userData) {
      try {
        await kyWithAuth.patch('users', { json: userData }).json();
      } catch (e) {
        console.error(e);
      }
    },
    async uploadLogo(formData) {
      try {
        const { logo } = await kyWithAuth.post('users/upload', { body: formData }).json();
        this.user.logo = logo;
      } catch (e) {
        console.error(e);
      }
    },
    async deleteLogo() {
      try {
        const user = await kyWithAuth.post('users/upload/delete').json();
        this.user = user;
      } catch (e) {
        console.error(e);
      }
    }
  }
});
