<template>
  <article class="product-group boxed">
    <h5>
      Fournisseur <span class="corail">{{ sellerName }}</span>
    </h5>
    <div v-for="product in products" :key="product.productId" class="product">
      <IconLibrary
        name="delete"
        color="red"
        size="sm"
        class="delete-margin"
        @click="removeProduct(product)"
      />
      <div class="img-wrapper">
        <img :src="product.image" :alt="product.name" />
      </div>
      <div class="content-wrapper">
        <small>{{ product.seller }}</small>
        <h6>{{ product.name }}</h6>
        <small v-if="product.options">{{ product.options }}</small
        ><br v-if="product.options" />
        <small>SKU: {{ product.sku }}</small>
      </div>
      <div>
        <FormNumberInput
          title="forms.label.quantity"
          type="number"
          :label="false"
          v-model.number="product.quantity"
          :quantity_rules="{ min: product.min, max: product.max, increment: product.increment }"
          :compact="true"
          @input="updateQuantity(product)"
        />
      </div>
      <div>{{ formatMoney({ amount: product.price }) }}</div>
    </div>
    <div class="subtotal">
      <h6>
        Sous-total: <strong>{{ formatMoney({ amount: subtotal }) }}</strong>
      </h6>
      <small v-if="shippingFeeIncluded">
        Frais de livraison <strong>inclus</strong> dans le prix
      </small>
      <small v-else> Frais de livraison <strong>non inclus</strong> dans le prix </small>
    </div>
  </article>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useCartStore } from '../../stores/cart.js';
import { formatMoney } from '../../libs/utils.js';
import IconLibrary from '../Shared/IconLibrary.vue';
import FormNumberInput from '../Shared/Forms/FormNumberInput.vue';

const cartStore = useCartStore();

const { products } = defineProps({
  products: {
    type: Array,
    required: true
  }
});

const sellerId = computed(() => products[0]?.sellerId);
const seller = computed(() => cartStore.sellers.find((seller) => seller._id === sellerId.value));
const sellerName = computed(() => {
  return seller.value ? seller.value.company || seller.value.full_name : 'In-House';
});
const shippingFeeIncluded = computed(() => {
  // Ensure buyer and seller data are available
  if (!cartStore.buyer || !seller.value) {
    return false;
  }

  // Determine the directorId based on the buyer's role
  const directorId =
    cartStore.buyer.role === 'buyer'
      ? cartStore.buyer.directors?.[0]?.user_id
      : cartStore.buyer._id;

  if (!directorId) {
    return false;
  }

  // Find the matching director in the seller's directors list
  const matchedDirector = seller.value.directors?.find(
    (director) => director.user_id.toString() === directorId.toString()
  );

  // Return the shipping fee status
  return matchedDirector ? matchedDirector.shipping_fee_included : false;
});

const subtotal = computed(() => {
  return products.reduce((sum, product) => sum + product.price * product.quantity, 0);
});

const removeProduct = (product) => {
  cartStore.remove({ productId: product.productId, variantId: product.variantId });
};

const updateQuantity = (product) => {
  cartStore.updateQuantity({
    productId: product.productId,
    variantId: product.variantId,
    quantity: product.quantity
  });
};
</script>

<style scoped lang="scss">
.product-group {
  margin-bottom: 2rem;
}

h5 {
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba($brand-light-purple, 0.3);
}

.product {
  display: grid;
  grid-template-columns: 24px 65px 1fr 90px 90px;
  gap: 25px;
  align-items: center;
  .img-wrapper {
    width: 65px;
    height: 65px;
    overflow: hidden;
    box-shadow: 0px 0px 8px rgba($black, 0.1);
    border-radius: $base-radius;
    border: 2px solid $white;
    background-color: $white;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.product > *:last-child {
  justify-self: end;
}
.corail {
  color: $brand-corail;
}
.content-wrapper {
  flex-grow: 1;
}

.delete-margin {
  margin-right: 1rem;
}
.subtotal {
  text-align: end;
  margin-top: 1rem;
  border-top: 1px solid rgba($brand-light-purple, 0.3);
  padding-top: 1rem;
}
</style>
