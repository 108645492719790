<template>
  <div class="language-container">
    <label for="language-switcher">{{ $t('forms.label.language') }}</label>
    <div class="language-dropdown" tabindex="0" @blur="isOpen = false">
      <div class="language-selected" @click="isOpen = !isOpen">
        <p>
          <img class="flag" :src="`/flags/${locale.slice(-2)}.svg`" /> {{ languageNames[locale] }}
        </p>
        <IconLibrary name="arrow-down" size="xs" :hasMargin="false" :isBold="true" />
      </div>
      <div class="language-options" :class="{ 'is-hidden': !isOpen }">
        <article v-for="lang in availableLocales" :key="lang" @click="changeLanguage(lang)">
          <img class="flag" :src="`/flags/${lang.slice(-2)}.svg`" />
          {{ languageNames[lang] }}
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import IconLibrary from './IconLibrary.vue';

const languageNames = {
  'fr-FR': 'Français',
  'en-UK': 'English',
  'pt-PT': 'Português'
};

const { locale, availableLocales } = useI18n({ useScope: 'global' });
const isOpen = ref(false);

const changeLanguage = (lang) => {
  locale.value = lang;
  window.localStorage.setItem('lang', lang);
  isOpen.value = false;
};
</script>

<style scoped lang="scss">
.language-container {
  padding: 15px 0;
  width: 50%;
}
.language-dropdown {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
}

.language-dropdown .language-selected {
  background-color: $brand-light-indigo;
  border-radius: $base-radius;
  border: 1px solid darken($brand-light-indigo, 10%);
  color: $brand-deep-purple;
  display: flex;
  padding: 4px 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  p {
    margin: 4px 0;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  &.is-open {
    border: 1px solid $brand-deep-purple;
  }
  .icon-wrapper {
    margin-left: 10px;
  }
}

.language-dropdown .language-options {
  position: absolute;
  overflow: hidden;
  color: $brand-deep-purple;
  border: 1px solid darken($brand-light-indigo, 10%);
  border-radius: $base-radius;
  background-color: $brand-light-indigo;
  padding: 4px 0;
  z-index: 1;
  margin-top: -3px;
  min-width: 115%;
  box-shadow: 2px 2px 8px rgba($brand-indigo, 0.1);
}

.language-dropdown .language-options article {
  cursor: pointer;
  user-select: none;
  padding: 4px 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  transition: background-color $base-speed-fast ease;
}

.language-dropdown .language-options article:hover {
  background-color: darken($brand-light-indigo, 5%);
}
.flag {
  display: inline-block;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  width: 18px;
  height: 13.5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 1px;
}

.is-hidden {
  display: none;
}
</style>
