<template>
  <div class="orders">
    <header>
      <nav class="filters">
        <a href="#" class="active">Toutes</a>
        <a href="#">En attente</a>
        <a href="#">Fermée</a>
      </nav>
      <section class="search">
        <FormSearch title="forms.label.search" placeholder="forms.placeholder.filter_order" />
        <a class="btn">
          <IconLibrary name="sort" color="white" size="sm" />
          Trier
        </a>
      </section>
    </header>
    <router-link
      v-for="order in orderStore.getSortedOrders"
      class="order"
      :key="order._id"
      :to="`/shop/orders/${order._id}`"
    >
      <FormCheckbox />
      <div class="name">{{ order.to_address.full_name }}</div>
      <div class="ref"><FlagLibrary :code="order.to_address.country" /> {{ order.identifier }}</div>
      <div class="total">
        <MoneyFormat :amount="getTotal(order.products)" currency="EUR" />
      </div>
      <div v-if="hasTags" class="tags"><!-- Tag Component Here --></div>
      <FormattedDate :date="order.created_at" />
      <div class="payment">
        <span class="tag paid">
          <IconLibrary name="status" :color:="statusColor(order.is_paid)" size="xs" />
          {{ order.is_paid ? 'Paid' : 'Unpaid' }}
        </span>
      </div>
      <div class="fulfillment">
        <span class="tag unfulfilled">
          <IconLibrary name="status" :color="statusColor(order.is_fulfilled)" size="xs" />
          {{ order.is_fulfilled ? 'Fulfilled' : 'Unfulfilled' }}
        </span>
      </div>
    </router-link>
  </div>
</template>

<script setup>
import { getTotal } from '../../libs/utils';
import { useOrderStore } from '../../stores/orders.js';
import FormattedDate from '../Shared/FormattedDate.vue';
import FormCheckbox from '../Shared/Forms/FormCheckbox.vue';
import MoneyFormat from '../Shared/MoneyFormat.vue';
import FormSearch from '../Shared/Forms/FormSearch.vue';
import IconLibrary from '../Shared/IconLibrary.vue';
import FlagLibrary from '../Shared/FlagLibrary.vue';

const orderStore = useOrderStore();
const hasTags = false; // TODO
const statusColor = (status) => (status ? 'green' : 'yellow');
defineProps({
  type: { type: Object, required: true, default: 'list' }
});
</script>

<style lang="scss" scoped>
:deep(.form-checkbox) {
  padding: 0 0;
}
nav.filters {
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid $gainsboro;
  a {
    text-decoration: none;
    font-size: 90%;
    color: #444444;
    padding: 12px 8px;
    margin-bottom: -1px;
    border-bottom: 3px solid transparent;
  }
  a.active {
    border-bottom: 3px solid $brand-blue;
  }
  a:hover {
    border-bottom: 3px solid $gainsboro;
  }
}
section.search {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}
a.order {
  display: grid;
  grid-template-columns: 35px 1fr 1fr 130px 130px;
  grid-template-rows: 1fr 1fr;
  gap: 0px 20px;
  height: 65px;
  width: 100%;
  color: $brand-deep-purple;
  grid-template-areas:
    'checkbox name date payment fulfillment'
    'checkbox ref total payment fulfillment';
  padding: 10px 15px;
  align-items: center;
  background-color: $white;
  text-decoration: none;
  transition: background-color $base-speed-fast ease;
}
a.order.tags {
  grid-template-areas:
    'checkbox name date shipping'
    'checkbox ref tags tags';
}
a.order:nth-of-type(even) {
  border-top: 1px dashed rgba($brand-light-purple, 0.75);
  border-bottom: 1px dashed rgba($brand-light-purple, 0.75);
}
a.order:last-child {
  border-bottom: none;
}
a.order:hover {
  cursor: pointer;
  background-color: rgba($brand-mint, 0.05);
}
a.order.router-link-active {
  background-color: rgba($brand-light-purple, 0.4);
  color: $white;
}

// Grid Area
.form-checkbox {
  grid-area: checkbox;
  text-align: center;
}
.shop {
  grid-area: shop;
  display: flex;
  justify-content: center;
}
.name {
  grid-area: name;
}
.ref {
  grid-area: ref;
  display: flex;
  align-items: center;
}
.tags {
  grid-area: tags;
}
.date {
  grid-area: date;
}
.shipping {
  grid-area: shipping;
}
.payment {
  grid-area: payment;
}
.fulfillment {
  grid-area: fulfillment;
}
.total {
  grid-area: total;
}
.payment,
.fulfillment {
  text-transform: capitalize;
  .tag {
    padding: 3px 14px;
    border-radius: 100px;
    font-size: 90%;
    display: inline-flex;
    align-items: center;
  }
  .tag.fulfilled,
  .tag.paid {
    background-color: $brand-light-mint;
  }
  .tag.unfulfilled,
  .tag.unpaid {
    background-color: $brand-light-indigo;
  }
  .tag.pending {
    background-color: $brand-light-gold;
  }
}
</style>
