<template>
  <div>
    <section v-if="showOptions">
      <FormInput title="forms.label.option" v-model="option" />
      <FormInput title="forms.label.values" @input="handleValue($event.target.value)" />
    </section>
    <nav class="flex">
      <div class="btn btn-dashed" @click="showOptions = !showOptions">
        {{
          showOptions
            ? $t('forms.button.cancel_variant_option')
            : $t('forms.button.add_variant_option')
        }}
      </div>
      <div
        class="btn"
        v-if="showOptions && optionValues.length > 0"
        @click="
          {
            $emit('update:modelValue', [{ name: option, values: optionValues }]);
            showOptions = !showOptions;
          }
        "
      >
        Save option
      </div>
    </nav>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import FormInput from '../Shared/Forms/FormInput.vue';

const showOptions = ref(false);
const option = ref('');
const optionValues = ref([]);

const handleValue = (optionValue) =>
  (optionValues.value = optionValue.split(',').map((option) => option.trim()));
</script>

<style lang="scss" scoped>
.flex {
  justify-content: flex-start;
  gap: 25px;
  .btn-dashed {
    flex-grow: 1;
  }
}
</style>
