<template>
  <Loader v-if="loading" id="page" />
  <div v-else id="page">
    <main>
      <header>
        <h2>{{ catalogId ? $t('collections.edit.title') : $t('collections.new.title') }}</h2>
        <div class="actions">
          <router-link to="/collections" class="btn btn-ghost btn-lg">
            <IconLibrary name="back" size="sm" />
            {{ $t('ui.back') }}
          </router-link>
          <div class="btn btn-lg" @click="onSubmit">
            <IconLibrary name="download" color="white" size="sm" />
            {{ $t('ui.submit') }}
          </div>
        </div>
      </header>
      <form @submit.prevent="onSubmit" id="add-catalog">
        <main>
          <section class="boxed">
            <h6>{{ $t('collections.info.title') }}</h6>
            <FormInput title="forms.label.title" v-model="form.title" />
            <FormTextArea title="forms.label.description" v-model="form.description" />
          </section>
          <section class="boxed">
            <h6>{{ $t('collections.cover.title') }}</h6>
            <FormUpload v-model="form.cover" :allowMultiple="false" />
          </section>
        </main>
        <aside>
          <section class="boxed">
            <h6>{{ $t('collections.type.title') }}</h6>
            <FormRadio name="collection-type" :options="options" v-model="form.type" />
          </section>
          <ProductList v-if="form.type === 'manual'" class="boxed" v-model="products" />
          <section class="boxed conditions" v-else>
            <h6>{{ $t('collections.conditions.title') }}</h6>
            <ConditionRow
              v-for="condition in conditions"
              :key="condition.id"
              :id="condition.id"
              :totalRows="conditions.length"
              :condition="condition"
              @deleteCondition="deleteCondition(condition.id)"
              @update:field="updateField"
              @update:condition="updateCondition"
              @update:value="updateValue"
            />
            <FormButton
              :title="$t('forms.button.add_conditions')"
              type="button"
              @click="addCondition"
            />
          </section>
        </aside>
      </form>
      <footer>
        <div class="actions">
          <router-link to="/collections" class="btn btn-ghost btn-lg">
            <IconLibrary name="back" size="sm" />
            {{ $t('ui.back') }}
          </router-link>
          <div class="btn btn-lg" @click="onSubmit">
            <IconLibrary name="download" color="white" size="sm" />
            {{ $t('ui.submit') }}
          </div>
        </div>
      </footer>
    </main>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useCollectionStore } from '../stores/collections.js';
import { kyWithAuth } from '../libs/ky';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import FormInput from '../components/Shared/Forms/FormInput.vue';
import FormTextArea from '../components/Shared/Forms/FormTextArea.vue';
import FormUpload from '../components/Shared/Forms/FormUpload.vue';
import FormButton from '../components/Shared/Forms/FormButton.vue';
import FormRadio from '../components/Shared/Forms/FormRadio.vue';
import Loader from '../components/Shared/Loader.vue';
import ProductList from '../components/Collections/CollectionProductList.vue';
import ConditionRow from '../components/Collections/CollectionConditionRow.vue';

const collectionStore = useCollectionStore();

const route = useRoute();
const router = useRouter();

const collectionId = route.params.collection_id;

const options = [
  {
    label: 'Manual collection',
    description: 'Manually add products to this collection one by one.',
    value: 'manual'
  },
  {
    label: 'Automated collection',
    description:
      'Existing and future products that match the conditions you set will automatically be added to this collection.',
    value: 'automated'
  }
];

const products = ref([]);

const conditions = ref([
  {
    id: 0,
    field: 'category',
    condition: 'equals',
    value: ''
  }
]);

let nextId = 1;

const addCondition = () => {
  conditions.value.push({
    id: nextId++,
    field: 'category',
    condition: 'equals',
    value: ''
  });
};
const deleteCondition = (id) =>
  (conditions.value = conditions.value.filter((condition) => condition.id !== id));

const form = ref({
  title: '',
  description: '',
  type: 'manual',
  cover: []
});

const loading = ref(true);

onMounted(async () => {
  if (collectionId) {
    try {
      const collection = await collectionStore.fetchCollection(collectionId);
      form.value = {
        title: collection.title,
        description: collection.description,
        type: collection.type,
        cover: collection.cover ? [collection.cover] : []
      };
      conditions.value = collection.conditions?.length ? collection.conditions : conditions.value;
      products.value = collection.products;
      nextId =
        collection.type === 'manual'
          ? 0
          : Math.max(...collection.conditions.map((condition) => condition.id)) + 1;
    } catch (e) {
      console.error('error fetching catalog:', e);
    } finally {
      loading.value = false;
    }
  } else {
    loading.value = false;
  }
});

async function onSubmit() {
  const existingImage = typeof form.value.cover[0] === 'string';
  const newFile = typeof form.value.cover[0] !== 'string';
  console.log({ existingImage, newFile });
  try {
    let uploadedImageURL = '';
    if (newFile) {
      const formData = new FormData();
      formData.append('image', form.value.cover[0]);

      const res = (await kyWithAuth.post('collections/cover', { body: formData }).json()) || '';
      uploadedImageURL = res.image;
    }
    const submissionData = {
      title: form.value.title,
      description: form.value.description,
      type: form.value.type,
      cover: existingImage ? form.value.cover[0] : uploadedImageURL
    };

    if (form.value.type === 'manual') {
      submissionData.products = products.value;
    } else if (form.value.type === 'automated') {
      submissionData.conditions = conditions.value;
    }

    if (collectionId) {
      await collectionStore.updateCollection(collectionId, submissionData);
    } else {
      await collectionStore.addCollection(submissionData);
    }

    router.push({ name: 'Collections' });
  } catch (error) {
    console.error(error);
  }
}

const updateValue = ({ id, value }) => {
  const index = conditions.value.findIndex((condition) => condition.id === id);
  if (index !== -1) {
    conditions.value[index].value = value;
  }
};

const updateField = ({ id, value }) => {
  const index = conditions.value.findIndex((condition) => condition.id === id);
  if (index !== -1) {
    conditions.value[index].field = value;
  }
};

const updateCondition = ({ id, value }) => {
  const index = conditions.value.findIndex((condition) => condition.id === id);
  if (index !== -1) {
    conditions.value[index].condition = value;
  }
};
</script>

<style scoped lang="scss">
#page main > header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .actions {
    display: flex;
    gap: 20px;
  }
}

#page main > footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
  .actions {
    display: flex;
    gap: 20px;
  }
}

h6 {
  margin-bottom: 12px;
  font-size: 95%;
}
form main {
  display: grid;
  grid-template-columns: 1fr minmax(300px, 35%);
  gap: 0 25px;
}
form aside {
  margin-top: 25px;
}
.boxed + .boxed {
  margin-top: 1.75rem;
}
@media screen and (max-width: 1040px) {
  form main {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
  }
}
</style>
