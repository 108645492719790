/**
 * i18n Configuration for Vue 3 Project
 *
 * This module sets up the internationalization (i18n) configurations for the Vue application.
 * It auto-imports all the translation files present in the 'public/i18n' directory with the '.i18n.json' extension,
 * and then initializes Vue's i18n plugin with these translations.
 *
 * As of the current version, we support 3 languages:
 *  - English (en)
 *  - French (fr)
 *  - Portuguese (pt)
 *
 * The default language is determined based on the browser's language setting. If it doesn't match any of the
 * supported languages, English will be set as the default.
 *
 * Developers are encouraged to add or modify translations by adding or updating the respective '.i18n.json' files.
 */

import { createI18n } from 'vue-i18n';

const extractLocale = (filePath) => filePath.split('/')[1].split('.')[0];

const loadI18nData = () => {
  const i18nFiles = require.context('../../public/i18n', true, /\.i18n\.json$/i);
  const i18nData = {};

  i18nFiles.keys().forEach((fileKey) => {
    const locale = extractLocale(fileKey);
    i18nData[locale] = i18nFiles(fileKey);
  });

  return i18nData;
};

const determineDefaultLocale = (availableLocales) => {
  for (let locale of availableLocales) {
    const currentLanguage = window.localStorage.getItem('lang');
    if (currentLanguage) return currentLanguage;
    if (navigator.language.includes(locale.split('-')[0])) {
      return locale;
    }
  }
  return 'fr-FR';
};

const i18nData = loadI18nData();
const availableLocales = Object.keys(i18nData);

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  missingWarn: false,
  fallbackWarn: false,
  locale: determineDefaultLocale(availableLocales),
  availableLocales,
  messages: i18nData
});

export default i18n;
