<template>
  <header>
    <section>
      <h2>{{ $t('order.information.title') }}</h2>
      <span class="tag paid">
        <IconLibrary
          name="status"
          :color:="orderStore.order.is_paid ? 'green' : 'yellow'"
          size="xs"
        />
        {{ orderStore.order.is_paid ? 'paid' : 'unpaid' }}
      </span>
      <span class="tag fulfilled">
        <IconLibrary name="status" :color="order.is_fulfilled ? 'green' : 'yellow'" size="xs" />
        {{ order.is_fulfilled ? 'fulfilled' : 'unfulfilled' }}
      </span>
      <!-- <div v-if="userStore.user?.role !== 'buyer'" class="actions">
        <IconLibrary
          name="delete"
          color="red"
          size="sm"
          class="delete-margin clickable"
          @click="$emit('onOrderDelete')"
        />
      </div> -->
    </section>
    <section>
      <h4 class="identifier">{{ order.identifier }}</h4>
      <FormattedDate :date="order.created_at" />
      <span><MoneyFormat v-if="total" :amount="total" currency="EUR" /> HT</span>
      <span>
        <strong> <MoneyFormat v-if="total" :amount="totalWithTax" currency="EUR" /> TTC </strong>
      </span>
      <div class="btn" v-if="userStore.user?.role === 'seller'">
        <IconLibrary name="download" size="sm" />
        <button @click="generatePDF(order._id)">Packing Slip</button>
      </div>
    </section>
  </header>
</template>

<script setup>
import { computed } from 'vue';
import { useOrderStore } from '../../stores/orders.js';
import { useUserStore } from '../../stores/user.js';
import { getTotal } from '../../libs/utils.js';
import FormattedDate from '../Shared/FormattedDate.vue';
import MoneyFormat from '../Shared/MoneyFormat.vue';
import IconLibrary from '../Shared/IconLibrary.vue';

const orderStore = useOrderStore();
const userStore = useUserStore();
const order = orderStore.order;
const total = orderStore.getTotal;

//TODO: This is to make work the tax display before SellerId fix.
//Needs a better solution through the sellerId that should be available now.
const matchedTax = computed(() =>
  order.taxes.find((tax) => {
    return tax.subtotal === getTotal(order.products);
  })
);

const totalWithTax = computed(() =>
  order.order_id ? matchedTax.value.total : order.total_with_tax
);

const generatePDF = async (orderId) => {
  const response = await orderStore.fetchPackingList(orderId);
  const blob = await response.blob();
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `PACKING-SLIP-${order.identifier.replace(/^#/, '')}.pdf`;
  link.click();
  URL.revokeObjectURL(link.href);
};
</script>

<style scoped lang="scss">
.actions {
  margin-left: auto;
}
header {
  h2 {
    margin: 0 0 5px;
  }
  h4 {
    color: $brand-blue;
  }
  > section {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .tag {
    padding: 3px 14px;
    border-radius: 100px;
    font-size: 90%;
    display: inline-flex;
    align-items: center;
    text-transform: capitalize;
  }
  .tag.fulfilled,
  .tag.paid {
    background-color: $brand-light-mint;
  }
  .tag.unfulfilled,
  .tag.unpaid {
    background-color: $brand-light-indigo;
  }
  .tag.pending {
    background-color: $brand-light-gold;
  }
  .btn {
    margin-left: auto;
  }
}
</style>
