<template>
  <main>
    <section>
      <img class="logo" src="/logos/logo-nuteam-white.svg" alt="NuTeam Logo" />
      <h1>{{ $t('signup.title') }}</h1>
      <form @submit.prevent="register">
        <p class="white">
          Choisissez le profil adapté à vos besoins pour profiter d'une expérience sur mesure
        </p>
        <FormRadio name="user-type" :options="options" styling="dark" v-model="role" />
        <section v-if="role && role !== 'buyer'">
          <FormInput
            title="forms.label.full_name"
            placeholder="forms.placeholder.full_name"
            v-model="fullname"
          />
          <FormInput
            title="forms.label.company"
            placeholder="forms.placeholder.company"
            v-model="company"
          />
          <FormInput
            title="forms.label.email"
            placeholder="forms.placeholder.email"
            v-model="email"
          />
          <FormInput
            title="forms.label.password"
            placeholder="forms.placeholder.password"
            type="password"
            v-model="password"
          />
          <div class="help">
            {{ $t('signup.password_requirements') }}
          </div>
          <FormButton title="forms.button.signup" />
        </section>
        <section v-if="role === 'buyer'" class="message">
          Demandez à votre directeur des achats de vous inviter à rejoindre notre communauté. S'il
          n'a pas encore de compte, envoyez-lui ce lien pour qu'il puisse s'inscrire et vous faire
          découvrir notre plateforme.
        </section>

        <router-link class="link" to="/login">{{ $t('signup.login.label') }}</router-link>
        <div v-if="loginErrors && loginErrors.length > 0" class="errors">
          <h6>Oops, we found some errors!</h6>
          <ul>
            <li v-for="error in loginErrors" :key="error.code">{{ error.message }}</li>
          </ul>
        </div>
      </form>
    </section>
    <section class="img-container">
      <span class="badge version">
        {{ $t('ui.version') }}
        <strong>{{ version }}</strong>
      </span>
    </section>
  </main>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { version } from '../../package';
import { useUserStore } from '../stores/user';
import FormInput from '../components/Shared/Forms/FormInput.vue';
import FormButton from '../components/Shared/Forms/FormButton.vue';
import FormRadio from '../components/Shared/Forms/FormRadio.vue';

const userStore = useUserStore();
const router = useRouter();

const options = [
  {
    label: 'Director',
    description: 'You are a director of a group with franchises.',
    value: 'director'
  },
  {
    label: 'Seller',
    description: 'You want to sell your products.',
    value: 'seller'
  },
  {
    label: 'Buyer',
    description: 'You are a franchise or belong to bigger group.',
    value: 'buyer'
  }
];

const role = ref(undefined);
const fullname = ref('');
const company = ref('');
const email = ref('');
const password = ref('');
const loginErrors = ref([]);

const register = async () => {
  try {
    await userStore.register({
      fullname: fullname.value,
      company: company.value,
      email: email.value,
      password: password.value,
      role: role.value
    });
    router.push({ name: 'Login' });
  } catch (err) {
    const { errors } = await err.response.json();
    loginErrors.value = errors;
  }
};
</script>

<style scoped lang="scss">
#app > main {
  padding-bottom: 0px;
}
.help {
  border: 1px solid $brand-light-indigo;
  padding: 0.5rem 1rem;
  color: $brand-light-indigo;
  border-radius: $base-radius;
  font-size: 90%;
  margin-bottom: 1rem;
}
main {
  display: flex;
  min-height: 100vh;
}
:deep(label) {
  color: rgba($white, 0.7) !important;
}
h1 {
  margin: 15px 0 40px;
  color: lightgrey;
  font-size: 25px;
}
form {
  display: flex;
  flex-direction: column;
  section {
    margin-top: 2rem;
    > * + * {
      margin-top: 0.5rem;
    }
  }
}
.white {
  color: $brand-light-indigo;
  text-wrap: balance;
}
.link {
  color: $white;
  font-size: 90%;
  margin-top: 10px;
  text-align: right;
  transition: color 200ms ease-in-out;
  &:hover {
    color: $brand-corail;
  }
}
.message {
  border: 2px solid rgba(white, 0.25);
  border-radius: 6px;
  padding: 15px 20px;
  color: $brand-light-indigo;
  text-wrap: balance;
}
.errors {
  background-color: $white;
  border: 1px solid darken($brand-corail, 20%);
  border-radius: $base-radius;
  padding: 25px;
  margin-top: 25px;
  h6 {
    margin-bottom: 12px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      color: darken($brand-corail, 35%);
    }
  }
}
.logo {
  width: 150px;
}
.badge {
  border-radius: 20px;
  background-color: rgba($white, 0.6);
  color: $brand-dark-purple;
  border: 1px solid $brand-dark-purple;
  padding: 3px 18px;
  font-size: 9px;
  position: absolute;
  bottom: 18px;
  right: 18px;
  text-transform: uppercase;
}
main > section:not(.img-container) {
  padding: 50px 80px;
  width: 850px;
  background-color: $brand-dark-grey-purple;
}
main > .img-container {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-image: url('../../public/svg/illustration_login.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  background-color: #f7f8fd;
  box-shadow: -5px 0px 10px 0px rgba($black, 0.1);
  position: relative;
}

@media (min-width: 1440px) {
  main > .img-container {
    background-size: 70%;
  }
}

@media (max-width: 1023px) {
  main {
    display: grid;
    grid-template-rows: auto 1fr;
  }
  main > section:not(.img-container) {
    width: 100%;
  }
  main > .img-container {
    background-size: 55%;
  }
}

@media (max-width: 767px) {
  main > .img-container {
    background-size: 75%;
  }
}
</style>
