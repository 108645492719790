<template>
  <div id="page">
    <main>
      <header>
        <h2>{{ $t('collections.title') }}</h2>
        <div class="actions">
          <router-link class="btn btn-lg" to="/collections/new">
            <IconLibrary name="add-product" color="white" size="sm" />
            {{ $t('forms.button.create_collection') }}
          </router-link>
        </div>
      </header>
      <div v-if="collectionStore.collections.length < 1" class="boxed">
        <EmptyState>
          <IconLibrary name="product" size="xxl" />
          <h4>No Product Collections</h4>
          <p>
            Uh-oh, looks like you haven't created any collection yet!<br />
            To get started, click on the following link
            <a href="#">to create your first collection</a>.
          </p>
          <p>
            <small>
              Once created, it will appear here and you can start managing your team right away.
            </small>
          </p>
        </EmptyState>
      </div>
      <CollectionList v-else class="boxed" :collections="collectionStore.collections" />
    </main>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import CollectionList from '../components/Collections/CollectionList.vue';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import EmptyState from '../components/Shared/EmptyState.vue';
import { useCollectionStore } from '../stores/collections.js';
const collectionStore = useCollectionStore();

onMounted(async () => {
  try {
    await collectionStore.fetchCollections();
  } catch (e) {
    console.error(e);
  }
});
</script>

<style scoped lang="scss">
#page main > header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .actions {
    display: flex;
    gap: 20px;
  }
}
.corail {
  color: $brand-corail;
}
</style>
