import CurrencyJS from 'currency.js';
import { deepClone } from 'lodash';

export {
  whitelistHtmlTags,
  getFileName,
  getTotal,
  getRandomStr,
  toFloat,
  toInteger,
  omit,
  queryStringify,
  formatMoney,
  formatPrice
};

// use for v-html to authorize only some html tags
// https://v3.vuejs.org/api/directives.html#v-html
function whitelistHtmlTags(str) {
  // match the opening html tag, for example in <span></span> it will match <span
  const openingTags = str.match(/<[a-z\s]+?[ >]{1}/g);
  if (!openingTags) return str;
  const whitelistTags = ['a', 'span', 'br', 'b', 'em', 'strong', 'p'];
  const isValidTags = openingTags.every((openingTag) => {
    const tag = openingTag.substring(1, openingTag.length - 1);
    return whitelistTags.includes(tag);
  });
  const isDev = process.env.NODE_ENV !== 'production';
  if (isDev && !isValidTags) {
    console.error('html tags not whitelisted, has to be whitelist in the whitelistHtmlTags func', {
      tagToWhitelist: openingTags
    });
  }
  return isValidTags ? str : '';
}

// get file name without extension
function getFileName(filepath) {
  return filepath?.split?.('/')?.pop?.()?.split?.('.')?.[0];
}

function getTotal(products) {
  if (!products) return 0;
  const total = products.reduce((total, product) => {
    return total + product.price * product.quantity;
  }, 0);
  return parseFloat(total.toFixed(2));
}

function formatMoney({ amount, currency = 'EUR', showSymbol = true }) {
  const currencyMap = {
    EUR: '€',
    USD: '$',
    CHF: 'CHF'
  };
  const currencySymbol = currencyMap[currency];
  return CurrencyJS(amount, {
    symbol: showSymbol ? currencySymbol : '',
    pattern: `# !`
  }).format();
}

function formatPrice({ amount }) {
  // TODO: Handle different precision if needed.
  // TODO: When needed pass also the currency to the function.
  // Eg: { amount, currency = 'EUR' }
  return CurrencyJS(amount, { precision: 2 });
}

/**
 * delete keys in obj, handle nested paths (based on this SO: https://stackoverflow.com/questions/40712249/deleting-nested-property-in-javascript-object)
 * (Same as lodash omit, but lodash omit will be drop in lodash 5 for performance reason)
 * @param {Object} obj
 * @param {Array} keysToOmit
 * @param {Object} options
 * @param {Boolean} options.mutateObj - if true, mutate the obj instead of returning a new cloned object
 * @returns {Object} object without keysToOmit
 */
function omit(obj = {}, keysToOmit = [], { mutateObj = false } = {}) {
  const clonedObj = mutateObj ? obj : deepClone(obj);
  for (const path of keysToOmit) {
    const nestedPaths = path.split('.');
    if (nestedPaths.length > 1) {
      let objCopy = clonedObj;
      const last = nestedPaths.pop();
      // iterate over the nested paths except the most nested one (pop line before)
      for (const nestedPath of nestedPaths) {
        objCopy = objCopy[nestedPath];
      }
      delete objCopy[last];
    } else {
      delete clonedObj[path];
    }
  }
  if (!mutateObj) return clonedObj;
}

const isEmptyString = (input) => {
  return typeof input === 'string' && input.trim() === '';
};

const isEmpty = (input) => {
  return input === undefined || input === null || isEmptyString(input);
};

const isValidToParse = (input) => !isEmpty(input) && !Number.isNaN(input);

function toFloat(num, defaultNum = 0) {
  return isValidToParse(num) ? parseFloat(num) : defaultNum;
}

function toInteger(num, defaultNum = 0) {
  return isValidToParse(num) ? parseInt(num) : defaultNum;
}

function getRandomStr(length = 12) {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = 0; i < length; i += 1) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

function queryStringify(obj) {
  const searchParams = new URLSearchParams();

  Object.keys(obj).forEach((key) => {
    // special case for Array:
    // for example { fields: ['_id', 'identifier'] } convert to fields=_id&fields=identifier
    if (Array.isArray(obj[key])) {
      obj[key].forEach((value) => {
        searchParams.append(key, value);
      });
    } else {
      searchParams.append(key, obj[key]);
    }
  });

  const stringified = searchParams.toString();
  return stringified;
}
