export { useCollectionStore };
import { defineStore } from 'pinia';
import { kyWithAuth } from '../libs/ky';

const useCollectionStore = defineStore('collections', {
  state: () => {
    return {
      collections: [],
      collection: {},
      loading: false
    };
  },
  actions: {
    async fetchCollection(collectionId) {
      const { collection } = await kyWithAuth.get(`collections/${collectionId}`).json();
      this.collection = collection;
      return collection;
    },
    async fetchCollections() {
      this.loading = true;
      try {
        const { collections = [] } = await kyWithAuth.get('collections').json();
        this.collections = collections;
        return collections;
      } catch (error) {
        console.error('Error fetching product:', error);
      } finally {
        this.loading = false;
      }
    },
    async addCollection(collection) {
      this.loading = true;
      try {
        await kyWithAuth.post('collections', { json: collection }).json();
      } catch (error) {
        console.error('Error adding collection:', error);
      } finally {
        this.loading = false;
      }
    },
    async updateCollection(collectionId, collection) {
      this.loading = true;
      try {
        await kyWithAuth.patch(`collections/${collectionId}`, { json: collection }).json();
      } catch (error) {
        console.error('Error updating collection:', error);
      } finally {
        this.loading = false;
      }
    },
    async deleteCollections(collectionId) {
      try {
        await kyWithAuth.delete(`collections/${collectionId}`);
        this.collections = this.collections.filter((collection) => collection._id !== collectionId);
      } catch (e) {
        console.error(e);
      }
    }
  }
});
