<template>
  <div class="pagination">
    <button
      type="button"
      class="btn"
      @click="changePage(currentPage - 1)"
      :disabled="currentPage === 1"
    >
      Previous
    </button>
    <button type="button" class="btn" @click="changePage(currentPage + 1)" :disabled="!hasNextPage">
      Next
    </button>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  currentPage: Number,
  hasNextPage: { type: Boolean, default: false },
  pageSize: Number
});

const emit = defineEmits(['update:page']);

const lastPage = computed(() => (props.hasNextPage ? props.currentPage + 10 : props.currentPage));

function changePage(page) {
  if (page < 1 || page > lastPage.value) return;
  emit('update:page', page);
}
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-block: 1rem;
  .btn[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
