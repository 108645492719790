<template>
  <div class="invitations" v-if="invitations.length">
    <h5>Invitations en attente</h5>
    <article v-for="invitedSeller in invitations" class="boxed pending">
      <h6><IconLibrary name="seller" size="sm" /> {{ invitedSeller?.email }}</h6>
      <ul class="badges">
        <li class="btn btn-ghost btn-sm" @click="sendInvitation(invitedSeller)">
          <IconLibrary name="message" size="xs" />{{ $t('user.send_invitation') }}
        </li>
        <li class="badge">Pending</li>
      </ul>
    </article>
  </div>
  <div class="sellers" v-if="sellers.length">
    <h5>Vos fournisseurs actif</h5>
    <section>
      <article v-for="seller in sellers" class="boxed">
        <header>
          <div class="flex">
            <h6>
              {{ seller?.identifier || seller?.company || seller?.email }}
            </h6>
            <router-link
              :to="`/sellers/${seller._id}`"
              class="btn btn-ghost btn-sm"
              v-if="seller.is_kyc"
            >
              <IconLibrary name="edit" size="xs" />Edit
            </router-link>
          </div>
          <div class="flex">
            <div class="badge" :class="{ 'is-active': seller.is_verified }">
              {{ seller.is_verified === true ? 'Active' : 'Pending' }}
            </div>
            <div class="badge" v-if="!seller.is_kyc && seller.is_verified">KYC</div>
          </div>
        </header>
      </article>
    </section>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import IconLibrary from '../Shared/IconLibrary.vue';
import { kyWithAuth } from '../../libs/ky';
import { useUserStore } from '../../stores/user';

const userStore = useUserStore();

const sellers = ref([]);
const invitations = ref(
  userStore.user.invitations?.filter((invitation) => invitation.role === 'seller') || []
);

const sendInvitation = async (invitedSeller) => {
  await kyWithAuth
    .post('users/send-invitation', {
      json: {
        inviterCompanyName: userStore.user.company,
        email: invitedSeller.email,
        role: invitedSeller.role
      }
    })
    .json();
};

onMounted(async () => {
  try {
    const { users: res } = await kyWithAuth.get('users/list/sellers').json();
    sellers.value = res;
  } catch (e) {
    console.error(e);
  }
});
</script>

<style lang="scss" scoped>
h5 {
  margin-bottom: 0.75rem;
}

h6 {
  display: flex;
  align-items: center;
}

.sellers > section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.invitations {
  margin-bottom: 2.5rem;

  .boxed {
    display: flex;
    justify-content: space-between;

    &.pending + &.pending {
      margin-top: 0.75rem;
    }
  }
}

.flex {
  display: flex;
  gap: 12px;
  align-items: center;
}

.boxed {
  display: block;
  color: inherit;
}

.badge {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: rgba($brand-corail, 0.1);
  color: darken($brand-corail, 15%);
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: $font-weight-bold;

  &.is-active {
    background-color: rgba($brand-mint, 0.15);
    color: darken($brand-mint, 25%);
  }
}

.badges {
  list-style: none;
  margin: 0.75rem 0 0;
  padding: 0;
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul {
  list-style: none;
  margin: 1rem 0 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;

    + li {
      margin-top: 6px;
    }
  }
}
</style>
