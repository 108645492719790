export { useCartStore };

import { defineStore } from 'pinia';
import { kyWithAuth } from '../libs/ky';
import { useUserStore } from './user.js';
import EuropeanVAT from '../libs/european_vat.json';

const useCartStore = defineStore('cart', {
  state: () => {
    return {
      purchases: JSON.parse(localStorage.getItem('CART_STORAGE')) || {},
      sellers: [],
      buyer: {}
    };
  },
  getters: {
    getCount() {
      return Object.keys(this.purchases).length;
    },
    getCart() {
      return Object.values(this.purchases);
    },
    getSubTotal(state) {
      const purchases = Object.values(state.purchases);
      return purchases.reduce((total, purchase) => total + purchase.price * purchase.quantity, 0);
    },
    getDetailedTax(state) {
      const purchases = Object.values(state.purchases);
      const taxesBySeller = {};
      let grandTotal = 0;

      for (const purchase of purchases) {
        const { sellerId, price, quantity } = purchase;
        const seller = state.sellers.find((seller) => seller._id === sellerId);

        const fromCountry = seller?.address?.country || state.buyer?.address?.country;
        const toCountry = state.buyer?.address?.country;
        const taxRate = fromCountry === toCountry ? EuropeanVAT[fromCountry] : 0;
        const subTotal = price * quantity;
        const taxAmount = subTotal * (taxRate / 100);
        const total = subTotal + taxAmount;

        grandTotal += total;

        if (!taxesBySeller[sellerId || state.buyer._id]) {
          taxesBySeller[sellerId || state.buyer._id] = {
            sellerId,
            taxRate,
            subTotal: 0,
            taxAmount: 0,
            total: 0
          };
        }

        taxesBySeller[sellerId || state.buyer._id].subTotal += subTotal;
        taxesBySeller[sellerId || state.buyer._id].taxAmount += taxAmount;
        taxesBySeller[sellerId || state.buyer._id].total += total;
      }

      return { taxesBySeller: Object.values(taxesBySeller), grandTotal };
    }
  },
  actions: {
    async fetchSellers() {
      const purchases = Object.values(this.purchases);
      const sellerIds = purchases
        .filter((product) => product.sellerId !== undefined)
        .map((product) => product.sellerId);

      const uniqueSellerIds = [...new Set(sellerIds)];
      uniqueSellerIds.forEach(async (sellerId) => {
        const { user: seller } = await kyWithAuth.get(`users/${sellerId}`).json();
        this.sellers.push(seller);
      });
    },
    async fetchBuyer() {
      const userStore = useUserStore();
      this.buyer = await userStore.user;
    },
    add({
      productId,
      variantId,
      sellerId,
      quantity,
      name,
      price,
      image,
      options,
      sku,
      min,
      max,
      increment
    }) {
      const productKey = variantId ? `${productId}-${variantId}` : productId;
      if (this.purchases[productKey]) {
        this.purchases[productKey].quantity += quantity;
      } else {
        this.purchases[productKey] = {
          productId,
          variantId,
          sellerId,
          quantity,
          name,
          price,
          sku,
          image,
          options,
          min,
          max,
          increment
        };
      }
    },
    remove({ productId, variantId }) {
      const productKey = variantId ? `${productId}-${variantId}` : productId;
      if (!this.purchases[productKey]) {
        return;
      }
      delete this.purchases[productKey];
    },
    updateQuantity({ productId, variantId, quantity }) {
      const productKey = variantId ? `${productId}-${variantId}` : productId;
      if (this.purchases[productKey]) {
        this.purchases[productKey].quantity = quantity;
      }
    },
    cleanup() {
      this.purchases = {};
      this.sellers = [];
      this.buyer = {};
    }
  }
});
