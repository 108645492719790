<template>
  <div class="form-checkbox">
    <input type="checkbox" class="filled-in" :id="id" v-model="model" :disabled="disabled" />
    <label :for="id" :class="{ empty: !label }">
      <span v-if="label">{{ $t(label) }}</span>
    </label>
    <span v-if="error" :id="errorId" class="form-item-error">{{ error }}</span>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const model = defineModel();

const { disabled, label, error } = defineProps({
  disabled: { type: Boolean, default: false },
  label: { type: String },
  error: { type: String }
});

const randomId = Math.random().toString(36).substring(2, 9);
const id = computed(() => `checkbox-${randomId}`);
const errorId = computed(() => `error-${id.value}`);
</script>

<style scoped lang="scss">
.form-checkbox {
  padding: 10px 0;
}

[type='checkbox']:not(:checked),
[type='checkbox']:checked {
  position: absolute;
  left: -9999px;
  opacity: 0;
}

[type='checkbox'] + label {
  position: relative;
  padding-left: 30px;
  padding-bottom: 25px;
  top: 2px;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  font-size: 0.9rem;
  color: $brand-dark-purple;
}

[type='checkbox'] + label.empty {
  padding-left: 20px;
  padding-bottom: 0;
  background-color: /* $white */ #ffffff;
}

[type='checkbox'] + label.select-all {
  font-size: 90%;
  color: grey;
}

[type='checkbox'] + label:before,
[type='checkbox']:not(.filled-in) + label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid #5a5a5a;
  border-radius: 1px;
  margin-top: 2px;
  transition: 0.2s;
}

[type='checkbox']:not(.filled-in) + label:after {
  border: 0;
  transform: scale(0);
}

[type='checkbox']:not(:checked):disabled + label:before {
  border: none;
  background-color: rgba(0, 0, 0, 0.26);
}

[type='checkbox'].tabbed:focus + label:after {
  transform: scale(1);
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}

[type='checkbox']:disabled + label {
  cursor: default;
}

[type='checkbox'].filled-in + label:after {
  border-radius: 2px;
}

[type='checkbox'].filled-in + label:before,
[type='checkbox'].filled-in + label:after {
  content: '';
  left: 0;
  position: absolute;
  transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s,
    left 0.2s 0.1s;
  z-index: 1;
}

[type='checkbox'].filled-in:not(:checked) + label:before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
}

[type='checkbox'].filled-in:not(:checked) + label:after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #5a5a5a;
  top: 0px;
  z-index: 0;
}

[type='checkbox'].filled-in:checked + label:before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
}

[type='checkbox'].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid /* $brand-corail */ #ff5722;
  background-color: /* $brand-corail */ #ff5722;
  z-index: 0;
}

[type='checkbox'].filled-in.tabbed:focus + label:after {
  border-radius: 2px;
  border-color: #5a5a5a;
  background-color: rgba(0, 0, 0, 0.1);
}

[type='checkbox'].filled-in.tabbed:checked:focus + label:after {
  border-radius: 2px;
  background-color: /* $brand-corail */ #ff5722;
  border-color: /* $brand-corail */ #ff5722;
}

[type='checkbox'].filled-in:disabled:not(:checked) + label:before {
  background-color: transparent;
  border: 2px solid transparent;
}

[type='checkbox'].filled-in:disabled:not(:checked) + label:after {
  border-color: transparent;
  background-color: #bdbdbd;
}

[type='checkbox'].filled-in:disabled:checked + label:before {
  background-color: transparent;
}

[type='checkbox'].filled-in:disabled:checked + label:after {
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}
</style>
