<template>
  <div>
    <div
      class="dropzone-container"
      @dragover.prevent="isDragging = true"
      @dragleave="isDragging = false"
      @drop="handleDrop"
      v-if="!images.length"
    >
      <input
        type="file"
        :multiple="allowMultiple"
        ref="fileInput1"
        id="fileInput1"
        class="hidden-input"
        @change="handleFiles"
        accept=".jpg,.jpeg,.png,.webp"
      />
      <label for="fileInput1" class="file-label">
        <div v-if="isDragging">Release to drop files here.</div>
        <div v-else>Drop files here or <u>click here</u> to upload.</div>
      </label>
    </div>
    <div class="preview-container" :class="{ 'allow-multiple': allowMultiple }" v-else>
      <div v-for="(image, index) in images" :key="index" class="preview-card">
        <img class="preview-img" :src="getImageSrc(image)" />
        <div class="btn btn-ghost btn-delete" @click="removeImage(index)">
          <IconLibrary name="delete" color="red" size="xs" :hasMargin="false" />
        </div>
      </div>
      <div class="preview-card" v-if="allowMultiple">
        <div
          class="dropzone-container dropzone-container-square"
          @dragover.prevent="isDragging = true"
          @dragleave="isDragging = false"
          @drop="handleDrop"
        >
          <input
            type="file"
            multiple
            ref="fileInput2"
            id="fileInput2"
            class="hidden-input"
            @change="handleFiles"
            accept=".jpg,.jpeg,.png,.webp"
          />
          <label for="fileInput2" class="file-label">
            <div v-if="isDragging">Release to drop files here.</div>
            <div v-else>Drop files here or <u>click here</u> to upload.</div>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import IconLibrary from '../IconLibrary.vue';

const props = defineProps({
  modelValue: Array,
  allowMultiple: { type: Boolean, default: true },
  maxFiles: { type: Number, default: 9 }
});
const emit = defineEmits(['update:modelValue']);

const isDragging = ref(false);
const images = ref([]);

const getImageSrc = (image) => {
  if (typeof image === 'string') {
    return image;
  } else {
    return URL.createObjectURL(image);
  }
};

const handleFiles = (e) => {
  let files = Array.from(e.target.files || e.dataTransfer.files);
  files = files.filter((file) =>
    ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'].includes(file.type)
  );

  if (!props.allowMultiple) {
    files = files.slice(0, 1);
  } else if (props.maxFiles) {
    const totalAllowedFiles = props.maxFiles - images.value.length;
    files = files.slice(0, totalAllowedFiles);
  }

  const newImages = [...images.value, ...files];
  images.value = newImages.length > props.maxFiles ? newImages.slice(0, props.maxFiles) : newImages;
  emit('update:modelValue', images.value);
};

const handleDrop = (e) => {
  e.preventDefault();
  isDragging.value = false;
  handleFiles(e);
};

const removeImage = (index) => {
  images.value.splice(index, 1);
  emit('update:modelValue', images.value);
};

watch(
  () => props.modelValue,
  (newVal) => {
    images.value = newVal || [];
  },
  { deep: true, immediate: true }
);
</script>

<style scoped lang="scss">
.dropzone-container {
  padding: 50px;
  background: $brand-light-grey;
  border-radius: $base-radius;
  border: 1px dashed $brand-light-purple;
  text-align: center;
  &:hover {
    border: 1px dashed $brand-grey;
    cursor: pointer;
  }
  &-square {
    padding: 8px;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 90%;
  }
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  display: block;
  cursor: pointer;
  font-size: 90%;
  font-style: italic;
  color: $brand-grey;
  u {
    color: $brand-indigo;
  }
}
.preview-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 0px;
  margin-bottom: 30px;
  &.allow-multiple {
    grid-template-columns: repeat(4, 1fr);
  }
}

.preview-card {
  position: relative;
  .btn {
    position: absolute;
    top: -10px;
    left: -10px;
    background-color: $white;
    &:hover {
      background-color: $white;
    }
  }
}

.preview-container > :first-child {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
}

.preview-img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: $base-radius;
  box-shadow: 2px 2px 8px rgba($black, 0.15);
  object-fit: cover;
}
</style>
