<template>
  <nav v-for="option in filteredOptions">
    <label>{{ option.name }}</label>
    <div class="options">
      <div
        v-for="value in option.values"
        :key="value"
        :class="{ active: productStore.options[option.name] === value.toLowerCase() }"
        class="btn btn-ghost"
        @click="selectedVariant({ name: option.name, value: value.toLowerCase() })"
      >
        {{ value }}
      </div>
    </div>
  </nav>
</template>

<script setup>
import { useProductStore } from '../../stores/products.js';
const productStore = useProductStore();

const activeVariants = productStore.product.variants.filter(
  (variant) => variant.status !== 'deleted'
);

const filteredOptions = activeVariants.reduce((options, variant) => {
  variant?.options?.forEach((option) => {
    const existingOption = options.find((opt) => opt.name === option.name);

    if (!existingOption) {
      options.push({ name: option.name, values: [option.value] });
    } else {
      if (!existingOption.values.includes(option.value)) {
        existingOption.values.push(option.value);
      }
    }
  });

  return options;
}, []);

const selectedVariant = ({ name, value }) => {
  if (value) {
    productStore.options = {
      ...productStore.options,
      [name]: value.toLowerCase()
    };
    productStore.variant = getVariant();
  }
};

const getVariant = () => {
  const variantIdObject = productStore.product.variants.reduce((acc, variant) => {
    const key = variant.options.map((option) => option.value.toLowerCase()).join('_');
    acc[key] = variant;
    return acc;
  }, {});
  return variantIdObject[Object.values(productStore.options).join('_')];
};
</script>

<style lang="scss" scoped>
label {
  display: block;
  margin-bottom: 8px;
  text-transform: capitalize;
  color: $brand-grey;
}
.options {
  display: flex;
  gap: 10px;
}
nav {
  margin-top: 20px;
}
.btn-ghost {
  background-color: $white !important;
}
</style>
