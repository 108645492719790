<template>
  <div class="money">
    {{ formattedValue }}
  </div>
</template>

<script setup>
import currency from 'currency.js';
const currencyMap = {
  EUR: '€',
  USD: '$',
  CHF: 'CHF'
};

const props = defineProps({
  amount: {
    type: Number,
    required: true
  },
  currency: {
    type: String,
    required: true
  },
  showSymbol: {
    type: Boolean,
    default: true
  }
});

const currencySymbol = currencyMap[props.currency];
const formattedValue = currency(props.amount, {
  symbol: props.showSymbol ? currencySymbol : ''
}).format();
</script>

<style scoped lang="scss">
.money {
  display: inline-block;
}
</style>
