<template>
  <div class="products">
    <header>
      <nav class="filters">
        <a href="#" class="active">Tous</a>
        <a href="#">Actif</a>
      </nav>
      <section class="search">
        <FormSearch title="forms.label.search" placeholder="forms.placeholder.filter_product" />
        <a class="btn">
          <IconLibrary name="sort" color="white" size="sm" />
          Trier
        </a>
      </section>
    </header>

    <article class="product-wrapper" v-for="product in products" :key="product._id">
      <FormCheckbox />
      <router-link class="product" :to="`/products/${product._id}`">
        <img class="image" :src="product?.images?.[0]" :alt="product.name" />
        <div class="name">{{ product.name }}</div>
        <div class="status" :class="statusColor(product.status)">{{ product.status }}</div>
        <div class="quantity">
          {{ getTotalQty(product.variants) }}
          <br />
          <span v-if="product.variants.length > 1">
            <small>{{ product.variants.length }} variants</small>
          </span>
        </div>
        <div class="type">{{ product.type }}</div>
        <div class="vendor">{{ product.seller_name || $t('ui.in_house') }}</div>
      </router-link>
      <div class="action" @click="deleteProduct(product._id, product.category)">
        <IconLibrary name="delete" color="red" size="xs" :hasMargin="false" />
      </div>
    </article>
  </div>
</template>

<script setup>
import FormCheckbox from '../Shared/Forms/FormCheckbox.vue';
import FormSearch from '../Shared/Forms/FormSearch.vue';
import IconLibrary from '../Shared/IconLibrary.vue';
import { kyWithAuth } from '../../libs/ky';
import { useProductStore } from '../../stores/products.js';
import { storeToRefs } from 'pinia';

const productStore = useProductStore();

const { getSortedProducts } = storeToRefs(productStore);
const products = getSortedProducts;

const getTotalQty = (variants) => {
  return variants.reduce((total, variant) => total + variant.quantity, 0);
};

const statusColor = (status) => (status === 'active' ? 'green' : 'yellow');

async function deleteProduct(productId, productCategory) {
  try {
    await productStore.deleteProduct(productId);
    const { products: productsWithCategory } = await kyWithAuth
      .get(`products/category/${productCategory}`)
      .json();
    if (!productsWithCategory.length)
      await kyWithAuth.delete(`categories/${productCategory}`).json();
  } catch (error) {
    console.error(error);
  }
}
</script>

<script>
export default {
  props: {
    products: {
      type: Array,
      required: true,
      default: []
    },
    type: {
      type: String,
      default: 'list'
    }
  }
};
</script>

<style lang="scss" scoped>
nav.filters {
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid $gainsboro;
  a {
    text-decoration: none;
    font-size: 90%;
    color: #444444;
    padding: 12px 8px;
    margin-bottom: -1px;
    border-bottom: 3px solid transparent;
  }
  a.active {
    border-bottom: 3px solid $brand-blue;
  }
  a:hover {
    border-bottom: 3px solid $gainsboro;
  }
}
section.search {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

article.product-wrapper {
  display: grid;
  grid-template-columns: 35px 1fr 35px;
  grid-template-rows: 1fr;
  grid-template-areas: 'checkbox product action';
  align-items: center;
  gap: 0px 20px;
  width: 100%;
  padding: 10px 15px;
  background-color: $white;
  transition: background-color $base-speed-fast ease;
  a {
    display: grid;
    grid-template-columns: 50px 1fr 70px 60px 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 20px;
    grid-template-areas: 'image name status quantity type vendor';
    color: $brand-deep-purple;
    align-items: center;
    text-decoration: none;
    width: 100%;
  }
}

article .status {
  padding: 3px 14px;
  border-radius: 100px;
  font-size: 85%;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  background-color: $brand-light-gold;
  &.green {
    background-color: $brand-light-mint;
  }
}

article.product-wrapper:nth-of-type(even) {
  border-top: 1px dashed rgba($brand-light-purple, 0.75);
  border-bottom: 1px dashed rgba($brand-light-purple, 0.75);
}
article.product-wrapper:last-child {
  border-bottom: none;
}
article.product-wrapper:hover {
  cursor: pointer;
  background-color: rgba($brand-mint, 0.05);
}
article.product-wrapper.router-link-active {
  background-color: rgba($brand-light-purple, 0.4);
  color: $white;
}

// Grid Area
.form-checkbox {
  grid-area: checkbox;
  text-align: center;
}
.product {
  grid-area: product;
}
.image {
  grid-area: image;
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: $base-radius;
  overflow: hidden;
  border: 1px solid rgba($brand-blue, 0.1);
  padding: 2px;
}

.quantity small {
  font-size: 80%;
  font-style: italic;
  color: rgba($brand-light-purple, 0.85);
}
.name {
  grid-area: name;
}
.status {
  grid-area: status;
}
.quantity {
  grid-area: quantity;
}
.type {
  grid-area: type;
}
.vendor {
  grid-area: vendor;
}
.action {
  grid-area: action;
}

@media (max-width: 767px) {
  article.product-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'product';
    @include padding(xs);
    a {
      grid-template-columns: 50px 0.7fr 0.3fr;
      grid-template-rows: 1fr 1fr;
      gap: 0px 20px;
      font-size: 0.85rem;
      grid-template-areas:
        'image name quantity'
        'image status quantity';
    }
  }
  .quantity {
    justify-self: flex-end;
    text-align: right;
    small {
      font-size: 90%;
    }
  }
  .status {
    justify-self: flex-start;
  }

  .action,
  .type,
  .vendor,
  .form-checkbox {
    display: none;
  }
}
</style>
