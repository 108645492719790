<template>
  <div class="form-search">
    <IconLibrary name="search" size="sm" color="blue" />
    <div class="input-wrapper">
      <label v-if="hasLabel" :for="title">{{ $t(title) }}</label>
      <input
        :id="title"
        :name="title"
        :type="type"
        v-bind="$attrs"
        :value="modelValue"
        :placeholder="$t(placeholder, { address: 'myemail', domain: 'address.com' })"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </div>
  </div>
</template>

<script setup>
import IconLibrary from '../IconLibrary.vue';
defineProps({
  title: { type: String },
  type: { type: String, default: 'text' },
  placeholder: { type: String, default: 'Insert value here' },
  hasLabel: { type: Boolean, default: false },
  modelValue: { type: String }
});
</script>

<script>
export default {
  name: 'FormSearch',
  // inheritAttrs cannot be express in script setup (https://v3.vuejs.org/api/sfc-script-setup.html#usage-alongside-normal-script)
  inheritAttrs: false // https://v3.vuejs.org/api/options-misc.html#inheritattrs
};
</script>

<style scoped lang="scss">
.form-search {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  .input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px 0;
    width: 100%;
  }
  input {
    width: 100%;
    padding: 10px;
    border-radius: $base-radius;
    border: 1.5px solid;
    border-color: rgba($brand-light-purple, 0.5);
    color: $brand-deep-purple;
    transition: border-color 250ms ease;
    &:focus {
      outline: none;
      border-color: $brand-deep-purple;
    }
  }
  label {
    margin-bottom: 6px;
  }
}
</style>
