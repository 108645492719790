<template>
  <Loader v-if="loading" id="page" />
  <div v-else id="page">
    <main>
      <header>
        <h2>{{ catalogId ? $t('catalogs.edit.title') : $t('catalogs.new.title') }}</h2>
        <div class="actions">
          <router-link to="/catalogs" class="btn btn-ghost btn-lg">
            <IconLibrary name="back" size="sm" />
            {{ $t('ui.back') }}
          </router-link>
          <div class="btn btn-lg" @click="onSubmit">
            <IconLibrary name="download" color="white" size="sm" />
            {{ $t('ui.submit') }}
          </div>
        </div>
      </header>
      <form @submit.prevent="onSubmit" id="add-catalog">
        <main>
          <section class="boxed">
            <FormInput title="forms.label.title" v-model="form.name" />
            <FormCheckbox
              label="forms.label.auto_include_products"
              v-model="form.auto_include_products"
            />
          </section>
          <section class="boxed">
            <h6>{{ $t('catalogs.count.title') }}</h6>
            <h2>{{ form.products.length || 0 }} <small>Products</small></h2>
          </section>
        </main>
        <aside>
          <DirectorList class="boxed" v-model="form.directors" />
          <ProductList class="boxed" v-model="form.products" />
        </aside>
      </form>
      <footer>
        <div class="actions">
          <router-link to="/products" class="btn btn-ghost btn-lg">
            <IconLibrary name="back" size="sm" />
            {{ $t('ui.back') }}
          </router-link>
          <div class="btn btn-lg" @click="onSubmit">
            <IconLibrary name="download" color="white" size="sm" />
            {{ $t('ui.submit') }}
          </div>
        </div>
      </footer>
    </main>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { kyWithAuth } from '../libs/ky';
import { useProductStore } from '../stores/products.js';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import FormInput from '../components/Shared/Forms/FormInput.vue';
import FormCheckbox from '../components/Shared/Forms/FormCheckbox.vue';
import ProductList from '../components/Catalogs/ProductList.vue';
import DirectorList from '../components/Catalogs/DirectorList.vue';
import Loader from '../components/Shared/Loader.vue';

const productStore = useProductStore();
const route = useRoute();
const router = useRouter();

const catalogId = route.params.catalog_id;

const form = ref({
  name: '',
  auto_include_products: false,
  products: [],
  directors: []
});

const loading = ref(true);

onMounted(async () => {
  if (!productStore.products.length) await productStore.fetchProducts();
  if (catalogId) {
    try {
      const { catalog } = await kyWithAuth.get(`catalogs/${catalogId}`).json();
      form.value = {
        name: catalog.name,
        auto_include_products: catalog.auto_include_products,
        products: catalog.products || [],
        directors: catalog.directors || []
      };
    } catch (e) {
      console.error('error fetching catalog:', e);
    } finally {
      loading.value = false;
    }
  } else {
    loading.value = false;
  }
});

async function onSubmit() {
  try {
    if (catalogId) {
      console.log('Update Catalog');
      await kyWithAuth.patch(`catalogs/${catalogId}`, { json: form.value }).json();
    } else {
      console.log('Create Catalog!');
      await kyWithAuth.post('catalogs', { json: form.value }).json();
    }
    router.push({ name: 'Catalogs' });
  } catch (error) {
    console.error(error);
  }
}
</script>

<style scoped lang="scss">
#page main > header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .actions {
    display: flex;
    gap: 20px;
  }
}

#page main > footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
  .actions {
    display: flex;
    gap: 20px;
  }
}

h6 {
  margin-bottom: 12px;
  font-size: 95%;
}
form main {
  display: grid;
  grid-template-columns: 1fr minmax(300px, 35%);
  gap: 0 25px;
}
form aside {
  margin-top: 25px;
}

@media screen and (max-width: 1040px) {
  form main {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
  }
}
</style>
