<template>
  <div class="user-container">
    <div class="user-dropdown" tabindex="0" @blur="isOpen = false">
      <div class="user-selected" @click="isOpen = !isOpen">
        <p>{{ currentUser.identifier || currentUser.company || 'Select a user' }}</p>
        <IconLibrary name="arrow-down" size="xs" :hasMargin="false" :isBold="true" />
      </div>
      <div class="user-options" :class="{ 'is-hidden': !isOpen }">
        <article v-for="buyer in buyers" :key="buyer._id" @click="changeBuyer(buyer)">
          <img class="flag" :src="`/flags/${buyer.address.country}.svg`" />
          {{ buyer.identifier || buyer.company || buyer.full_name }}
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import IconLibrary from './IconLibrary.vue';
import { kyWithAuth } from '../../libs/ky';
import { useUserStore } from '../../stores/user';

const userStore = useUserStore();
const buyers = ref([]);
const currentUser = ref({});
const isOpen = ref(false);

const fetchBuyers = async () => {
  const { users: res } = await kyWithAuth.get('users/list/buyers').json();
  buyers.value = [userStore.user, ...res];

  if (buyers.value.length) {
    currentUser.value = buyers.value[0];
  }
};

onMounted(fetchBuyers);

const emit = defineEmits(['update:modelValue']);

const changeBuyer = (buyer) => {
  emit('update:modelValue', buyer);
  currentUser.value = buyer;
  isOpen.value = false;
};
</script>

<style scoped lang="scss">
.user-container {
  padding: 0px 0;
  width: 100%;
}
.user-dropdown {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
}

.user-dropdown .user-selected {
  background-color: $brand-light-indigo;
  border-radius: $base-radius;
  border: 1px solid darken($brand-light-indigo, 10%);
  color: $brand-deep-purple;
  display: flex;
  padding: 4px 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  p {
    margin: 4px 0;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  &.is-open {
    border: 1px solid $brand-deep-purple;
  }
  .icon-wrapper {
    margin-left: 10px;
  }
}

.user-dropdown .user-options {
  position: absolute;
  overflow: hidden;
  color: $brand-deep-purple;
  border: 1px solid darken($brand-light-indigo, 10%);
  border-radius: $base-radius;
  background-color: $brand-light-indigo;
  padding: 4px 0;
  z-index: 1;
  margin-top: -3px;
  min-width: 115%;
  box-shadow: 2px 2px 8px rgba($brand-indigo, 0.1);
}

.user-dropdown .user-options article {
  cursor: pointer;
  user-select: none;
  padding: 4px 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  transition: background-color $base-speed-fast ease;
}

.user-dropdown .user-options article:hover {
  background-color: darken($brand-light-indigo, 5%);
}
.flag {
  display: inline-block;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  width: 18px;
  height: 13.5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 1px;
}

.is-hidden {
  display: none;
}
</style>
