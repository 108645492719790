<template>
  <div class="product">
    <img class="photo" :src="product.image" :alt="product.name" />
    <p class="name" :for="product.name">
      {{ product.name }} <span class="options" v-if="product.options">- {{ product.options }}</span>
    </p>
    <p class="ref">
      <small>
        <span class="blue">{{ product.sku }}</span>
      </small>
    </p>
    <div class="price">
      <MoneyFormat :amount="product.price" currency="EUR" />
      <span>
        × <strong>{{ product.quantity }}</strong>
      </span>
    </div>
    <MoneyFormat :amount="product.price * product.quantity" currency="EUR" class="total" />
  </div>
</template>

<script setup>
import { toRefs } from 'vue';
import MoneyFormat from '../Shared/MoneyFormat.vue';

defineEmits(['onProductSelect']);
const props = defineProps({
  product: { type: Object }
});

const { product } = toRefs(props);
</script>

<style scoped lang="scss">
.product {
  display: grid;
  align-items: center;
  grid-template-columns: 50px 1fr 110px 75px;
  grid-template-areas:
    'photo name price total'
    'photo ref ref ref';
  gap: 0 25px;
  padding: 8px 0;
  p {
    margin: 0;
  }
  .name {
    grid-area: name;
  }
  .options {
    color: $brand-light-purple;
  }
  .price {
    grid-area: price;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
  }
  .ref {
    grid-area: ref;
  }
  .total {
    grid-area: total;
  }
  .photo {
    grid-area: photo;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: $base-radius;
    overflow: hidden;
    border: solid 1px rgba($brand-blue, 0.1);
    padding: 2px;
    // box-shadow: 2px 2px 8px rgba($black, 0.15);
  }
}
</style>
