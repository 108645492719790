<template>
  <div id="page">
    <main>
      <header>
        <h2>{{ $t('settings.title') }}</h2>
        <div class="actions">
          <!-- <a href="#" class="btn btn-ghost btn-lg">
            <IconLibrary name="help" size="sm" />
            {{ $t('forms.button.help') }}
          </a> -->
          <div class="btn btn-lg" @click="submitForm">
            <IconLibrary name="download" color="white" size="sm" />
            {{ $t('ui.save') }}
          </div>
        </div>
      </header>
      <aside v-if="!userStore.user?.is_kyc" class="kyc">
        <h5>{{ $t('settings.kyc.title') }}</h5>
        <p>{{ $t('settings.kyc.message') }}</p>
      </aside>
      <div class="settings">
        <ul>
          <li class="profile">
            <aside>
              <h6>{{ $t('settings.profile.title') }}</h6>
              <!-- <p>
                <small>{{ $t('settings.profile.description') }}</small>
              </p> -->
            </aside>
            <div class="boxed">
              <div class="flex">
                <div v-if="form.full_name" class="round" :style="{ backgroundColor: randomColor }">
                  {{ initials }}
                </div>
                <FormInput title="forms.label.full_name" v-model="form.full_name" />
              </div>
              <div class="flex">
                <FormInput title="forms.label.company" v-model="form.company" />
                <FormInput title="forms.label.phone" v-model="form.phone" />
              </div>
              <div class="flex">
                <FormInput title="forms.label.vat_number" v-model="form.vat" />
                <FormInput title="forms.label.company_number" v-model="form.nif" />
              </div>
            </div>
          </li>
          <li class="address">
            <aside>
              <h6>{{ $t('settings.address.title') }}</h6>
              <!-- <p>
                <small>{{ $t('settings.address.description') }}</small>
              </p> -->
            </aside>
            <AddressEdit class="boxed" v-model="form.address" />
          </li>
          <li>
            <aside>
              <h6>{{ $t('settings.security.title') }}</h6>
              <!-- <p>
                <small>{{ $t('settings.security.description') }}</small>
              </p> -->
            </aside>
            <div class="boxed flex">
              <FormInput
                title="forms.label.email"
                :modelValue="userStore.user?.email"
                :disabled="true"
              />
              <FormInput
                title="forms.label.password"
                type="password"
                modelValue="************"
                :disabled="true"
              />
            </div>
          </li>
          <li>
            <aside>
              <h6>{{ $t('settings.language.title') }}</h6>
              <!-- <p>
                <small>{{ $t('settings.language.description') }}</small>
              </p> -->
            </aside>
            <div class="boxed">
              <LanguageSelector />
            </div>
          </li>
          <li v-if="userStore.user?.role === 'director'">
            <aside>
              <h6>{{ $t('settings.online-shop.title') }}</h6>
              <!-- <p>
                <small>{{ $t('settings.online-shop.description') }}</small>
              </p> -->
            </aside>
            <div class="boxed">
              <div class="flex upload-logo">
                <img
                  v-if="userStore.user?.logo"
                  :src="`data:image;base64, ${userStore.user?.logo}`"
                />
                <img v-else :src="defaultLogo" />

                <input
                  type="file"
                  id="upload-logo"
                  style="display: none"
                  v-on:change="uploadLogo"
                />
                <label for="upload-logo">
                  <div class="btn btn-ghost btn-sm">
                    <IconLibrary name="upload" size="xs" />
                    {{ $t('ui.upload_logo') }}
                  </div>
                </label>
                <div class="btn btn-sm btn-ghost btn-delete" @click="userStore.deleteLogo()">
                  <IconLibrary name="delete" size="xs" :hasMargin="false" />
                </div>
              </div>
              <FormInput title="forms.label.baseline" v-model="form.baseline" />
            </div>
          </li>
        </ul>
      </div>
      <footer>
        <div class="btn btn-lg" @click="submitForm">
          <IconLibrary name="download" color="white" size="sm" />
          {{ $t('ui.save') }}
        </div>
      </footer>
    </main>
  </div>
</template>
<script setup>
// import { computed, onMounted, reactive, ref } from 'vue';
import { ref, computed } from 'vue';
import { kyWithAuth } from '../libs/ky';
import { useRouter } from 'vue-router';
import { useUserStore } from '../stores/user';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import LanguageSelector from '../components/Shared/LanguageSelector.vue';
import FormInput from '../components/Shared/Forms/FormInput.vue';
import AddressEdit from '../components/Shared/Address/AddressEdit.vue';
const userStore = useUserStore();
const defaultLogo = ref('/logos/logo-shop-black.svg');

const router = useRouter();

const initials = computed(() => {
  if (!userStore.user.full_name) return '';
  return userStore.user.full_name
    .split(' ')
    .filter((name) => name.trim().length > 0)
    .map((name) => name.charAt(0))
    .join('');
});

const randomColor = computed(() => {
  const colors = ['#FF5733', '#33FF57', '#5733FF', '#33A1FD', '#FD33A1'];
  return colors[Math.floor(Math.random() * colors.length)];
});

const form = ref({
  full_name: userStore.user?.full_name || '',
  company: userStore.user?.company || '',
  phone: userStore.user?.phone || '',
  address: {
    country: userStore.user?.address?.country || 'FR',
    address1: userStore.user?.address?.address1 || '',
    address2: userStore.user?.address?.address2 || '',
    zip: userStore.user?.address?.zip || '',
    city: userStore.user?.address?.city || ''
  },
  vat: userStore.user?.vat || '',
  nif: userStore.user?.nif || '',
  baseline: userStore.user?.baseline || '',
  is_kyc: true
});

const uploadLogo = async (event) => {
  const file = event.target.files[0];
  if (file) {
    const formData = new FormData();
    formData.append('logo', file);
    await userStore.uploadLogo(formData);
  }
};

async function submitForm() {
  try {
    await kyWithAuth.patch('users', { json: form.value }).json();
    if (userStore.user?.role === 'buyer') {
      router.push({ name: 'Shop' });
    } else {
      router.push({ name: 'Home' });
    }
  } catch (e) {
    console.error(e);
  } finally {
    //Loading false
  }
}
</script>

<style scoped lang="scss">
.flex {
  gap: 25px;
}

.kyc {
  border: 2px solid $brand-light-purple;
  border-radius: $base-radius;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}

.upload-logo {
  justify-content: flex-end;
  margin-bottom: 1.25rem;
  img {
    max-width: 125px;
    max-height: 75px;
    margin-right: auto;
  }
}

#page main > header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  .actions {
    display: flex;
    gap: 20px;
  }
}

#page main > footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
}

.profile {
  height: 100%;
  display: flex;
  align-items: top;
  gap: 15px;
  img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100px;
  }
  .round {
    width: 60px;
    height: 50px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $font-weight-bold;
    font-size: 125%;
    color: $white;
  }
}
.settings ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.settings ul > li {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  > div {
    min-width: 62.5%;
    padding: 15px 30px;
  }
  > aside {
    p {
      color: $brand-light-purple;
      font-style: italic;
    }
  }
}
.settings ul > li + li {
  margin-top: 35px;
}

@media (max-width: 767px) {
  .settings ul > li {
    flex-direction: column;
    gap: 10px;
  }
  #page main > header {
    flex-direction: column;
  }
}
</style>
