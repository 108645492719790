<template>
  <main>
    <section>
      <router-link to="/">
        <img class="logo" src="/logos/logo-nuteam-white.svg" alt="NuTeam Logo" />
      </router-link>
      <h1>{{ $t('login.reset.title') }}</h1>
      <form @submit.prevent="reset">
        <FormInput
          title="forms.label.email"
          placeholder="forms.placeholder.email"
          v-model="email"
          :disabled="isLoading"
        />
        <FormButton title="forms.button.reset" :loading="isLoading" />

        <router-link class="link" to="/login">{{ $t('reset.login.label') }}</router-link>
        <div v-if="successMessage" class="feedback success">
          <h6>{{ $t('ui.success') }}</h6>
          <p>{{ successMessage }}</p>
        </div>
        <div v-if="errors" class="feedback errors">
          <h6>Oops, we found some errors!</h6>
          <ul>
            <li v-for="error in loginErrors" :key="error.code">{{ error.message }}</li>
          </ul>
        </div>
      </form>
    </section>
    <section class="img-container">
      <span class="badge version">
        {{ $t('ui.version') }}
        <strong>{{ version }}</strong>
      </span>
    </section>
  </main>
</template>

<script setup>
import { ref } from 'vue';
import { version } from '../../package';
import { useUserStore } from '../stores/user';
import FormInput from '../components/Shared/Forms/FormInput.vue';
import FormButton from '../components/Shared/Forms/FormButton.vue';
import { useI18n } from 'vue-i18n';

const userStore = useUserStore();
const { t } = useI18n();
const email = ref('');
const isLoading = ref(false);
const successMessage = ref('');
const errors = ref('');

const reset = async () => {
  if (isLoading.value) return;
  isLoading.value = true;
  errors.value = '';
  successMessage.value = '';

  try {
    await userStore.reset({ email: email.value });
    setTimeout(() => (successMessage.value = t('login.reset.success')), 300);
    setTimeout(() => (email.value = ''), 300);
  } catch (err) {
    const { errors } = await err.response.json();
    errors.value = errors;
    console.error(errors.value);
  } finally {
    setTimeout(() => (isLoading.value = false), 300);
  }
};
</script>

<style scoped lang="scss">
#app > main {
  padding-bottom: 0px;
}
main {
  display: flex;
  min-height: 100vh;
}
:deep(label) {
  color: rgba($white, 0.7) !important;
}
h1 {
  margin: 15px 0 65px;
  color: lightgrey;
  font-size: 25px;
}
form {
  display: flex;
  flex-direction: column;
}

.feedback {
  background-color: $white;
  border-radius: $base-radius;
  padding: 25px;
  margin-top: 25px;
  h6 {
    margin-bottom: 12px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  p {
    margin: 0;
  }
}
.feedback.errors {
  border: 1px solid darken($brand-corail, 20%);
  ul li {
    color: darken($brand-corail, 35%);
  }
}

.feedback.success {
  border: 1px solid darken($brand-mint, 20%);
  p {
    color: darken($brand-mint, 40%);
  }
}

.link {
  color: $white;
  font-size: 90%;
  margin-top: 10px;
  text-align: right;
  transition: color 200ms ease-in-out;
  &:hover {
    color: $brand-corail;
  }
}
.logo {
  width: 150px;
}
.badge {
  border-radius: 20px;
  background-color: rgba($white, 0.6);
  color: $brand-dark-purple;
  border: 1px solid $brand-dark-purple;
  padding: 3px 18px;
  font-size: 9px;
  position: absolute;
  bottom: 18px;
  right: 18px;
  text-transform: uppercase;
}
main > section:not(.img-container) {
  padding: 50px 80px;
  width: 850px;
  background-color: $brand-dark-grey-purple;
}
main > .img-container {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-image: url('../../public/svg/illustration_login.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  background-color: #f7f8fd;
  box-shadow: -5px 0px 10px 0px rgba($black, 0.1);
  position: relative;
}

@media (min-width: 1440px) {
  main > .img-container {
    background-size: 70%;
  }
}

@media (max-width: 1023px) {
  main {
    display: grid;
    grid-template-rows: auto 1fr;
  }
  main > section:not(.img-container) {
    width: 100%;
  }
  main > .img-container {
    background-size: 55%;
  }
}

@media (max-width: 767px) {
  main > .img-container {
    background-size: 75%;
  }
}
</style>
