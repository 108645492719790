<template>
  <div class="empty-state">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.empty-state {
  width: 100%;
  padding: 50px 25%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: $brand-grey;
  }
  :deep(.icon-wrapper) {
    opacity: 0.3;
    margin-bottom: 30px;
  }
  :deep(svg) {
    stroke: $brand-indigo;
    stroke-width: 1;
  }
}
</style>
