<template>
  <div class="collections">
    <article v-for="collection in collections" class="collection-wrapper">
      <router-link class="collection" :to="`/collections/${collection._id}`">
        <img class="cover" :src="collection.cover" />
        <div class="name">
          <strong>{{ collection.title }}</strong>
        </div>
        <div class="status">
          <strong>{{ collection.type || '' }}</strong>
        </div>
        <div class="products">
          <strong>{{ collection.products?.length || 0 }} products</strong>
        </div>
      </router-link>
      <div class="btn btn-sm btn-ghost btn-delete" @click="deleteCollection(collection._id)">
        <IconLibrary name="delete" color="red" size="xs" />
        Delete
      </div>
    </article>
  </div>
</template>

<script setup>
import IconLibrary from '../Shared/IconLibrary.vue';
import { useCollectionStore } from '../../stores/collections.js';

const collectionStore = useCollectionStore();

const { collections } = defineProps({
  collections: {
    type: Array,
    required: true
  }
});

const deleteCollection = async (collectionId) => {
  try {
    await collectionStore.deleteCollections(collectionId);
  } catch (e) {
    console.error('Error deleting collection:', e);
  }
};
</script>

<style lang="scss" scoped>
nav.filters {
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid $gainsboro;
  a {
    text-decoration: none;
    font-size: 90%;
    color: #444444;
    padding: 12px 8px;
    margin-bottom: -1px;
    border-bottom: 3px solid transparent;
  }
  a.active {
    border-bottom: 3px solid $brand-blue;
  }
  a:hover {
    border-bottom: 3px solid $gainsboro;
  }
}
section.search {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}

.collection-wrapper {
  display: grid;
  grid-template-columns: 1fr 100px;
  gap: 0px 20px;
  width: 100%;
  padding: 10px 15px;
  background-color: $white;
  align-items: center;
  transition: background-color $base-speed-fast ease;
}
.collection {
  display: grid;
  grid-template-columns: 80px 1fr 180px auto;
  grid-template-areas: 'cover title type products';
  align-items: center;
  gap: 0px 15px;
  width: 100%;
  color: $brand-deep-purple;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 85%;
  font-weight: $font-weight-light;
  transition: background-color $base-speed-fast ease;
}

article.collection-wrapper:nth-of-type(even) {
  border-top: 1px dashed rgba($brand-light-purple, 0.75);
  border-bottom: 1px dashed rgba($brand-light-purple, 0.75);
}
article.collection-wrapper:last-child {
  border-bottom: none;
}
article.collection-wrapper:hover {
  cursor: pointer;
  background-color: rgba($brand-mint, 0.05);
}
article.collection-wrapper.router-link-active {
  background-color: rgba($brand-light-purple, 0.4);
  color: $white;
}

article.collection:nth-of-type(even) {
  border-top: 1px dashed rgba($brand-light-purple, 0.75);
  border-bottom: 1px dashed rgba($brand-light-purple, 0.75);
}
article.collection:last-child {
  border-bottom: none;
}
article.collection:hover {
  cursor: default;
  background-color: rgba($brand-mint, 0.05);
}
.green {
  color: green;
}
.added-to-shop {
  display: flex;
  border: 1px solid rgba(green, 0.25);
  padding: 4px 12px;
  border-radius: $base-radius;
}
// Grid Area

.type {
  grid-area: type;
}
.products {
  grid-area: products;
  justify-self: flex-end;
}
.title {
  grid-area: title;
}
.cover {
  grid-area: cover;
  width: 100%;
  height: 50px;
  object-fit: cover;
  border-radius: $base-radius;
  border: 1px solid rgba($brand-light-purple, 0.4);
}
</style>
