<template>
  <div :class="['flag', { 'flag-margin': hasMargin }]">
    <img :src="imageUrl" />
  </div>
</template>

<script>
export default {
  name: 'FlagLibrary',
  computed: {
    imageUrl() {
      return `/flags/${this.code.toUpperCase()}.svg`;
    }
  },
  props: {
    code: {
      type: String,
      default: 'FR'
    },
    hasMargin: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped lang="scss">
.flag {
  display: inline-block;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  width: 18px;
  height: 13.5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 1px;
  &-margin {
    margin-right: 8px;
  }
}
img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
