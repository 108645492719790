import { createRouter, createWebHistory } from 'vue-router';
// import HomeDirector from '../views/HomeDirector.vue';
// import HomeBuyer from '../views/HomeBuyer.vue';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import CreateAccount from '../views/CreateAccount.vue';
import ResetPassword from '../views/ResetPassword.vue';
import NewPassword from '../views/NewPassword.vue';
import Orders from '../views/Orders.vue';
import Order from '../views/Order.vue';
import Products from '../views/Products.vue';
import Catalog from '../views/Catalog.vue';
import Catalogs from '../views/Catalogs.vue';
import UpsertCatalog from '../views/UpsertCatalog.vue';
import UpsertCollection from '../views/UpsertCollection.vue';
import UpsertProduct from '../views/UpsertProduct.vue';
import Collections from '../views/Collections.vue';
import Cart from '../views/Cart.vue';
import Shop from '../views/Shop.vue';
import ShopHome from '../views/ShopHome';
import ShopCategory from '../views/ShopCategory.vue';
import ShopCollection from '../views/ShopCollection.vue';
import ShopProduct from '../views/ShopProduct.vue';
import ShopOrderList from '../components/Shop/ShopOrderList.vue';
import Sellers from '../views/Sellers.vue';
import InvitationValidation from '../views/InvitationValidation.vue';
import EditSellers from '../views/EditSellers.vue';
import Buyers from '../views/Buyers.vue';
import EditBuyers from '../views/EditBuyers.vue';
import Directors from '../views/Directors.vue';
import ShippingAddresses from '../views/ShippingAddresses.vue';
import Settings from '../views/Settings.vue';
import InvitedTeamUser from '../views/InvitedTeamUser.vue';
import Teams from '../views/Teams.vue';
import OrderConfirmation from '../views/OrderConfirmation.vue';
import { useUserStore } from '../stores/user.js';

const routes = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Home' }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/create-account',
    name: 'CreateAccount',
    component: CreateAccount
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: { requiresAuth: true }
  },
  {
    path: '/orders/:order_id',
    name: 'Order',
    component: Order,
    meta: { requiresAuth: true }
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: { requiresAuth: true }
  },
  {
    path: '/products/new',
    name: 'AddProduct',
    component: UpsertProduct,
    props: { isEditMode: false },
    meta: { requiresAuth: true }
  },
  {
    path: '/products/:product_id',
    name: 'Product',
    component: UpsertProduct,
    props: { isEditMode: true },
    meta: { requiresAuth: true }
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: Catalog,
    meta: { requiresAuth: true }
  },
  {
    path: '/catalogs',
    name: 'Catalogs',
    component: Catalogs,
    meta: { requiresAuth: true }
  },
  {
    path: '/catalogs/new',
    name: 'AddCatalog',
    component: UpsertCatalog,
    meta: { requiresAuth: true }
  },
  {
    path: '/catalogs/:catalog_id',
    name: 'EditCatalog',
    component: UpsertCatalog,
    meta: { requiresAuth: true }
  },
  {
    path: '/collections',
    name: 'Collections',
    component: Collections,
    meta: { requiresAuth: true }
  },
  {
    path: '/collections/new',
    name: 'AddCollection',
    component: UpsertCollection,
    meta: { requiresAuth: true }
  },
  {
    path: '/collections/:collection_id',
    name: 'EditCollection',
    component: UpsertCollection,
    meta: { requiresAuth: true }
  },
  {
    path: '/sellers',
    name: 'Sellers',
    component: Sellers,
    meta: { requiresAuth: true }
  },
  {
    path: '/sellers/:seller_id',
    name: 'EditSellers',
    component: EditSellers,
    meta: { requiresAuth: true }
  },
  {
    path: '/clients',
    name: 'Directors',
    component: Directors,
    meta: { requiresAuth: true }
  },
  {
    path: '/pos',
    name: 'Buyers',
    component: Buyers,
    meta: { requiresAuth: true }
  },
  {
    path: '/pos/:pos_id',
    name: 'EditBuyers',
    component: EditBuyers,
    meta: { requiresAuth: true }
  },
  {
    path: '/shop',
    component: Shop,
    meta: { requiresAuth: true },
    children: [
      { path: '', name: 'Shop', component: ShopHome },
      {
        path: 'collections/:slug',
        component: ShopCollection
      },
      {
        path: 'collections/:slug/product_id',
        component: ShopProduct
      },
      {
        path: 'categories/:slug',
        component: ShopCategory
      },
      {
        path: 'categories/:slug/:product_id',
        component: ShopProduct
      },
      {
        path: 'products/:product_id',
        component: ShopProduct
      },
      {
        path: 'cart',
        component: Cart
      },
      {
        path: 'orders',
        component: ShopOrderList
      },
      {
        path: 'order/:order_id/confirmation',
        component: OrderConfirmation
      },
      {
        path: 'orders/:order_id',
        component: Order
      }
    ]
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresAuth: true }
  },
  {
    path: '/addresses',
    name: 'Addresses',
    component: ShippingAddresses,
    meta: { requiresAuth: true }
  },
  {
    path: '/reset',
    name: 'Reset',
    component: ResetPassword
  },
  {
    path: '/reset-password',
    name: 'NewPassword',
    component: NewPassword
  },
  {
    path: '/accept-invitation',
    name: 'InvitationValidation',
    component: InvitationValidation
  },
  {
    path: '/verify-email',
    name: 'Verify',
    beforeEnter: async (to, from, next) => {
      try {
        const userStore = useUserStore();
        const verifyJWT = to.query.jwt;
        if (verifyJWT) {
          window.localStorage.setItem('jwt', verifyJWT);
          const jwt = await userStore.verifyMail();
          window.localStorage.setItem('jwt', jwt);
          return next({ name: 'Home' });
        }
      } catch (e) {
        window.localStorage.removeItem('jwt');
        return next({ name: 'Login' });
      }
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: async (to, from, next) => {
      const userStore = useUserStore();
      await userStore.logout();
      window.localStorage.removeItem('jwt');
      return next({ name: 'Login' });
    }
  },
  {
    path: '/invited-team-user',
    name: 'InvitedTeamUser',
    component: InvitedTeamUser,
    meta: { requiresAuth: true }
  },
  {
    path: '/teams',
    name: 'Teams',
    component: Teams,
    meta: { requiresAuth: true }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes
});

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore();
  const jwt = window.localStorage.getItem('jwt');
  if (to.meta.requiresAuth) {
    if (!jwt) {
      return next({ name: 'Login' });
    }
    await userStore.auth();
    if (!userStore.user) {
      return next({ name: 'Login' });
    }
    if (!userStore.user.is_kyc && to.name !== 'Settings' && to.name !== 'Logout') {
      return next({ name: 'Settings' });
    }

    return next();
  } else {
    if (to.name === 'Login' && jwt) {
      await userStore.auth();
      const targetRoute = getRedirectionRoute(userStore.user);
      return next(targetRoute);
    }
    return next();
  }
});

function getRedirectionRoute(user) {
  if (!user.is_kyc) {
    return { name: 'Settings' };
  }
  switch (user.role) {
    case 'buyer':
      return { name: 'Shop' };
    default:
      return { name: 'Home' };
  }
}

export default router;
