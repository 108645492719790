<template>
  <component :is="layout">
    <!-- <AppNotification v-if="show" :message="message" :type="type" /> -->
    <router-view />
  </component>
</template>

<script setup>
import { computed } from 'vue';
// import { computed, ref, onMounted } from 'vue';
import { useUserStore } from './stores/user';
import Authenticated from './layouts/Authenticated.vue';
import Anonymous from './layouts/Anonymous.vue';

const userStore = useUserStore();
const layout = computed(() => (userStore.isLogged ? Authenticated : Anonymous));

// const show = ref(false);
// const message = ref('');
// const type = ref('info');

// const handleNotify = (event) => {
//   message.value = event.detail.message;
//   type.value = event.detail.type;
//   show.value = true;
//   setTimeout(() => (show.value = false), 5000);
// };

// onMounted(() => {
//   window.addEventListener('notify', handleNotify);
// });
</script>

<style lang="scss">
@import './scss/layout.scss';
</style>
