<template>
  <div id="page">
    <main>
      <header>
        <h2>{{ $t('catalogs.title') }}</h2>
        <div class="actions">
          <div class="btn btn-lg" @click="openModal">
            <IconLibrary name="add-product" color="white" size="sm" />
            {{ $t('forms.button.create_catalog') }}
          </div>
        </div>
      </header>
      <div v-if="catalogStore.catalogs.length < 1" class="boxed catalogs">
        <EmptyState>
          <IconLibrary name="product" size="xxl" />
          <h4>No Catalogs</h4>
          <p>
            Uh-oh, looks like you haven't any catalogs yet!<br />
            To get started, click on the following link
            <router-link to="/catalogs/new">to create your first catalog</router-link>.
          </p>
          <p>
            <small> Once created, it will appear here and you can use it right away. </small>
          </p>
        </EmptyState>
      </div>
      <CatalogList v-else class="boxed" :catalogs="catalogStore.catalogs" />
    </main>
    <Modal ref="modal" size="small">
      <template #title>{{ $t('modal.catalogs.title') }}</template>
      <form id="submit-catalog" @submit.prevent="submitForm">
        <FormRadio name="catalog-type" :options="options" v-model="catalogType" />
        <input type="submit" hidden />
      </form>
      <template #footer>
        <div class="btn btn-ghost" @click="handleCancel">Cancel</div>
        <button type="submit" form="submit-catalog" class="btn">
          {{ $t('forms.button.next') }}
        </button>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import CatalogList from '../components/Catalogs/CatalogList.vue';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import EmptyState from '../components/Shared/EmptyState.vue';
import Modal from '../components/Shared/Modal.vue';
import FormRadio from '../components/Shared/Forms/FormRadio.vue';
import { useCatalogStore } from '../stores/catalogs.js';

const router = useRouter();
const catalogStore = useCatalogStore();

onMounted(async () => {
  try {
    await catalogStore.fetchCatalogs();
  } catch (e) {
    console.error(e);
  }
});

const options = [
  {
    label: 'All products',
    description: 'All of your active products will be included.',
    value: 'all'
  },
  {
    label: 'Specific products',
    description: 'Choose which products to include.',
    value: 'manual'
  }
];

const modal = ref();
const catalogType = ref(options[0].value);

const openModal = () => {
  modal.value.open();
};

const closeModal = () => {
  modal.value.close();
  resetForm();
};

const handleCancel = () => closeModal();

const resetForm = () => {
  catalogType.value = 'all';
};

const submitForm = async () => {
  try {
    router.push({ name: 'AddCatalog', query: { type: catalogType.value } });
  } catch (e) {
    console.error(e);
  }
};
</script>

<style scoped lang="scss">
#page main > header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .actions {
    display: flex;
    gap: 20px;
  }
}
</style>
