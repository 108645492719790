export { useOrderStore };
import { useCartStore } from './cart.js';
import { defineStore } from 'pinia';
import { kyWithAuth } from '../libs/ky';
import { getTotal, queryStringify } from '../libs/utils';

const useOrderStore = defineStore('orders', {
  state: () => {
    return {
      orderCount: 0,
      orders: [],
      order: {},
      currentFilter: 'all',
      searchQuery: '',
      currentPage: 1,
      pageSize: 50
    };
  },
  getters: {
    getSortedOrders: (state) => {
      return state.orders.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));
    },
    getGroupedProducts: (state) => {
      return state.order?.products?.reduce((results, product) => {
        results[product.seller_id] = results[product.seller_id] || [];
        results[product.seller_id].push(product);
        return results;
      }, {});
    },
    getTotal: (state) => {
      return getTotal(state.order.products);
    }
  },
  actions: {
    async fetchOrders() {
      const projection = [
        '_id',
        'identifier',
        'created_at',
        'is_active',
        'is_paid',
        'is_fulfilled',
        'is_validated',
        'to_address',
        'products'
      ];
      const searchParams = {
        fields: projection,
        page: this.currentPage,
        limit: this.pageSize,
        filter: this.currentFilter !== 'all' ? this.currentFilter : undefined,
        search: this.searchQuery || undefined
      };
      const queryString = queryStringify(searchParams);
      const { orders = [] } = await kyWithAuth.get(`orders?${queryString}`).json();
      this.orders = orders;
      return orders;
    },
    async fetchOrder(orderId) {
      const { order } = await kyWithAuth.get(`orders/${orderId}`).json();
      this.order = order;
      return order;
    },
    async fetchPackingList(orderId) {
      const res = await kyWithAuth.get(`orders/packing-slip/${orderId}`);
      return res;
    },
    async fetchOrderCount() {
      //TODO: Use Aggregation to have more performant way to count/paginate
      const { count } = await kyWithAuth.get('orders/count').json();
      this.orderCount = count;
      return count;
    },
    setOrder(order) {
      this.order = order;
    },
    addOrder(order) {
      // Ensure the orders array does not exceed 50 items
      if (this.orders.length >= 50) {
        this.orders.pop();
      }
      // Add the new order to the beginning of the array
      this.orders.unshift(order);
      // Increment the order count
      this.orderCount++;
    },
    async createOrder(orderData) {
      try {
        const cart = useCartStore();
        const products = orderData.products;
        const { order } = await kyWithAuth.post('orders', { json: orderData }).json();

        for (let product of products) {
          await await kyWithAuth
            .patch(`products/${product.product_id}/variants/${product.variant_id}`, {
              json: { quantity: product.quantity }
            })
            .json();
        }
        cart.cleanup();
        return order._id;
      } catch (e) {
        console.error(e);
      }
    },

    async updateOrderProducts({ orderId, sellerId, updatedProducts, copy }) {
      try {
        const json = { products: updatedProducts };
        const res = await kyWithAuth.patch(`orders/${orderId}`, { json }).json();
        this.order = res.order;
        if (!copy || res.order.order_id) return;
        if (sellerId !== res.order.director_id) {
          const sellerProducts = updatedProducts.filter(
            (product) => product.seller_id && product.seller_id === sellerId
          );
          if (!sellerProducts.length) return;
          await kyWithAuth.post('orders', {
            json: {
              ...this.order,
              seller_id: sellerId,
              order_id: this.order._id,
              is_active: true,
              products: sellerProducts
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    setFilter(filter) {
      this.currentFilter = filter;
      this.currentPage = 1;
      this.fetchOrders();
    },
    setSearchQuery(query) {
      this.searchQuery = query;
      this.currentPage = 1;
      this.fetchOrders();
    },
    setPage(page) {
      this.currentPage = page;
      this.fetchOrders();
    }
  }
});
