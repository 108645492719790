<template>
  <div id="page">
    <main>
      <header>
        <h2>{{ $t('catalogue.title') }}</h2>
        <!-- <div class="actions">
          <router-link to="/products/new" class="btn btn-lg">
            <IconLibrary name="add-product" color="white" size="sm" />
            Créer un produit
          </router-link>
          <a href="" class="btn btn-lg disabled">
            <IconLibrary name="upload" color="white" size="sm" />
            Importer
          </a>
        </div> -->
      </header>
      <div v-if="productStore.catalog.length < 1" class="boxed products">
        <EmptyState>
          <IconLibrary name="product" size="xxl" />
          <h4>No Products</h4>
          <p>
            Uh-oh, looks like you haven't any products yet!<br />
            To get started, click on the following link
            <router-link to="/products/new">to create your first product</router-link>.
          </p>
          <p>
            <small>
              Once created, it will appear here and you can use your products right away.
            </small>
          </p>
        </EmptyState>
      </div>
      <CatalogList v-else class="boxed" />
      <Pagination
        :currentPage="productStore.currentPage"
        :hasNextPage="productStore.hasNextPage"
        :pageSize="productStore.pageSize"
        @update:page="changePage"
      />
    </main>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import CatalogList from '../components/Products/CatalogProductList.vue';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import EmptyState from '../components/Shared/EmptyState.vue';
import { useProductStore } from '../stores/products.js';
import Pagination from '../components/Shared/Pagination.vue';

const productStore = useProductStore();

function changePage(page) {
  productStore.currentPage = page;
  productStore.fetchProducts({ page, pageSize: productStore.pageSize, catalog: true });
}

onMounted(async () => {
  try {
    await productStore.fetchProducts({ catalog: true });
  } catch (e) {
    console.error(e);
  }
});
</script>

<style scoped lang="scss">
#page main > header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .actions {
    display: flex;
    gap: 20px;
  }
}
</style>
