import { kyWithAuth } from '../libs/ky';
import { defineStore } from 'pinia';

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    notifications: []
  }),
  actions: {
    async fetchNotifications() {
      const { notifications } = await kyWithAuth.get('notifications').json();
      this.notifications = notifications;
    },
    addNotification(notification) {
      this.notifications.push(notification);
    },
    async markAsRead(notificationId) {
      await kyWithAuth.patch(`notifications/${notificationId}/markAsRead`);
      const notification = this.notifications.find((n) => n._id === notificationId);
      if (notification) {
        notification.read = true;
      }
    }
  }
});
