<template>
  <div id="page">
    <main>
      <img class="illustration" src="/svg/illustration_home.svg" />
      <h2>
        Bonjour<br /><strong class="blue">{{ userStore.user?.full_name }}</strong
        >,
      </h2>
      <p>Voici vos notifications du jour</p>
      <nav class="notifications boxed">
        <router-link to="/orders">
          <span>
            <IconLibrary name="order" color="blue" size="sm" />
            <div>
              <strong>{{ orderStore.orderCount }}</strong> commandes en attente de validation
            </div>
          </span>
          <IconLibrary name="arrow" color="blue" size="sm" class="arrow" />
        </router-link>
        <!-- <router-link to="/messages">
          <span>
            <IconLibrary name="message" color="blue" size="sm" />
            <div><strong>0</strong> messages non lu</div>
          </span>
          <IconLibrary name="arrow" color="blue" size="sm" class="arrow" />
        </router-link> -->
      </nav>
      <section class="notification-center boxed" v-if="userStore?.user?.role === 'director'">
        <header>
          <IconLibrary name="product" color="blue" size="sm" />
          <div>
            <strong>{{ notifications.length ? notifications.length : 'Aucune' }}</strong>
            notification(s) de modification de produits
          </div>
        </header>
        <div v-if="notifications.length">
          <div
            v-for="notification in notifications"
            :key="notification._id"
            class="notification"
            :class="{ 'is-read': notification.read }"
          >
            <div class="message">
              <img
                :src="notification.product_image"
                class="round"
                v-if="notification.product_image"
              />
              <div>
                Votre fournisseur <strong>{{ notification.seller_name }}</strong> a modifié
                <strong>le prix</strong> du produit
                <strong>{{ notification.product_name }}</strong>
                <br />Ce produit a été retiré de votre boutique afin que vous puissiez vérifier et
                valider les modifications.
              </div>
            </div>
            <button
              class="btn btn-sm"
              @click="markAsRead(notification._id, notification.product_id)"
              v-if="!notification.read"
            >
              Voir produit
            </button>
          </div>
        </div>
      </section>
      <section class="product-ranking boxed">
        <header class="flex">
          <p><strong>Produits les plus vendus</strong></p>
          <span style="opacity: 0.5"
            ><small>{{ previousMonthLabel }}</small></span
          >
        </header>
        <div class="ranking-header">
          <span></span>
          <span>Product Name - Variant</span>
          <span>Quantity</span>
          <span></span>
        </div>
        <router-link
          class="product"
          v-for="product in rankedProducts"
          :to="`/products/${product.id}`"
          :key="product.id"
        >
          <img class="round" :src="product.image" :alt="product.name" />
          <p>
            {{ product.name }} <strong>{{ product.options }}</strong>
          </p>
          <p>{{ product.quantity }}</p>
          <IconLibrary name="arrow" color="blue" size="sm" />
        </router-link>
      </section>
    </main>
    <aside>
      <section>
        <header class="flex">
          <span><small>TOTAL DES ACHATS</small></span>
          <span style="opacity: 0.6"><small>Ce mois-ci</small></span>
        </header>
        <span style="margin-top: 4px; display: block"
          ><strong>{{ monthlyTotals[currentMonthIndex] }}€</strong></span
        >
        <apexchart type="bar" :options="options" :series="series" v-if="series"></apexchart>
      </section>
    </aside>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import VueApexCharts from 'vue3-apexcharts';
import { useUserStore } from '../stores/user.js';
import { useOrderStore } from '../stores/orders.js';
import { useNotificationStore } from '../stores/notifications.js';
import { kyWithAuth } from '../libs/ky';
import { format, subMonths } from 'date-fns';
import IconLibrary from '../components/Shared/IconLibrary.vue';
const rankedProducts = ref([]);
const monthlyTotals = ref(new Array(12).fill(0));
const currentMonthIndex = new Date().getMonth();

const userStore = useUserStore();
const orderStore = useOrderStore();
const notificationStore = useNotificationStore();
const notifications = computed(() => notificationStore.notifications);

const route = useRoute();
const router = useRouter();

const markAsRead = (notificationId, productId) => {
  notificationStore.markAsRead(notificationId);
  router.push({ name: 'Product', params: { product_id: productId } });
};

const previousMonthLabel = computed(() => {
  const currentDate = new Date();
  const previousMonthDate = subMonths(currentDate, 1);

  // Format the date in English
  return format(previousMonthDate, 'MMMM yyyy');
});

onMounted(async () => {
  try {
    if (!notificationStore.notifications.length && userStore.user.role === 'director') {
      await notificationStore.fetchNotifications();
    }
    if (userStore.isLogged) {
      const { bestSellers } = (await kyWithAuth.get('statistics/best-sellers').json()) || [];
      const { totalOrders } = (await kyWithAuth.get('statistics/total-orders').json()) || [];

      rankedProducts.value = bestSellers.map((prod) => {
        return {
          name: prod._id.name,
          options: prod._id.options,
          image: prod._id.image,
          id: prod._id.product_id,
          quantity: prod.totalQuantity
        };
      });

      totalOrders.forEach((order) => {
        monthlyTotals.value[order._id - 1] = order.totalAmount;
      });
    }
  } catch (e) {
    console.error(e);
  }
});

const apexchart = VueApexCharts;
const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const options = {
  fill: {
    colors: ['#D6DAFF', '#E91E63']
  },
  states: {
    hover: {
      filter: {
        type: 'darken',
        value: 0.7
      }
    }
  },
  xaxis: {
    categories: labels,
    labels: {
      formatter(value) {
        const labelsAmount = labels.length;
        return labels[0] === value || labels[labelsAmount - 1] === value ? value : '';
      }
    }
  },
  chart: {
    width: '80%',
    toolbar: {
      show: false
    }
  },
  yaxis: {
    show: false
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '60%',
      borderRadius: 3,
      borderRadiusApplication: 'end'
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },
  tooltip: {
    enabled: true
  }
};

const series = [
  {
    name: 'Ventes',
    data: monthlyTotals
  }
];

onMounted(() => {
  isUserComeFromVerifyMail();
});

const isUserComeFromVerifyMail = () => {
  if (route.redirectedFrom?.path?.includes('/verify-email')) {
    if (userStore.isMailVerified) {
      console.log('successfully verify mail');
    } else {
      console.log('verify mail failed');
    }
  }
};
</script>

<style lang="scss" scoped>
#page {
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 340px;
}
main {
  position: relative;
}
.boxed + .boxed {
  @include margin(sm, top);
}
.tag {
  padding: 2px 8px;
  background-color: coral;
  color: white;
  border-radius: $base-radius;
  font-size: 80%;
  font-weight: $font-weight-bold;
}
.illustration {
  position: absolute;
  top: -45px;
  right: -45px;
  z-index: 1;
  opacity: 0.25;
}

.product-ranking {
  .ranking-header {
    display: grid;
    align-items: center;
    grid-template-columns: 50px 1fr 90px 30px;
    gap: 20px;
    padding: 8px 15px;
    background-color: $snow;
    margin: 25px 0 5px;
    color: $monsoon;
    font-weight: $font-weight-light;
    font-size: 90%;
  }
  .product {
    display: grid;
    align-items: center;
    grid-template-columns: 50px 1fr 90px 30px;
    gap: 20px;
    padding: 5px 15px;
    border-radius: $base-radius;
    text-decoration: none;
    background-color: transparent;
    color: inherit;
    transition: background-color $base-speed-normal ease;
    .arrow {
      position: relative;
      right: 0;
      transition: right $base-speed-normal ease;
    }
    &:hover {
      background-color: $snow;
      .arrow {
        right: -12px;
      }
    }
  }
  .product + .product {
    margin-top: 5px;
  }
}

img.round {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100px;
  overflow: hidden;
  border: solid 1px transparent;
  box-shadow: 2px 2px 8px rgba($black, 0.15);
}

.notification-center {
  padding: 2.5rem 2.5rem 2rem;
  header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .message {
    display: flex;
    align-items: center;
    gap: 1rem;
    img {
      width: 50px;
    }
  }
  .notification {
    font-size: 0.95rem;
    margin-top: 1.75rem;
  }
  .notification.is-read {
    opacity: 0.35;
  }
  .btn {
    margin-top: 0.5rem;
  }
  // display: grid;
  // grid-template-columns: 1fr 150px;
  // align-items: center;
}

nav {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  > a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include padding(xs, top);
    @include padding(xs, bottom);
    @include padding(xs, left);
    border-radius: $base-radius;
    text-decoration: none;
    background-color: transparent;
    color: inherit;
    transition: background-color $base-speed-normal ease;
    span {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    + div {
      border-top: 1px solid $gainsboro;
    }
    > .arrow {
      margin-left: auto;
      transition: transform $base-speed-normal ease;
    }
    &:hover {
      background-color: $snow;
      .arrow {
        transform: translateX(10px);
      }
    }
  }
}
@media (max-width: 767px) {
  #page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  main + aside {
    // @include margin(sm);
    @include margin(sm, left);
    @include margin(sm, right);
    border-radius: $base-radius;
  }
  .product-ranking {
    display: none;
  }
  .illustration {
    right: -100px;
  }
}
</style>
