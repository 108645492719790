<template>
  <section v-if="!productStore.loading">
    <nav class="breadcrumb">
      <button type="button" class="btn btn-ghost" @click="goBack">
        <IconLibrary name="back" size="xs" />
        {{ $t('ui.back') }}
      </button>
    </nav>
    <div>
      <ShopProductGallery :product="productStore.product" />
      <ShopProductInfo :product="productStore.product" />
    </div>
  </section>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useProductStore } from '../stores/products.js';
import { usePersistCart } from '../composables/usePersistCart';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import ShopProductGallery from '../components/Shop/ShopProductGallery.vue';
import ShopProductInfo from '../components/Shop/ShopProductInfo.vue';

const route = useRoute();
const router = useRouter();
const productStore = useProductStore();
usePersistCart();

const goBack = () => {
  router.back();
};

onBeforeMount(async () => {
  await productStore.fetchProduct(route.params.product_id);
});
onBeforeUnmount(async () => {
  productStore.clearProduct();
});
</script>
<style lang="scss" scoped>
nav {
  margin-bottom: 15px;
}
section > div {
  display: grid;
  grid-template-columns: 0.9fr 1.1fr;
  gap: 140px;
  padding: 0;
}

@media (max-width: 767px) {
  section > div {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    padding: 0;
  }
}
</style>
