<template>
  <button
    :type="type"
    :id="title"
    class="form-button btn"
    :aria-label="ariaLabel"
    :disabled="loading"
  >
    <template v-if="loading">
      <Loader />
    </template>
    <template v-else>
      {{ $t(title) }}
    </template>
  </button>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import Loader from '../Loader.vue';

const props = defineProps({
  title: { type: String, required: true },
  type: { type: String, default: 'submit' },
  loading: { type: Boolean, default: false }
});

const { t } = useI18n({ useScope: 'local' });
const ariaLabel = computed(() => t(props.title));
</script>

<style scoped lang="scss">
button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0;
  text-decoration: none;
  background: $brand-blue;
  color: #ffffff;
  font-family: sans-serif;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:hover,
button:focus {
  background: darken($brand-blue, 15%);
}

button:focus {
  outline: none;
  border-color: $brand-deep-purple;
}

button:active {
  transform: scale(0.99);
}
</style>
