<template>
  <div class="products">
    <header>
      <IconLibrary
        :name="statusIcons.name"
        :color="statusIcons.color"
        size="sm"
        :hasBorder="true"
      />
      <div>
        <h5 class="title">{{ orderStatus }} ({{ products.length }})</h5>
        <h6>
          Fournisseur
          <span class="corail">{{ seller.identifier || seller.company }}</span>
        </h6>
      </div>
    </header>
    <OrderProduct
      v-for="product in products"
      :key="product.id"
      :hasCheckbox="true"
      @onProductSelect="selectProduct(product)"
      :product="product"
      :order="order"
    />

    <ul class="total">
      <li v-if="shippingFeeIncluded !== null" class="shipping-fee">
        <div>
          <span v-if="shippingFeeIncluded">
            Frais de livraison <strong>inclus</strong> dans le prix
          </span>
          <span v-else>Frais de livraison <strong>non inclus</strong> dans le prix</span>
        </div>
      </li>
      <li>
        <div>
          <strong>{{ $t('order.subtotal') }}:</strong>
          <!-- {{ products.reduce((acc, product) => acc + product.quantity, 0) }}
          {{ $t('order.products') }} -->
        </div>
        <div>
          <MoneyFormat :amount="getTotal(products)" currency="EUR" />
        </div>
      </li>
      <li v-if="matchedTax">
        <div>
          TVA (<strong>{{ matchedTax.tax_rate }}%</strong> de
          <MoneyFormat :amount="matchedTax.subtotal" currency="EUR" />):
        </div>
        <div><MoneyFormat :amount="matchedTax.tax_amount" currency="EUR" /></div>
      </li>
      <li v-if="matchedTax">
        <div><strong>Total (TTC):</strong></div>
        <div>
          <strong><MoneyFormat :amount="matchedTax.total" currency="EUR" /></strong>
        </div>
      </li>
    </ul>
    <footer v-if="!isLoading">
      <section v-if="!isValidated && !isDenied && isDirector" class="is-pending">
        <div class="btn btn-ghost btn-cancel" @click="denyOrder(sellerId)">
          <IconLibrary name="cancel" size="sm" />
          {{ $t('order.reject') }}
        </div>
        <div class="btn" @click="allowOrder(sellerId)">
          <IconLibrary name="validate" size="sm" />
          {{ $t('order.validate') }}
        </div>
      </section>
      <div class="flex flex-end">
        <section v-if="!isPaid && isValidated && isSeller" class="is-unpaid">
          <div class="btn" @click="payOrder(sellerId)">
            <IconLibrary name="validate" size="sm" />
            {{ $t('order.pay') }}
          </div>
        </section>
        <section v-if="isSeller && !isFulfilled">
          <div class="btn" @click="fulfillOrder(sellerId)">
            <IconLibrary name="validate" size="sm" />
            {{ $t('order.fulfill') }}
          </div>
        </section>
      </div>
    </footer>
    <footer v-else>
      <Loader />
    </footer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useOrderStore } from '../../stores/orders.js';
import { useUserStore } from '../../stores/user.js';
import MoneyFormat from '../Shared/MoneyFormat.vue';
import IconLibrary from '../Shared/IconLibrary.vue';
import Loader from '../Shared/Loader.vue';
import OrderProduct from './OrderProduct.vue';
import { getTotal } from '../../libs/utils';
import { kyWithAuth } from '../../libs/ky';

const orderStore = useOrderStore();
const userStore = useUserStore();
const isLoading = ref(false);

const { products } = defineProps({
  products: {
    type: Array,
    required: true
  }
});

const sellerId = computed(() => products[0]?.seller_id);
const seller = ref({ identifier: 'In-House' });

const isDirector = computed(() => userStore.user?.role === 'director');
const isSeller = computed(() => userStore.user?.role === 'seller');
const order = computed(() => orderStore.order);

//TODO: This is to make work the tax display before SellerId fix.
//Needs a better solution through the sellerId that should be available now.
const matchedTax = computed(() =>
  order.value.taxes.find((tax) => {
    return tax.subtotal === getTotal(products);
  })
);

const hasProductStatus = (statusKey) => {
  return order.value.products.some(
    (product) => product.seller_id === sellerId.value && product[statusKey]
  );
};

const isPaid = computed(() => hasProductStatus('is_paid'));
const isValidated = computed(() => hasProductStatus('is_validated'));
const isDenied = computed(() => hasProductStatus('is_denied'));
const isFulfilled = computed(() => hasProductStatus('is_fulfilled'));

const orderStatus = computed(() => {
  if (!isSeller.value && isValidated.value) return 'Commande validée';
  if (!isSeller.value && isDenied.value) return 'Commande refusée';
  if (isSeller.value && isFulfilled.value) return 'Commande expédiée';
  if (isSeller.value && !isFulfilled.value) return "En attente d'expédition";
  return 'En attente de validation';
});

const statusIcons = computed(() => {
  if (isValidated.value || (isFulfilled.value && isSeller.value)) {
    return { name: 'check', color: 'green' };
  }
  if (isDenied.value) {
    return { name: 'denied', color: 'red' };
  }
  return { name: 'status', color: 'yellow' };
});

const shippingFeeIncluded = computed(() => {
  if (!order.value.shipping_fees) return null;
  const shippingFee = order.value.shipping_fees.find(
    (fee) => fee.seller_id?.toString() === sellerId.value?.toString()
  );
  return shippingFee ? shippingFee.shipping_fee_included : null;
});

onMounted(async () => {
  if (sellerId.value) {
    try {
      const fetchedSeller = await kyWithAuth.get(`users/${sellerId.value}`).json();
      seller.value = fetchedSeller?.user || { identifier: 'In-House' };
    } catch (e) {
      console.error(e);
    }
  }
});

const apiCallWrapper = async (callback) => {
  isLoading.value = true;
  try {
    await callback();
  } catch (e) {
    console.error('Error', e);
  } finally {
    setTimeout(() => (isLoading.value = false), 500);
  }
};

const updateOrderStatus = async ({ statusKey, sellerId, copy = false }) => {
  const updatedProducts = order.value.products.map((product) => ({
    ...product,
    [statusKey]:
      product.seller_id === sellerId || (!product.seller_id && !sellerId)
        ? true
        : product[statusKey]
  }));

  await orderStore.updateOrderProducts({
    orderId: order.value._id,
    sellerId,
    updatedProducts,
    copy
  });
};

const allowOrder = (sellerId) =>
  apiCallWrapper(() => updateOrderStatus({ statusKey: 'is_validated', sellerId, copy: true }));
const denyOrder = (sellerId) =>
  apiCallWrapper(() => updateOrderStatus({ statusKey: 'is_denied', sellerId }));
const payOrder = (sellerId) =>
  apiCallWrapper(() => updateOrderStatus({ statusKey: 'is_paid', sellerId }));
const fulfillOrder = (sellerId) =>
  apiCallWrapper(() => updateOrderStatus({ statusKey: 'is_fulfilled', sellerId }));
</script>

<style scoped lang="scss">
.products {
  margin: 0 0 25px 0;
  background-color: $white;
  border-radius: $base-radius;
  box-shadow: 0px 8px 18px rgba(56, 71, 162, 0.08);

  > * {
    padding: 15px 25px;
  }

  header {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 25px 25px 0 25px;
    margin-bottom: 15px;
    h5,
    h6 {
      margin: 0;
    }
    h6 {
      color: $monsoon;
      font-weight: $font-weight-light;
    }
  }

  footer {
    background-color: $brand-light-indigo;
    border-top: 1px solid darken($brand-light-indigo, 15%);
    padding: 15px 25px;
    margin-top: 20px;
    > section {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
    }
  }
  .total {
    border-top: 1px solid darken($brand-light-indigo, 15%);
    margin: 20px 30px;
    padding: 15px 10px;
  }
  .total li {
    display: flex;
    gap: 35px;
    padding: 6px 0px 6px 0px;
    justify-content: flex-end;

    > *:last-child {
      min-width: 80px;
      text-align: right;
    }
  }
  .corail {
    color: $brand-corail;
  }
  .flex-end {
    justify-content: flex-end;
    gap: 1rem;
  }
}
.shipping-fee {
  font-size: 90%;
}
</style>
