<template>
  <div class="directors">
    <header>
      <h6>{{ $t('catalogs.directors.title') }}</h6>
      <div class="btn btn-ghost" @click="openModal">
        <IconLibrary name="director" size="sm" />
        Add a client
      </div>
    </header>

    <article v-for="director in selectedDirectors" :key="director._id" class="director">
      <div class="company">
        <FlagLibrary :code="director.address?.country" />{{ director.company }}
      </div>
      <div class="name">{{ director.full_name }}</div>
      <div class="email">{{ director.email }}</div>
      <div class="actions">
        <div
          class="btn btn-sm btn-ghost btn-delete"
          @click="updateDirectorSelection(director._id, true)"
        >
          <IconLibrary name="delete" color="red" size="xs" />
          Remove
        </div>
      </div>
    </article>

    <Modal ref="modal">
      <template #title>{{ $t('modal.catalogs.directors.title') }}</template>
      <article
        v-for="director in allDirectors"
        :key="director._id"
        class="director"
        :class="{ included: director.included }"
      >
        <div class="company">
          <FlagLibrary :code="director.address?.country" />{{ director.company }}
        </div>
        <div class="name">{{ director.full_name }}</div>
        <div class="email">{{ director.email }}</div>
        <div class="action">
          <div
            v-if="!director.included"
            class="btn btn-sm"
            @click="updateDirectorSelection(director._id, false)"
          >
            Add client
          </div>
          <div v-else class="included-label">Included</div>
        </div>
      </article>
      <template #footer>
        <div class="btn btn-ghost" @click="handleCancel">Cancel</div>
        <div class="btn" @click="closeModal">
          {{ $t('forms.button.submit') }}
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import Modal from '../Shared/Modal.vue';
import IconLibrary from '../Shared/IconLibrary.vue';
import FlagLibrary from '../Shared/FlagLibrary.vue';
import { kyWithAuth } from '../../libs/ky';
import { ref, onMounted, computed } from 'vue';

const { modelValue } = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  }
});

const modal = ref();
const allDirectors = ref([]);
const originalDirectorsId = ref([]);

const emit = defineEmits(['update:modelValue']);

onMounted(async () => {
  try {
    const { users: directors } = await kyWithAuth.get('users/list/directors').json();
    allDirectors.value = directors.map((director) => ({
      ...director,
      included: modelValue.includes(director._id)
    }));
  } catch (e) {
    console.error(e);
  }
});

const selectedDirectors = computed(() =>
  allDirectors.value.filter((director) => director.included)
);

const updateDirectorSelection = (directorId, included) => {
  const director = allDirectors.value.find((d) => d._id === directorId);
  if (director) {
    director.included = !included;
    emit(
      'update:modelValue',
      allDirectors.value.filter((d) => d.included).map((d) => d._id)
    );
  }
};

const openModal = () => {
  originalDirectorsId.value = [...modelValue];
  modal.value.open();
};

const closeModal = () => {
  modal.value.close();
};

const handleCancel = () => {
  allDirectors.value.forEach((director) => {
    director.included = originalDirectorsId.value.includes(director._id);
  });
  originalDirectorsId.value = [];
  closeModal();
};
</script>

<style lang="scss" scoped>
.directors {
  margin-bottom: 2rem;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

article.director {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr auto;
  grid-template-rows: 1fr;
  grid-template-areas: 'company name email actions';
  align-items: center;
  gap: 0 1rem;
  color: $brand-deep-purple;
  padding: 0.75rem 1rem;
  background-color: $white;
  margin-top: 1.5rem;
  transition: background-color $base-speed-fast ease;
  &.included {
    opacity: 0.3;
  }
}

article.director:nth-of-type(even) {
  border-top: 1px dashed rgba($brand-light-purple, 0.75);
  border-bottom: 1px dashed rgba($brand-light-purple, 0.75);
}
article.director:last-child {
  border-bottom: none;
}
article.director:hover {
  cursor: default;
  background-color: rgba($brand-mint, 0.05);
}

article .status {
  padding: 3px 14px;
  border-radius: 100px;
  font-size: 85%;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
  background-color: $brand-light-gold;
  &.green {
    background-color: $brand-light-mint;
  }
}

// Grid Area
.form-checkbox {
  grid-area: checkbox;
  text-align: center;
}
.company {
  grid-area: company;
}
.name {
  grid-area: name;
  small {
    font-size: 90%;
    color: $brand-blue;
  }
}
.email {
  grid-area: email;
}
.actions {
  grid-area: actions;
}
</style>
