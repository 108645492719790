<template>
  <div id="page">
    <header>
      <h2>Mon Panier ({{ cartStore.getCount }})</h2>
      <div class="actions">
        <RouterLink to="/shop" class="btn btn-lg" :class="{ 'btn-ghost': cartStore.getCount > 0 }">
          <IconLibrary
            name="online-shop"
            :color="cartStore.getCount > 0 ? '' : 'white'"
            size="sm"
          />
          Continuer mes achats
        </RouterLink>
      </div>
    </header>
    <EmptyState v-if="cartStore.getCount < 1" class="boxed">
      <IconLibrary name="online-shop" size="xxl" />
      <h4>No Products in your Cart</h4>
      <p>
        Uh-oh, looks like you haven't added any products to your cart yet!<br />
        To get started, click on the following link
        <router-link to="/shop">to buy some products.</router-link>.
      </p>
      <p>
        <small>
          Once you've added your first products, they will appear in your cart and you finish the
          checkout.
        </small>
      </p>
    </EmptyState>
    <section v-else class="layout-flex">
      <main>
        <CartProductGroup
          v-for="GroupedProducts in groupedProducts"
          :key="GroupedProducts[0].sellerId"
          :products="GroupedProducts"
        />
        <FormTextArea
          title="forms.label.order_note"
          placeholder="forms.placeholder.order_note"
          v-model="orderNote"
          class="note"
        />
      </main>
      <aside class="boxed">
        <div class="address">
          <h4>Livraison</h4>
          <BuyerSelector v-model="cartStore.buyer" v-if="userStore.user.role === 'director'" />
          <p>
            <strong>{{ cartStore.buyer?.identifier || cartStore.buyer?.company }}</strong>
            <br />
            <span>{{ cartStore.buyer?.full_name }}</span>
          </p>
          <p>
            {{ cartStore.buyer?.address?.address1 }}<br />
            {{ cartStore.buyer?.address?.address2 }}<br />
            <FlagLibrary :code="cartStore.buyer?.address?.country" />
            {{ cartStore.buyer?.address?.zip }}
            {{ cartStore.buyer?.address?.city }}
          </p>
        </div>
        <div>
          <h4>Récapitulatif</h4>
          <p class="flex">
            Sous-total (HT):
            <span class="money">{{ formatMoney({ amount: cartStore.getSubTotal }) }}</span>
          </p>
          <p class="flex" v-for="tax in cartStore.getDetailedTax.taxesBySeller">
            {{ $t('ui.vat') }} ({{ tax.taxRate }}% {{ $t('ui.of') }}
            {{ formatMoney({ amount: tax.subTotal }) }}):
            <span class="money">{{ formatMoney({ amount: tax.taxAmount }) }}</span>
          </p>
          <hr />
          <p class="flex">
            Total (TTC):
            <span class="money">{{
              formatMoney({ amount: cartStore.getDetailedTax.grandTotal || 0 })
            }}</span>
          </p>
          <div class="btn" @click="createOrder()">Commander</div>
          <!-- <p class="info">
            <small>
              <strong>Délai d'expédition:</strong>
              Le fournisseur prévoit d’expédier votre commande dans les 8 jours ouvrables.
            </small>
          </p> -->
        </div>
      </aside>
    </section>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { formatMoney, formatPrice } from '../libs/utils.js';
import { useCartStore } from '../stores/cart.js';
import { useOrderStore } from '../stores/orders.js';
import { usePersistCart } from '../composables/usePersistCart';
import { useUserStore } from '../stores/user.js';

import EmptyState from '../components/Shared/EmptyState.vue';
import FormTextArea from '../components/Shared/Forms/FormTextArea.vue';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import FlagLibrary from '../components/Shared/FlagLibrary.vue';
import BuyerSelector from '../components/Shared/BuyerSelector.vue';
import CartProductGroup from '../components/Cart/CartProductGroup.vue';

usePersistCart();
const cartStore = useCartStore();
const orderStore = useOrderStore();
const userStore = useUserStore();
const orderNote = ref('');

const groupedProducts = computed(() => {
  const groups = {};
  cartStore.getCart.forEach((product) => {
    const sellerId = product.sellerId;
    if (!groups[sellerId]) {
      groups[sellerId] = [];
    }
    groups[sellerId].push(product);
  });
  return Object.values(groups);
});

onMounted(async () => {
  try {
    // I need the sellers to get the correct Tax rates.
    await cartStore.fetchSellers();
    await cartStore.fetchBuyer();
  } catch (e) {
    // TODO: Display some errors if the fetching of data fails.
    console.error(e);
  }
});

const router = useRouter();

const createOrder = async () => {
  try {
    const pos = cartStore.buyer;
    const toAddress = {
      pos_id: pos._id,
      identifier: pos.identifier,
      full_name: pos.full_name,
      phone: pos.phone,
      email: pos.email,
      ...pos.address
    };

    const shippingFees = cartStore.sellers.map((seller) => {
      const directorId = pos.role === 'buyer' ? pos.directors?.[0]?.user_id : pos._id;
      const matchedDirector = seller.directors.find(
        (director) => director.user_id.toString() === directorId.toString()
      );
      return {
        seller_id: seller._id,
        shipping_fee_included: matchedDirector ? matchedDirector.shipping_fee_included : false
      };
    });

    const products = cartStore.getCart?.map((product) => {
      return {
        product_id: product.productId,
        variant_id: product.variantId,
        seller_id: product.sellerId,
        quantity: product.quantity,
        price: product.price,
        name: product.name,
        image: product.image,
        sku: product.sku,
        options: product.options,
        state: pos.option1 ? 'purchased' : 'pending'
      };
    });
    const orderData = {
      is_active: true,
      is_paid: false,
      is_fulfilled: false,
      note: orderNote.value,
      tags: [],
      total_with_tax: formatPrice({ amount: cartStore.getDetailedTax.grandTotal }),
      taxes: cartStore.getDetailedTax.taxesBySeller.map((tax) => {
        return {
          seller_id: tax.sellerId,
          subtotal: formatPrice({ amount: tax.subTotal }),
          tax_amount: formatPrice({ amount: tax.taxAmount }),
          tax_rate: tax.taxRate,
          total: formatPrice({ amount: tax.total })
        };
      }),
      shipping_fees: shippingFees,
      to_address: toAddress,
      products
    };
    const orderId = await orderStore.createOrder(orderData);
    router.push(`/shop/order/${orderId}/confirmation`);
  } catch (e) {
    console.error(e);
  }
};
</script>

<style scoped lang="scss">
#page {
  padding: 0px !important;
}
#page > header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  h2 {
    margin: 0;
  }
}
:deep(input[type='number']) {
  background-color: $white;
  border: 2px solid rgba($brand-blue, 0.3);
  color: $tuatara;
  transition: border 200ms ease, color 200ms ease, transform 200ms ease-in-out;
  font-size: 16px;
}
.layout-flex {
  display: flex;
  gap: 35px;
}
.info {
  font-size: 85%;
  color: $brand-grey;
}
.product + .product {
  margin-top: 20px;
}
.address strong,
.address span {
  color: $brand-light-purple;
}

.address {
  strong,
  span {
    color: $brand-light-purple;
  }
  p:last-child {
    margin-bottom: 0;
  }
  margin-bottom: 40px;
}
.product {
  display: grid;
  grid-template-columns: 24px 65px 1fr 90px 90px;
  gap: 25px;
  align-items: center;
  .img-wrapper {
    width: 65px;
    height: 65px;
    overflow: hidden;
    box-shadow: 0px 0px 8px rgba($black, 0.1);
    border-radius: $base-radius;
    border: 2px solid $white;
    background-color: $white;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
h4 {
  margin: 25px 0;
}
aside h4 {
  margin-top: 0;
}
.tag {
  padding: 3px 12px;
  background-color: $brand-light-purple;
  color: rgba($white, 0.8);
  border-radius: $base-radius;
  font-size: 90%;
  &.active {
    color: $white;
    background-color: $brand-blue;
  }
}
.note {
  margin-top: 2rem;
}
main {
  width: 65%;
}

aside {
  width: 35%;
}
@media (max-width: 1023px) {
  .layout-flex {
    gap: 25px;
  }
  aside,
  main {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .layout-flex {
    flex-direction: column;
  }
  .product {
    grid-template-columns: 24px 50px 1fr;
    grid-template-rows: auto auto;
    gap: 0.5rem 1.5rem;
    .img-wrapper {
      width: 50px;
      height: 50px;
    }
  }
  .product + .product {
    border-top: 1px solid $brand-light-purple;
    @include padding(xl, top);
  }
  .product .form-input {
    width: 100%;
  }
  .product > :nth-child(4),
  .product > :nth-child(5) {
    grid-column: span 2;
  }

  .product > :nth-child(4) {
    grid-column-start: 1;
  }

  .product > :nth-child(5) {
    grid-column-start: 3;
  }
  #page > header {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    @include margin(xl, bottom);
    h2 {
      margin: 0;
    }
  }
}
</style>
