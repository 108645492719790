export { useCategoryStore };
import { defineStore } from 'pinia';
import { kyWithAuth } from '../libs/ky';

const useCategoryStore = defineStore('categories', {
  state: () => {
    return {
      categories: []
    };
  },
  actions: {
    async fetchCategories() {
      this.loading = true;
      try {
        const { categories = [] } = await kyWithAuth.get('categories').json();
        this.categories = categories;
        return categories;
      } catch (error) {
        console.error('Error fetching categories:', error);
      } finally {
        this.loading = false;
      }
    },
    async fetchCategory(categoryName) {
      const { category } = await kyWithAuth.get(`categories/${categoryName}`).json();
      return category;
    }
  }
});
