<template>
  <div class="form-input">
    <label v-if="label" :for="title">{{ $t(title) }}</label>
    <div class="input-wrapper input-price">
      <input
        :id="title"
        :name="title"
        :value="inputValue"
        :disabled="disabled"
        :placeholder="0.0"
        type="text"
        @input="handleInput($event.target.value)"
      />
      <div class="currency-symbol">€</div>
    </div>
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  title: { type: String, required: true },
  label: { type: Boolean, default: true },
  modelValue: { type: Number, default: null },
  disabled: { type: Boolean, default: false }
});

const emit = defineEmits(['update:modelValue']);
const inputValue = ref(
  props.modelValue !== null && props.modelValue !== undefined ? props.modelValue.toString() : ''
);
const errorMessage = ref('');

watch(
  () => props.modelValue,
  (newValue) => {
    inputValue.value = newValue !== null && newValue !== undefined ? newValue.toString() : '';
  }
);

function handleInput(value) {
  inputValue.value = value;

  // Replace comma with dot for decimal parsing
  const normalizedValue = value.replace(',', '.');
  const parsedValue = parseFloat(normalizedValue);
  if (!isNaN(parsedValue) && parsedValue >= 0) {
    emit('update:modelValue', parsedValue);
  } else {
    emit('update:modelValue', null);
  }
}
</script>

<style scoped lang="scss">
.form-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 0;
  min-width: 90px;
  width: 100%;
  > .input-wrapper {
    display: flex;
    position: relative;
    width: 100%;
  }

  input {
    width: 100%;
    padding: 10px;
    border-radius: $base-radius;
    border: 1.5px solid;
    border-color: rgba($brand-light-purple, 0.5);
    color: $brand-deep-purple;
    transition: border-color 250ms ease;
    &:focus {
      outline: none;
      border-color: $brand-deep-purple;
    }
    &:disabled {
      background-color: rgba($brand-light-purple, 0.3);
      border-color: transparent;
      color: rgba($brand-deep-purple, 0.7);
      font-style: italic;
    }
  }
  .input-price input {
    padding-right: 26px;
  }
  .currency-symbol {
    position: absolute;
    right: 10px;
    top: 10px;
    color: rgba($brand-deep-purple, 0.4);
    font-size: 95%;
  }
  > label {
    margin-bottom: 6px;
    color: $brand-grey;
    font-size: 85%;
  }
}
.error-message {
  color: darken(red, 20%);
  margin: 0;
  padding: 0.25rem 0.75rem;
  margin-top: 1rem;
  border-radius: $base-radius;
  border: 1px solid darken(red, 10%);
}
</style>
