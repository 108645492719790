export { useCatalogStore };
import { defineStore } from 'pinia';
import { kyWithAuth } from '../libs/ky';
// import { queryStringify } from '../libs/utils.js';

const useCatalogStore = defineStore('catalogs', {
  state: () => {
    return {
      catalogs: [],
      catalog: {},
      loading: false
    };
  },
  getters: {
    // getSortedProducts: (state) => {
    //   return state.products.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at));
    // }
  },
  actions: {
    // async fetchProducts() {
    //   this.loading = true;
    //   //Todo Add Params ( Search / Sort / Filter, etc... )
    //   const projection = [];
    //   const searchParams = queryStringify({ fields: projection });
    //   const { products = [] } = await kyWithAuth.get('products', { searchParams }).json();
    //   if (process.env.NODE_ENV === 'development') {
    //     console.log('products list:', products);
    //   }
    //   this.products.splice(0, this.products.length);
    //   this.products.push(...products);
    //   return (this.loading = false);
    // },
    async fetchCatalogs() {
      this.loading = true;
      try {
        const { catalogs = [] } = await kyWithAuth.get('catalogs').json();
        this.catalogs = catalogs;
        return catalogs;
      } catch (error) {
        console.error('Error fetching product:', error);
      }
      this.loading = false;
    },
    async deleteCatalog(catalogId) {
      try {
        await kyWithAuth.delete(`catalogs/${catalogId}`);
        this.catalogs = this.catalogs.filter((catalog) => catalog._id !== catalogId);
      } catch (e) {
        console.error(e);
      }
    }
  }
});
