<template>
  <div class="products">
    <header>
      <section class="search">
        <FormSearch title="forms.label.search" placeholder="forms.placeholder.filter_product" />
        <a class="btn">
          <IconLibrary name="sort" color="white" size="sm" />
          Trier
        </a>
      </section>
    </header>
    <article v-for="product in productStore.catalog" class="product">
      <FormCheckbox />
      <img class="image" :src="product?.images?.[0]" :alt="product.name" />
      <div class="name">
        <strong>{{ product.name }}</strong>
      </div>
      <div class="category small">{{ product.category }}</div>
      <div class="quantity">
        {{ getTotalQty(product.variants) }}
        <span v-if="product.variants.length > 1">
          {{ $t('ui.on') }} {{ product.variants.length }} {{ $t('ui.variants') }}
        </span>
      </div>
      <div class="price small" v-if="product.maxPrice">
        <div v-if="product.variants.length > 1">
          <MoneyFormat :amount="product.minPrice" currency="EUR" /> ~
          <MoneyFormat :amount="product.maxPrice" currency="EUR" />
        </div>
        <div v-else>
          <MoneyFormat :amount="product.minPrice" currency="EUR" />
        </div>
      </div>
      <div class="vendor">{{ product.seller_name }}</div>
      <div class="actions">
        <div
          class="btn btn-ghost btn-sm add-to-shop"
          @click="productStore.addToShop(product._id)"
          v-if="!product.included"
        >
          <IconLibrary name="add-to-shop" size="xs" />{{ $t('ui.add_to_shop') }}
        </div>
        <div
          class="btn btn-ghost btn-delete btn-sm red remove-from-shop"
          @click="productStore.removeFromShop(product._id)"
          v-else
        >
          <IconLibrary name="remove-from-shop" size="xs" />{{ $t('ui.remove_from_shop') }}
        </div>
      </div>
    </article>
  </div>
</template>

<script setup>
import { useProductStore } from '../../stores/products.js';
import FormCheckbox from '../Shared/Forms/FormCheckbox.vue';
import FormSearch from '../Shared/Forms/FormSearch.vue';
import IconLibrary from '../Shared/IconLibrary.vue';
import MoneyFormat from '../Shared/MoneyFormat.vue';

const productStore = useProductStore();

const getTotalQty = (variants) => {
  return variants.reduce((total, variant) => total + variant.quantity, 0);
};
</script>

<style lang="scss" scoped>
nav.filters {
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid $gainsboro;
  a {
    text-decoration: none;
    font-size: 90%;
    color: #444444;
    padding: 12px 8px;
    margin-bottom: -1px;
    border-bottom: 3px solid transparent;
  }
  a.active {
    border-bottom: 3px solid $brand-blue;
  }
  a:hover {
    border-bottom: 3px solid $gainsboro;
  }
}
section.search {
  display: flex;
  gap: 20px;
  margin: 20px 0;
}
article.product {
  display: grid;
  grid-template-columns: 35px 50px 1.5fr 0.75fr 0.75fr auto;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'checkbox image name quantity vendor actions'
    'checkbox image category price vendor actions';
  align-items: center;
  gap: 0px 15px;
  width: 100%;
  color: $brand-deep-purple;
  padding: 10px 15px;
  background-color: $white;
  text-decoration: none;
  font-size: 85%;
  font-weight: $font-weight-light;
  transition: background-color $base-speed-fast ease;
}

article.product:nth-of-type(even) {
  border-top: 1px dashed rgba($brand-light-purple, 0.75);
  border-bottom: 1px dashed rgba($brand-light-purple, 0.75);
}
article.product:last-child {
  border-bottom: none;
}
article.product:hover {
  cursor: default;
  background-color: rgba($brand-mint, 0.05);
}
.green {
  color: green;
}
.added-to-shop {
  display: flex;
  border: 1px solid rgba(green, 0.25);
  padding: 4px 12px;
  border-radius: $base-radius;
}

.red {
  color: $brand-corail;
}

.remove-from-shop {
  display: flex;
  border: 1px solid rgba($brand-corail, 0.25);
  padding: 4px 12px;
  border-radius: $base-radius;
}

// Grid Area
.form-checkbox {
  grid-area: checkbox;
  text-align: center;
}
.image {
  grid-area: image;
  width: 48px;
  height: 48px;
  object-fit: cover;
  border-radius: $base-radius;
  overflow: hidden;
  border: 1px solid rgba($brand-blue, 0.1);
  padding: 2px;
}

.small {
  font-size: 90%;
  font-style: italic;
  font-weight: $font-weight-light;
  color: $brand-light-purple;
}
.money {
  display: inline-block;
}
.name {
  grid-area: name;
}
.actions {
  grid-area: actions;
}
.quantity {
  grid-area: quantity;
}
.price {
  grid-area: price;
}
.vendor {
  grid-area: vendor;
}
.actions {
  grid-area: actions;
  justify-self: flex-end;
}
</style>
