<template>
  <div class="invitations" v-if="invitations.length">
    <h5>Invitations en attente</h5>
    <article v-for="invitedDirector in invitations" class="boxed pending">
      <h6><IconLibrary name="director" size="sm" /> {{ invitedDirector?.email }}</h6>
      <ul class="badges">
        <li class="btn btn-ghost btn-sm" @click="sendInvitation(invitedDirector)">
          <IconLibrary name="message" size="xs" />{{ $t('user.send_invitation') }}
        </li>
        <li class="badge">Pending</li>
      </ul>
    </article>
  </div>
  <div class="directors" v-if="directors.length">
    <h5>Vos clients actif</h5>
    <section>
      <article v-for="director in directors" class="boxed card">
        <header>
          <h6><IconLibrary name="director" size="sm" /> {{ director?.company }}</h6>
          <ul class="badges">
            <li class="badge" :class="{ 'is-active': director.is_verified }">
              {{ director.is_verified === true ? 'Active' : 'Pending' }}
            </li>
            <li class="badge" v-if="!director.is_kyc && director.is_verified">KYC</li>
          </ul>
        </header>
        <section class="contact">
          <p>{{ director.full_name }}</p>
          <p>{{ director.email }}</p>
          <p v-if="director.phone">{{ director.phone }}</p>
        </section>

        <section class="address" v-if="director.address">
          <p>{{ director.address.address1 }}</p>
          <p v-if="director.address.address2">{{ director.address.address2 }}</p>
          <img class="flag" :src="`/flags/${director.address.country}.svg`" />
          {{ director.address.zip }} {{ director?.address?.city }}
        </section>

        <ul>
          <li>
            <FormCheckbox
              id="shipping_fee_included"
              label="director.settings.shipping_fee_included"
              v-model="director.shipping_fee_included"
              @change="updateShippingFeeIncluded(director._id, director.shipping_fee_included)"
            />
          </li>
        </ul>
      </article>
    </section>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import IconLibrary from '../Shared/IconLibrary.vue';
import { kyWithAuth } from '../../libs/ky';
import { useUserStore } from '../../stores/user';
import FormCheckbox from '../Shared/Forms/FormCheckbox.vue';

const userStore = useUserStore();

const directors = ref([]);
const invitations = ref(userStore.user.invitations || []);

const sendInvitation = async (invitedDirector) => {
  await kyWithAuth
    .post('users/send-invitation', {
      json: {
        inviterCompanyName: userStore.user.company,
        email: invitedDirector.email,
        role: invitedDirector.role
      }
    })
    .json();
};

onMounted(async () => {
  try {
    const { users: res } = await kyWithAuth.get(`users/list/directors`).json();
    const userDirectors = userStore?.user?.directors || [];

    const updatedDirectors = res.map((director) => {
      const options = userDirectors.find(
        (userDirector) => userDirector.user_id?.toString() === director._id?.toString()
      );
      return {
        ...director,
        shipping_fee_included: false,
        ...options
      };
    });
    directors.value = updatedDirectors;
  } catch (e) {
    console.error(e);
  }
});

const updateShippingFeeIncluded = async (directorId, isChecked) => {
  try {
    await kyWithAuth.patch(`users/directors/${directorId}/options`, {
      json: { shipping_fee_included: isChecked }
    });

    const director = directors.value.find((d) => d._id === directorId);
    if (director) {
      director.shipping_fee_included = isChecked;
    }
  } catch (e) {
    console.error(e);
  }
};
</script>

<style lang="scss" scoped>
/* General styles */
h5 {
  margin-bottom: 0.75rem;
}

h6 {
  display: flex;
  align-items: center;
}

/* Directors Section */
.directors > section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}

/* Invitations */
.invitations {
  margin-bottom: 2.5rem;

  .boxed {
    display: flex;
    justify-content: space-between;

    &.pending + &.pending {
      margin-top: 0.75rem;
    }
  }
}

.boxed {
  display: block;
  color: inherit;
}

/* Card styles */
.card {
  p {
    margin: 3px 0;
  }

  section {
    margin-top: 0.75rem;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.75rem 0 0;
    padding: 0;
    gap: 0.75rem;

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
      gap: 0.75rem;
    }
  }

  > ul {
    list-style: none;
    margin: 0.75rem 0 0;
    padding: 0;

    li {
      display: flex;
      align-items: center;

      + li {
        margin-top: 6px;
      }
    }
  }
}

/* Badge styles */
.badge {
  padding: 0.35rem 0.85rem;
  border-radius: 0.25rem;
  background-color: rgba($brand-corail, 0.1);
  color: darken($brand-corail, 15%);
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: $font-weight-bold;

  &.is-active {
    background-color: rgba($brand-mint, 0.15);
    color: darken($brand-mint, 25%);
  }
}

.badges {
  list-style: none;
  margin: 0.75rem 0 0;
  padding: 0;
  display: flex;
  gap: 0.75rem;
  align-items: center;
}
</style>
