<template>
  <div class="dropdown-container">
    <label for="dropdown">{{ title ? $t(title) : $t('forms.label.selection') }}</label>
    <div
      class="dropdown"
      tabindex="0"
      @blur="closeDropdown"
      @keydown.space.prevent="toggleDropdown"
    >
      <div class="dropdown-selected" @click="toggleDropdown">
        <p>
          <template v-if="selectedOption.icon">
            <img class="icon" :src="selectedOption.icon" :alt="`${selectedOption.label} icon`" />
          </template>
          {{ $t(selectedOption.label) }}
        </p>
        <IconLibrary name="arrow-down" size="xs" :hasMargin="false" :isBold="true" />
      </div>
      <div class="dropdown-options" :class="{ 'is-hidden': !isOpen }">
        <article v-for="option in options" :key="option.value" @click="selectOption(option)">
          <template v-if="option.icon">
            <img class="icon" :src="option.icon" :alt="`${option.label} icon`" />
          </template>
          {{ $t(option.label) }}
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import IconLibrary from '../IconLibrary.vue';

const props = defineProps({
  modelValue: String,
  options: Array,
  title: String
});

// options = [{ value, label, icon }]

const emit = defineEmits(['update:modelValue']);
const isOpen = ref(false);

const selectedOption = computed(() => {
  return props.options.find((option) => option.value === props.modelValue) || {};
});

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const closeDropdown = () => {
  isOpen.value = false;
};

const selectOption = (option) => {
  emit('update:modelValue', option.value);
  closeDropdown();
};
</script>

<style scoped lang="scss">
.dropdown-container {
  width: 100%;
}

.dropdown-container > label {
  margin-bottom: 6px;
  color: $brand-grey;
  font-size: 85%;
  display: block;
}

.dropdown {
  position: relative;
  text-align: left;
  outline: none;
}

.dropdown .dropdown-selected {
  background-color: $brand-light-indigo;
  border-radius: $base-radius;
  border: 1px solid darken($brand-light-indigo, 10%);
  color: $brand-deep-purple;
  display: flex;
  padding: 4px 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  p {
    margin: 4px 0;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  &.is-open {
    border: 1px solid $brand-deep-purple;
  }
  .icon-wrapper {
    margin-left: 10px;
  }
}

.dropdown .dropdown-options {
  position: absolute;
  overflow: hidden;
  color: $brand-deep-purple;
  border: 1px solid darken($brand-light-indigo, 10%);
  border-radius: $base-radius;
  background-color: $brand-light-indigo;
  padding: 4px 0;
  z-index: 1;
  margin-top: -3px;
  min-width: 115%;
  box-shadow: 2px 2px 8px rgba($brand-indigo, 0.1);
}

.dropdown .dropdown-options article {
  cursor: pointer;
  user-select: none;
  padding: 4px 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  transition: background-color $base-speed-fast ease;
}

.dropdown .dropdown-options article:hover {
  background-color: darken($brand-light-indigo, 5%);
}
.icon {
  display: inline-block;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  width: 18px;
  height: 13.5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 1px;
}

.is-hidden {
  display: none;
}
</style>
