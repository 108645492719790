import { reactive, onUnmounted } from 'vue';
import { useOrderStore } from '../stores/orders';
import { useNotificationStore } from '../stores/notifications';

export function useWebSocket() {
  const orderStore = useOrderStore();
  const notificationStore = useNotificationStore();
  const state = reactive({
    isConnected: false,
    error: null,
    socket: null
  });

  function handleWebSocketMessage(message) {
    if (!message.type) return;
    const { type, data } = message;
    switch (type) {
      case 'new-order':
        orderStore.addOrder(data);
        break;
      case 'product-modification':
        notificationStore.addNotification(data);
        break;
      default:
        console.warn('Unhandled message type:', type);
    }
  }

  const initWebSocket = (jwt) => {
    if (!jwt) return;
    if (state.socket) {
      state.socket.close(1000);
    }

    const wsUrl =
      process.env.NODE_ENV !== 'production'
        ? `wss://shiftapis.ngrok.dev/ws?jwt=${jwt}`
        : `wss://api.nuteam.io/ws?jwt=${jwt}`;
    const socket = new WebSocket(wsUrl);
    state.socket = socket;

    socket.onopen = () => {
      console.log('WebSocket connected');
      state.isConnected = true;
      state.error = null;
    };

    socket.onmessage = (event) => {
      console.log('Message from server:', event.data);
      if (event.data) handleWebSocketMessage(JSON.parse(event.data));
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      state.error = error;
    };

    socket.onclose = (event) => {
      console.log('WebSocket disconnected', event);
      state.isConnected = false;
      state.socket = null;
      if (event.code !== 1000) {
        // Check for abnormal closure
        handleReconnect(jwt);
      }
    };
  };

  let reconnectionAttempts = 0;
  const maxReconnectAttempts = 10;

  const handleReconnect = (jwt) => {
    if (reconnectionAttempts < maxReconnectAttempts) {
      setTimeout(() => {
        reconnectionAttempts++;
        console.log(`Attempting to reconnect... (${reconnectionAttempts})`);
        initWebSocket(jwt);
      }, Math.min(2000 * reconnectionAttempts, 30000));
    }
  };

  const closeWebSocket = () => {
    if (state.socket) {
      state.socket.close(1000);
    }
  };

  onUnmounted(() => {
    closeWebSocket();
  });

  return { state, initWebSocket, closeWebSocket };
}
