<template>
  <section v-if="!productStore.loading" class="info">
    <h6 class="alt">{{ product.vendor || product.seller_name || 'In-House' }}</h6>
    <h4>{{ product.name }}</h4>
    <!-- <h5 v-if="variant.title">{{ variant.title }}</h5> -->
    <small class="sku" v-if="variant.sku">
      <strong>{{ $t('ui.sku') }}</strong> {{ variant.sku }}
    </small>
    <div class="money">
      <span>{{ formatMoney({ amount: variant.price }) }}</span>
      <div v-if="shippingFeeIncluded !== null" class="shipping-fee">
        <small v-if="shippingFeeIncluded" class="shipping-included">
          {{ $t('options.shipping_fee.included') }}
        </small>
        <small v-else class="shipping-not-included">
          {{ $t('options.shipping_fee.not_included') }}
        </small>
      </div>
    </div>
    <pre class="desc">{{ product.description }}</pre>
    <ShopProductOptions v-if="product.options" />
    <FormNumberInput
      title="forms.label.quantity"
      type="number"
      v-model.number="quantity"
      :quantity_rules="product.quantity_rules"
    />
    <div v-if="!variant?.quantity && !variant.allow_backorder" class="btn disabled">
      <IconLibrary name="order" size="sm" />
      <span v-if="Object.keys(options).length === 0" @click="addToCart">{{
        $t('forms.button.add_to_cart')
      }}</span>
      <span v-else>{{ $t('forms.button.out_of_stock') }}</span>
    </div>

    <div
      v-else
      class="btn"
      :class="[{ disabled: !variant?.quantity && !variant.allow_backorder }]"
      @click="addToCart"
    >
      <IconLibrary name="order" size="sm" />
      {{ $t('forms.button.add_to_cart') }}
    </div>
    <CartPopup :show="showCartPopup" @update:show="showCartPopup = $event" />
  </section>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useProductStore } from '../../stores/products.js';
import { useCartStore } from '../../stores/cart.js';
import { useUserStore } from '../../stores/user.js';
import { formatMoney } from '../../libs/utils.js';
import { kyWithAuth } from '../../libs/ky.js';
import FormNumberInput from '../Shared/Forms/FormNumberInput.vue';
import ShopProductOptions from './ShopProductOptions.vue';
import IconLibrary from '../Shared/IconLibrary.vue';
import CartPopup from '../Shared/CartPopup.vue';

const productStore = useProductStore();
const cartStore = useCartStore();
const userStore = useUserStore();

defineProps({ product: { type: Object } });
const { variant, product, options } = storeToRefs(productStore);
const quantity = ref(product.quantity_rules?.min || 1);
const shippingFeeIncluded = ref(null);
const showCartPopup = ref(false); // Contrôler l'affichage du popup

const fetchSellerDetails = async (sellerId) => {
  try {
    const { user: seller } = await kyWithAuth.get(`users/${sellerId}`).json();
    const matchedDirector = seller.directors.find(
      (director) => director?.user_id?.toString() === userStore.user?._id?.toString()
    );
    shippingFeeIncluded.value = matchedDirector.shipping_fee_included;
  } catch (e) {
    console.error(e);
  }
};

const addToCart = () => {
  const selectedVariant = variant?.value;
  const selectedProduct = product?.value;
  const selectedOptions =
    selectedVariant?.options?.map((option) => `${option.name} / ${option.value}`) || [];
  if (
    (selectedVariant?.quantity && selectedVariant?.quantity > 0) ||
    selectedVariant.allow_backorder
  ) {
    cartStore.add({
      productId: selectedProduct._id,
      variantId: selectedVariant?._id,
      sellerId: selectedProduct.seller_id,
      quantity: quantity.value,
      name: selectedProduct.name,
      options: selectedOptions.join(' - '),
      sku: selectedVariant?.sku,
      price: selectedVariant?.price,
      image: selectedProduct.images?.[0],
      ...selectedProduct.quantity_rules
    });
    showCartPopup.value = true;
  }
};

onMounted(async () => {
  if (product.value.seller_id) {
    await fetchSellerDetails(product.value.seller_id); // Fetch seller details on mount
  }
});

watch(quantity, (newQty) => {
  if (newQty === '') quantity.value = 0;
});
</script>

<style lang="scss" scoped>
h4 {
  margin-top: 0;
}
h5 {
  color: $brand-light-purple;
  font-weight: $font-weight-light;
  font-style: italic;
  font-size: 105%;
}
.info {
  position: relative;
}
.alt {
  text-transform: uppercase;
  color: $brand-light-purple;
  font-size: 80%;
  margin-bottom: 10px;
}
.sku {
  color: $brand-light-purple;
  font-size: 80%;
}
.money {
  font-size: 125%;
  margin: 15px 0 35px;
}
.btn {
  margin-top: 35px;
  justify-content: center;
  background-color: $brand-dark-purple;
}
:deep(input[type='number']) {
  background-color: $white;
  border: 2px solid rgba($brand-blue, 0.3);
  color: $tuatara;
  transition: border 200ms ease, color 200ms ease, transform 200ms ease-in-out;
  font-size: 16px;
}
</style>
