<template>
  <div id="app" :class="[routeName, 'anon']">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute();
const routeName = computed(() => route?.name?.toLocaleLowerCase());
</script>

<style lang="scss">
#app.anon {
  display: block;
}
</style>
