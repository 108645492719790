<template>
  <div class="form-input">
    <label v-if="label" :for="title">{{ $t(title) }}</label>
    <div class="input-wrapper" :class="[{ compact: !!compact }]">
      <input
        :id="title"
        :name="title"
        :value="inputValue"
        :disabled="disabled"
        :placeholder="0"
        :step="effectiveIncrement"
        :min="effectiveMin"
        :max="isMaxDefined ? max : undefined"
        type="number"
        @input="updateInputValue($event.target.value)"
        @blur="validateAndAdjustValue"
      />
      <span v-if="compact && errorMessage" class="error-icon" :msg="errorMessage">⚠️</span>
    </div>
    <p v-if="!compact && errorMessage" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue';

const props = defineProps({
  title: { type: String, required: true },
  label: { type: Boolean, default: true },
  modelValue: { type: Number, default: 0 },
  disabled: { type: Boolean, default: false },
  quantity_rules: { type: Object, default: () => ({}) },
  compact: { type: Boolean, default: false }
});

const emit = defineEmits(['update:modelValue']);
const errorMessage = ref('');
const inputValue = ref(props.modelValue);

const { min = 0, max = null, increment = 1 } = props.quantity_rules;

const isMaxDefined = max !== null && max !== 0;

const effectiveIncrement = computed(() => {
  return increment > 0 ? increment : 1;
});

const effectiveMin = computed(() => {
  if (min > 0) {
    return Math.ceil(min / effectiveIncrement.value) * effectiveIncrement.value;
  }
  return increment > 0 ? increment : 0;
});

const updateInputValue = (value) => {
  inputValue.value = value;
  emit('update:modelValue', parseFloat(value));
};

const validateAndAdjustValue = () => {
  let value = parseFloat(inputValue.value);
  if (isNaN(value)) return;

  let adjustedValue = value;
  if (value < effectiveMin.value) {
    adjustedValue = effectiveMin.value;
    errorMessage.value = `La valeur minimale est ${effectiveMin.value}.`;
  } else if (isMaxDefined && value > max) {
    adjustedValue = max;
    errorMessage.value = `La valeur maximale est ${max}.`;
  } else if (
    effectiveIncrement.value > 0 &&
    (value - effectiveMin.value) % effectiveIncrement.value !== 0
  ) {
    adjustedValue =
      effectiveMin.value +
      Math.round((value - effectiveMin.value) / effectiveIncrement.value) *
        effectiveIncrement.value;
    errorMessage.value = `Valeur ajustée au prochain incrément valide : ${adjustedValue}.`;
  } else {
    errorMessage.value = '';
  }

  inputValue.value = adjustedValue;
  emit('update:modelValue', adjustedValue);
};

watch(
  () => props.modelValue,
  (newValue) => {
    inputValue.value = newValue;
  }
);

onMounted(() => {
  if (effectiveMin.value > 0 && inputValue.value < effectiveMin.value) {
    inputValue.value = effectiveMin.value;
    emit('update:modelValue', effectiveMin.value);
  }
});
</script>

<style scoped lang="scss">
.form-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 0;
  min-width: 90px;
  width: 100%;
  > .input-wrapper {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
  }

  input {
    width: 100%;
    padding: 10px;
    border-radius: $base-radius;
    border: 1.5px solid;
    border-color: rgba($brand-light-purple, 0.5);
    color: $brand-deep-purple;
    transition: border-color 250ms ease;
    &:focus {
      outline: none;
      border-color: $brand-deep-purple;
    }
    &:disabled {
      background-color: rgba($brand-light-purple, 0.3);
      border-color: transparent;
      color: rgba($brand-deep-purple, 0.7);
      font-style: italic;
    }
  }
  .input-price input {
    padding-right: 26px;
  }
  .currency-symbol {
    position: absolute;
    right: 10px;
    top: 10px;
    color: rgba($brand-deep-purple, 0.4);
    font-size: 95%;
  }
  > label {
    margin-bottom: 6px;
    color: $brand-grey;
    font-size: 85%;
  }
}
.error-message {
  color: darken(red, 20%);
  margin: 0;
  padding: 0.25rem 0.75rem;
  margin-top: 1rem;
  border-radius: $base-radius;
  border: 1px solid darken(red, 10%);
}
.error-icon {
  margin-left: 10px;
  cursor: pointer;
  color: darken(red, 10%);
  &:hover::after {
    content: attr(msg);
    background: rgba(darken(red, 20%), 0.65);
    color: white;
    padding: 5px;
    border-radius: 3px;
    position: absolute;
    bottom: -40px;
    right: 0;
    white-space: nowrap;
    z-index: 1;
    font-size: 85%;
  }
}
</style>
