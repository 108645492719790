<template>
  <div class="condition-row">
    <FormDropdown
      title="forms.label.condition_field"
      :options="fieldOptions"
      v-model="selectedField"
    />
    <FormDropdown
      title="forms.label.condition_rule"
      :options="conditionOptions"
      v-model="selectedCondition"
    />
    <FormInput title="forms.label.condition_value" v-model="inputValue" />
    <IconLibrary
      v-if="totalRows > 1"
      name="delete"
      color="red"
      size="sm"
      :hasMargin="false"
      @click="deleteCondition()"
    />
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import FormInput from '../Shared/Forms/FormInput.vue';
import FormDropdown from '../Shared/Forms/FormDropdown.vue';
import IconLibrary from '../Shared/IconLibrary.vue';

const props = defineProps({
  id: Number,
  totalRows: Number,
  condition: Object
});

const emit = defineEmits(['deleteCondition', 'update:condition', 'update:field', 'update:value']);

const deleteCondition = () => {
  emit('deleteCondition', props.id);
};

const fieldOptions = [
  { value: 'category', label: 'ui.category' },
  { value: 'seller', label: 'ui.seller' },
  { value: 'price', label: 'ui.price' }
];

const stringOptions = [
  { value: 'equals', label: 'ui.equals' },
  { value: 'not_equals', label: 'ui.not_equals' },
  { value: 'contains', label: 'ui.contains' },
  { value: 'not_contains', label: 'ui.not_contains' }
];

const numberOptions = [
  ...stringOptions.slice(0, 2),
  { value: 'greater_than', label: 'ui.greater_than' },
  { value: 'less_than', label: 'ui.less_than' }
];

const conditionOptionsMap = {
  category: [{ value: 'equals', label: 'ui.equals' }],
  seller: stringOptions,
  price: numberOptions
};

const selectedField = ref(props.condition.field || 'category');
const selectedCondition = ref(props.condition.condition || 'equals');
const inputValue = ref(props.condition.value || '');

const conditionOptions = computed(() => {
  return conditionOptionsMap[selectedField.value] || [];
});

watch(selectedField, (newValue) => {
  emit('update:field', { id: props.id, value: newValue });
});

watch(selectedCondition, (newValue) => {
  emit('update:condition', { id: props.id, value: newValue });
});

watch(inputValue, (newValue) => {
  emit('update:value', { id: props.id, value: newValue });
});
</script>

<style scoped>
.condition-row {
  display: grid;
  grid-template-columns: 180px 180px 1fr 24px;
  gap: 0.75rem;
  align-items: flex-end;
}

.icon-wrapper {
  margin-bottom: 8px;
}
</style>
