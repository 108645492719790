<template>
  <div id="page">
    <main>
      <header>
        <h2>{{ $t('orders.title') }}</h2>
        <div class="actions">
          <router-link to="/shop" v-if="userStore.user?.role === 'director'" class="btn btn-lg">
            <IconLibrary name="add-order" color="white" size="sm" />
            {{ $t('forms.button.create_order') }}
          </router-link>
          <a v-if="orderStore.orders.length > 0" href="#" class="btn btn-lg" @click="exportOrders">
            <IconLibrary name="download" color="white" size="sm" />
            Exporter
          </a>
        </div>
      </header>
      <OrderList class="boxed" />
    </main>
  </div>
</template>

<script setup>
import OrderList from '../components/Order/OrderList.vue';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import { kyWithAuth } from '../libs/ky';
import { useOrderStore } from '../stores/orders.js';
import { useUserStore } from '../stores/user.js';

const orderStore = useOrderStore();
const userStore = useUserStore();

const exportOrders = async () => {
  try {
    const response = await kyWithAuth
      .get('orders/export', {
        responseType: 'blob'
      })
      .blob();

    const url = URL.createObjectURL(response); // Create a URL for the Blob object

    // Create a link element to initiate the download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'orders.csv';
    link.click();

    // Clean up by revoking the URL object
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error(error);
  }
};
</script>

<style scoped lang="scss">
#page main > header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .actions {
    display: flex;
    gap: 20px;
  }
}
@media (max-width: 767px) {
  #page main > header {
    display: flex;
    flex-direction: column;
  }
}
</style>
