<template>
  <main>
    <section>
      <img class="logo" src="/logos/logo-nuteam-white.svg" alt="NuTeam Logo" />
      <h1>{{ $t('login.title') }}</h1>

      <form @submit.prevent="editPassword" v-if="!error">
        <FormInput
          title="forms.label.password"
          placeholder="forms.placeholder.password"
          type="password"
          v-model="password"
        />
        <div class="help">
          {{ $t('signup.password_requirements') }}
        </div>
        <FormButton title="forms.button.password" />

        <router-link class="link" to="/login">{{ $t('reset.login.label') }}</router-link>
        <div v-if="loginErrors" class="errors">
          <h6>Oops, we found some errors!</h6>
          <ul>
            <li v-for="error in loginErrors" :key="error.code">{{ error.message }}</li>
          </ul>
        </div>
      </form>
      <div v-else class="errors">
        <h6>Oops, we found some errors!</h6>
        {{ error }}
      </div>
    </section>
    <section class="img-container">
      <span class="badge version">
        {{ $t('ui.version') }}
        <strong>{{ version }}</strong>
      </span>
    </section>
  </main>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { kyWithAuth } from '../libs/ky';
import { version } from '../../package';
import { useUserStore } from '../stores/user';
import FormInput from '../components/Shared/Forms/FormInput.vue';
import FormButton from '../components/Shared/Forms/FormButton.vue';

const userStore = useUserStore();
const password = ref('');
const error = ref('');
const token = ref(null);

const router = useRouter();
const route = useRoute();

const inviteToken = route.query.jwt;

onMounted(async () => {
  if (inviteToken) {
    try {
      const { jwt, user } = await kyWithAuth
        .post('users/accept-invitation', { json: { jwt: inviteToken } })
        .json();
      token.value = jwt;
      if (user?.services?.password) router.push({ name: 'Home' });
    } catch (err) {
      console.error(err);
      error.value = err.response?.data?.error || 'Failed to accept invitation.';
    }
  } else {
    error.value = 'Invalid or missing token.';
  }
});

const editPassword = async () => {
  try {
    await userStore.editPassword({ password: password.value, jwt: token.value });
    router.push({ name: 'Login' });
  } catch (err) {
    const { errors } = await err.response.json();
    error.value = errors;
  }
};
</script>

<style scoped lang="scss">
#app > main {
  padding-bottom: 0px;
}
.help {
  border: 1px solid $brand-light-indigo;
  padding: 0.5rem 1rem;
  color: $brand-light-indigo;
  border-radius: $base-radius;
  font-size: 90%;
  margin-bottom: 1rem;
}
main {
  display: flex;
  min-height: 100vh;
}
:deep(label) {
  color: rgba($white, 0.7) !important;
}
h1 {
  margin: 15px 0 65px;
  color: lightgrey;
  font-size: 25px;
}
form {
  display: flex;
  flex-direction: column;
}
.link {
  color: $white;
  font-size: 90%;
  margin-top: 10px;
  text-align: right;
  transition: color 200ms ease-in-out;
  &:hover {
    color: $brand-corail;
  }
}
.errors {
  background-color: $white;
  border: 1px solid darken($brand-corail, 20%);
  border-radius: $base-radius;
  padding: 25px;
  margin-top: 25px;
  h6 {
    margin-bottom: 12px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      color: darken($brand-corail, 35%);
    }
  }
}
.logo {
  width: 150px;
}
.badge {
  border-radius: 20px;
  background-color: rgba($white, 0.6);
  color: $brand-dark-purple;
  border: 1px solid $brand-dark-purple;
  padding: 3px 18px;
  font-size: 9px;
  position: absolute;
  bottom: 18px;
  right: 18px;
  text-transform: uppercase;
}
main > section:not(.img-container) {
  padding: 50px 80px;
  width: 850px;
  background-color: $brand-dark-grey-purple;
}
main > .img-container {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-image: url('../../public/svg/illustration_login.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  background-color: #f7f8fd;
  box-shadow: -5px 0px 10px 0px rgba($black, 0.1);
  position: relative;
}

@media (min-width: 1440px) {
  main > .img-container {
    background-size: 70%;
  }
}
</style>
