<template>
  <div class="country-container">
    <label for="country-switcher">{{ $t('forms.label.country') }}</label>
    <div
      class="country-dropdown"
      tabindex="0"
      @blur="closeDropdown"
      @keydown.space.prevent="toggleDropdown"
    >
      <div class="country-selected" @click="toggleDropdown">
        <p>
          <img class="flag" :src="flagPath" :alt="`${currentCountry} flag`" />
          {{ $t(`country.iso-code-${currentCountry}`) }}
        </p>
        <IconLibrary name="arrow-down" size="xs" :hasMargin="false" :isBold="true" />
      </div>
      <div class="country-options" :class="{ 'is-hidden': !isOpen }">
        <article v-for="country in countries" :key="country" @click="changeCountry(country)">
          <img class="flag" :src="`/flags/${country}.svg`" :alt="`${country} flag`" />
          {{ $t(`country.iso-code-${country}`) }}
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import IconLibrary from './IconLibrary.vue';

const props = defineProps({
  modelValue: { type: String, default: 'FR' }
});

const emit = defineEmits(['update:modelValue']);
const countries = ['FR', 'BE', 'DE', 'IT', 'LU', 'NL', 'DK', 'IE', 'GR', 'ES', 'PT', 'GB'];
const isOpen = ref(false);
const currentCountry = ref(props.modelValue);

watch(
  () => props.modelValue,
  (newVal) => {
    currentCountry.value = newVal;
  }
);

const flagPath = computed(() => `/flags/${currentCountry.value}.svg`);

const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};

const closeDropdown = () => {
  isOpen.value = false;
};

const changeCountry = (country) => {
  emit('update:modelValue', country);
  currentCountry.value = country;
  closeDropdown();
};
</script>

<style scoped lang="scss">
.country-container {
  padding: 15px 0;
  width: 50%;
}
.country-dropdown {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
}

.country-dropdown .country-selected {
  background-color: $brand-light-indigo;
  border-radius: $base-radius;
  border: 1px solid darken($brand-light-indigo, 10%);
  color: $brand-deep-purple;
  display: flex;
  padding: 4px 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  p {
    margin: 4px 0;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  &.is-open {
    border: 1px solid $brand-deep-purple;
  }
  .icon-wrapper {
    margin-left: 10px;
  }
}

.country-dropdown .country-options {
  position: absolute;
  overflow: hidden;
  color: $brand-deep-purple;
  border: 1px solid darken($brand-light-indigo, 10%);
  border-radius: $base-radius;
  background-color: $brand-light-indigo;
  padding: 4px 0;
  z-index: 1;
  margin-top: -3px;
  min-width: 115%;
  box-shadow: 2px 2px 8px rgba($brand-indigo, 0.1);
}

.country-dropdown .country-options article {
  cursor: pointer;
  user-select: none;
  padding: 4px 12px;
  display: flex;
  gap: 10px;
  align-items: center;
  transition: background-color $base-speed-fast ease;
}

.country-dropdown .country-options article:hover {
  background-color: darken($brand-light-indigo, 5%);
}
.flag {
  display: inline-block;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  width: 18px;
  height: 13.5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 1px;
}

.is-hidden {
  display: none;
}
</style>
