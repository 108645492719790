<template>
  <div id="page">
    <main>
      <header>
        <h2>{{ $t('buyers.title') }}</h2>
        <div class="actions">
          <div class="btn btn-lg" @click="openModal()">
            <IconLibrary name="shop" color="white" size="sm" />
            {{ $t('forms.button.add_buyer') }}
          </div>
        </div>
      </header>
      <div
        v-if="!userStore.user?.buyers?.length && !userStore.user?.invitations?.length"
        class="boxed buyers"
      >
        <EmptyState>
          <IconLibrary name="shop" size="xxl" />
          <h4>No Points of Sale</h4>
          <p>
            Uh-oh, looks like you haven't created any point of sale yet!<br />
            To get started, click on the following link
            <router-link to="/pos/new">to create your first PoS</router-link>.
          </p>
          <p>
            <small>
              Once created, it will appear here and you can start using it right away.
            </small>
          </p>
        </EmptyState>
      </div>
      <BuyerList v-else />
    </main>
    <Modal ref="modal">
      <template #title>{{ $t('modal.buyers-invitation.title') }}</template>
      <div v-if="statusMessage" :class="{ success: isSuccess, failure: !isSuccess }">
        {{ statusMessage }}
      </div>
      <form v-else id="submit-invitation" @submit.prevent="submitForm">
        <FormInput
          title="forms.label.email"
          placeholder="forms.placeholder.email"
          type="email"
          v-model="email"
        />
        <input type="submit" hidden />
      </form>
      <template #footer>
        <div class="btn btn-ghost" @click="handleCancel">Cancel</div>
        <button type="submit" form="submit-invitation" class="btn">Send Invitation</button>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { kyWithAuth } from '../libs/ky.js';
import IconLibrary from '../components/Shared/IconLibrary.vue';
import EmptyState from '../components/Shared/EmptyState.vue';
import BuyerList from '../components/Buyers/BuyerList.vue';
import { useUserStore } from '../stores/user.js';
import Modal from '../components/Shared/Modal.vue';
import FormInput from '../components/Shared/Forms/FormInput.vue';

const userStore = useUserStore();
const modal = ref();
const email = ref('');
const company = ref(userStore.user?.company || userStore.user?.full_name);
const statusMessage = ref('');
const isSuccess = ref(false);

const openModal = () => {
  modal.value.open();
};

const closeModal = () => {
  modal.value.close();
  resetForm();
};

const resetForm = () => {
  email.value = '';
  statusMessage.value = '';
};

const submitForm = async () => {
  try {
    await kyWithAuth
      .post('users/send-invitation', {
        json: {
          inviterCompanyName: company.value,
          email: email.value,
          role: 'buyer'
        }
      })
      .json();
    statusMessage.value = 'User has been successfully invited!';
    isSuccess.value = true;
    setTimeout(closeModal, 1500);
  } catch (e) {
    console.error(e);
    statusMessage.value = 'Error, please verify the email address and try again!';
    isSuccess.value = false;
  }
};

const handleCancel = () => closeModal();
</script>

<style scoped lang="scss">
#page main > header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .actions {
    display: flex;
    gap: 20px;
  }
}

.buyer-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}
.boxed {
  display: block;
  color: inherit;
}

h6 {
  display: flex;
  align-items: center;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: flex;
    align-items: center;
    + li {
      margin-top: 6px;
    }
  }
}
</style>
