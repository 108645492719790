<template>
  <div
    class="icon-wrapper"
    :class="[`svg-size-${this.size}`, { 'svg-margin': hasMargin }, { 'svg-inverted': isInverted }]"
  >
    <svg
      :class="[
        `svg-${this.type}`,
        `svg-${this.type}--${this.name}`,
        { 'svg-bold': isBold },
        { 'svg-border': hasBorder },
        `svg-color-${this.color}`
      ]"
      xmlns="http://www.w3.org/2000/svg"
    >
      <use :xlink:href="path" xmlns:xlink="http://www.w3.org/1999/xlink" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'icon-library',
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'icon'
    },
    hasMargin: {
      type: Boolean,
      default: true
    },
    isBold: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'base',
      validator(value) {
        return ['xs', 'sm', 'base', 'lg', 'xl', 'xxl'].includes(value);
      }
    },
    color: {
      type: String,
      default: 'current',
      validator(value) {
        return ['current', 'blue', 'black', 'white', 'yellow', 'red'].includes(value);
      }
    },
    isInverted: {
      type: Boolean,
      default: false
    },
    hasBorder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    path() {
      return this.type === 'icon'
        ? `/svg/icons-sprite.svg#icon-${this.name}`
        : `/logos/logos-sprite.svg#icon-${this.name}`;
    }
  }
};
</script>

<style lang="scss">
.svg-icon,
.svg-logo {
  display: inline-block;
}
.svg-icon {
  stroke-width: 1.5;
  stroke: currentColor;
  stroke-linecap: round;
  fill: transparent;
}
.svg-bold {
  stroke-width: 3;
}
.svg-inverted {
  transform: rotate(90deg);
}
svg {
  width: 100%;
  height: 100%;
}
.svg-border {
  box-shadow: 0 0 0 5px currentColor;
  border-radius: 50px;
  margin-left: 5px;
  &.svg-color-green {
    box-shadow: 0 0 0 5px $brand-light-mint;
  }
  &.svg-color-yellow {
    box-shadow: 0 0 0 5px rgba($brand-gold, 0.5);
  }
  &.svg-color-red {
    box-shadow: 0 0 0 5px rgba($brand-corail, 0.35);
  }
}
.icon-wrapper {
  &.svg-margin {
    margin-right: 6px;
  }
  &.svg-size-xs {
    width: 16px;
    height: 16px;
  }
  &.svg-size-sm {
    width: 24px;
    height: 24px;
  }
  &.svg-size-base {
    width: 32px;
    height: 32px;
  }
  &.svg-size-lg {
    width: 40px;
    height: 40px;
  }
  &.svg-size-xl {
    width: 58px;
    height: 58px;
  }
  &.svg-size-xxl {
    width: 105px;
    height: 105px;
  }
}
.svg-color {
  &-current {
    stroke: currentColor;
  }
  &-blue {
    stroke: $brand-blue;
  }
  &-black {
    stroke: #000000;
  }
  &-grey {
    stroke: $gainsboro;
  }
  &-white {
    stroke: $white;
  }
  &-green {
    stroke: darken($brand-mint, 25%);
  }
  &-yellow {
    stroke: darken($brand-gold, 25%);
  }
  &-red {
    stroke: $brand-dark-corail;
  }
  &-red-boxed {
    stroke: $white;
    background-color: darken(red, 10%);
    padding: 5px;
    transform: scale(1.35);
    border-radius: $base-radius;
  }
}
</style>
